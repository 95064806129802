import React, { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import { Facebook, Instagram, LoaderCircle, Save, X } from "lucide-react";
import MapGenerator from "./MapGenerator";
import useApi from "../../../../utils/useApi";
import { InputField } from "../../../../components";
import { useProjectContext } from "../ProjectContext";
import SectionHead from "../ProjectSidebar/SectionHead";

export default function ContactDetails({
  setIsCustomizing,
  isCustomizing,
  open,
}) {
  const { request } = useApi();
  const { project_id, refreshIframe } = useProjectContext();
  const [isUpdating, setIsUpdating] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
    socials: [
      { name: "facebook", link: "" },
      { name: "instagram", link: "" },
      { name: "x", link: "" },
    ],
    mapDetails: {
      location: "",
      mapUrl: "",
      center: { lat: 0, lng: 0 },
      loading: false,
      error: "",
    },
  });

  const fetchContactDetails = useCallback(async () => {
    try {
      const { data } = await request({
        method: "get",
        url: `projects/${project_id}/data/contact_details`,
      });

      const details = data[0].value || {};
      const socials = details.socials?.filter((social) => social.link) || [
        { name: "facebook", link: "" },
        { name: "instagram", link: "" },
        { name: "x", link: "" },
      ];

      setContactDetails((prev) => ({
        ...prev,
        ...details,
        socials,
      }));
    } catch (err) {
      console.error("Failed to fetch contact details:", err);
    }
  }, [project_id, request]);

  useEffect(() => {
    fetchContactDetails();
  }, [project_id]);

  const updateContactDetails = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    try {
      const formData = new FormData();
      formData.append("key", "contact_details");
      formData.append("value", JSON.stringify(contactDetails));
      formData.append("value_type", "JSON");
      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Contact details updated");
      refreshIframe();
    } catch (err) {
      toast.error(
        err.response?.data?.message || "Error updating contact details."
      );
    }
    setIsUpdating(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSocialChange = (index, value) => {
    setContactDetails((prev) => {
      const socials = [...prev.socials];
      socials[index].link = value;
      return { ...prev, socials };
    });
  };

  const handleMapDetailsChange = (newMapDetails) => {
    setContactDetails((prev) => ({
      ...prev,
      mapDetails: { ...prev.mapDetails, ...newMapDetails },
    }));
  };

  return (
    <div
      className={`z-50 shadow-r shadow-black/15 bg-white dark:bg-gray-900 absolute transparent-scrollbar
      transition-all duration-500 ease-in-out
      ${open ? "left-64" : "left-0"}
      ${
        isCustomizing === "contact_details"
          ? "w-96 opacity-100 h-[calc(100vh-6rem)] top-0 overflow-y-scroll"
          : "w-0 opacity-0 h-0 bottom-0 overflow-hidden"
      }
      border-r border-gray-200 dark:border-gray-800`}
    >
      <SectionHead
        title="Contact Details"
        setIsCustomizing={setIsCustomizing}
      />

      <div className="p-6">
        <form onSubmit={updateContactDetails} className="w-full space-y-8">
          <div className="space-y-6">
            <div className="flex items-center gap-2">
              <div className="h-8 w-1 bg-blue-500 rounded-full"></div>
              <h5 className="text-lg font-semibold">Basic Information</h5>
            </div>
            <div className="space-y-4">
              {["name", "email", "phone"].map((field) => (
                <InputField
                  key={field}
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  name={field}
                  value={contactDetails[field]}
                  onChange={handleChange}
                  className="transition-all duration-200 focus-within:scale-[1.02]"
                />
              ))}
            </div>
          </div>

          <div className="space-y-6">
            <div className="flex items-center gap-2">
              <div className="h-8 w-1 bg-green-500 rounded-full"></div>
              <h5 className="text-lg font-semibold">Location Details</h5>
            </div>
            <div className="space-y-4">
              <InputField
                label="Address"
                name="address"
                value={contactDetails.address}
                onChange={handleChange}
                className="transition-all duration-200 focus-within:scale-[1.02]"
              />
              <MapGenerator
                onUpdateMapDetails={handleMapDetailsChange}
                mapDetails={contactDetails.mapDetails}
              />
            </div>
          </div>

          <div className="space-y-6">
            <div className="flex items-center gap-2">
              <div className="h-8 w-1 bg-purple-500 rounded-full"></div>
              <h5 className="text-lg font-semibold">Social Media</h5>
            </div>
            <div className="space-y-4">
              {contactDetails.socials.map((social, index) => (
                <div
                  key={social.name}
                  className="group flex items-center gap-3 p-3 rounded-lg transition-all duration-200
                    hover:bg-gray-50 dark:hover:bg-gray-800/50"
                >
                  <div className="w-10 h-10 rounded-full flex items-center justify-center bg-gray-100 dark:bg-gray-800">
                    {index === 0 && (
                      <Facebook className="w-5 h-5 text-blue-600" />
                    )}
                    {index === 1 && (
                      <Instagram className="w-5 h-5 text-pink-600" />
                    )}
                    {index === 2 && (
                      <X className="w-5 h-5 text-gray-800 dark:text-gray-200" />
                    )}
                  </div>
                  <InputField
                    className="flex-1 group-hover:scale-[1.02] transition-all duration-200"
                    placeholder={`${social.name}.com`}
                    value={social.link}
                    onChange={(e) => handleSocialChange(index, e.target.value)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="sticky bottom-0 left-0 right-0 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm p-4 -mx-6 border-t dark:border-gray-700">
            <button
              type="submit"
              disabled={isUpdating}
              className="w-full btnPrimary bg-gradient-to-r from-green-600 to-green-500 hover:from-green-700 
                hover:to-green-600 dark:from-green-700 dark:to-green-600 dark:hover:from-green-800 
                dark:hover:to-green-700 px-4 py-3 flex items-center justify-center gap-2 rounded-lg
                transition-all duration-300 transform hover:scale-[1.02] disabled:opacity-70"
            >
              {isUpdating ? (
                <LoaderCircle className="w-4 animate-spin" />
              ) : (
                <Save className="w-4 h-4" />
              )}
              <span className="font-medium">
                {isUpdating ? "Updating..." : "Save Changes"}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
