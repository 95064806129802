import React from 'react';

export default function Step2Content({ formData, setFormData, isBlog }) {
  if (!isBlog) return null;

  return (
    <div className="space-y-6">
      {/* Number of Articles */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Number of Articles
        </label>
        <input
          type="number"
          value={formData.numArticles}
          onChange={(e) => setFormData(prev => ({ ...prev, numArticles: e.target.value }))}
          className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                   dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
          placeholder="Enter number of articles"
        />
      </div>

      {/* Number of Authors */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Number of Authors
        </label>
        <input
          type="number"
          value={formData.numAuthors}
          onChange={(e) => setFormData(prev => ({ ...prev, numAuthors: e.target.value }))}
          className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                   dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
          placeholder="Enter number of authors"
        />
      </div>

      {/* Number of Tags */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Number of Tags
        </label>
        <input
          type="number"
          value={formData.numTags}
          onChange={(e) => setFormData(prev => ({ ...prev, numTags: e.target.value }))}
          className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                   dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
          placeholder="Enter number of tags"
        />
      </div>

      {/* Word Count */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Words per Article
        </label>
        <input
          type="number"
          value={formData.wordCount}
          onChange={(e) => setFormData(prev => ({ ...prev, wordCount: e.target.value }))}
          className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                   dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
          placeholder="Enter words per article"
        />
      </div>
    </div>
  );
} 