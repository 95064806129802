import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import useApi from "../../../../utils/useApi";
import { InputField } from "../../../../components";
import { useProjectContext } from "../ProjectContext";
import usePermissions from "../../../../utils/userPermission";
import { Camera, Edit, LoaderCircle, Trash, X, Plus } from "lucide-react";
import SectionHead from "./SectionHead";

function useClickOutside(ref, handler) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current || ref.current.contains(event.target)) return;
      handler();
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, handler]);
}

export default function ServicesListing({
  setIsCustomizing,
  isCustomizing,
  open,
}) {
  const { request, isLoading } = useApi();
  const { project_id } = useProjectContext();
  const { hasPermission } = usePermissions();

  const [services, setServices] = useState([]);
  const [blogsList, setBlogsList] = useState([]);
  const [input, setInput] = useState("");
  const [editServiceId, setEditServiceId] = useState(null);
  const [deleteServiceId, setDeleteServiceId] = useState(null);
  const [imagePreviewSrc, setImagePreviewSrc] = useState(null);
  const [selectedServiceForImageUpload, setSelectedServiceForImageUpload] =
    useState(null);

  const [fileInfo, setFileInfo] = useState(null);
  const fileInputRef = useRef(null);
  const [newDescription, setNewDescription] = useState("");
  const [showAddForm, setShowAddForm] = useState(false);

  const getServices = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/services`,
      });
      const fetchedServices = res?.data?.[0]?.value || [];
      const servicesWithIds = fetchedServices.map((service, index) => ({
        ...service,
        id: index,
      }));
      setServices(servicesWithIds);
    } catch (err) {
      console.error("Error fetching services:", err);
    }
  }, [project_id]);

  const getBlogList = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/blog_list`,
      });
      setBlogsList(res?.data?.[0]?.value || []);
    } catch (err) {
      console.error("Error fetching blogs list:", err);
    }
  }, [project_id, request]);

  useEffect(() => {
    if (project_id) {
      getServices();
      getBlogList();
    }
  }, [project_id]);

  const updateServicesOnServer = async (updatedServices) => {
    try {
      const formData = new FormData();
      formData.append("key", "services");
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(updatedServices));

      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      setServices(updatedServices);
      toast.success("Services updated successfully");
    } catch (error) {
      toast.error("Failed to update services");
    }
  };

  const resetForm = () => {
    setInput("");
    setNewDescription("");
    setFileInfo(null);
    setShowAddForm(false);
  };

  const addService = async (e) => {
    e.preventDefault();
    const trimmedInput = input.trim();
    if (!trimmedInput) {
      toast.error("Service name cannot be empty");
      return;
    }
    if (services.some((service) => service.title === trimmedInput)) {
      toast.error("Service already exists");
      return;
    }

    let imageFileName = null;
    if (fileInfo && fileInfo.file) {
      try {
        const uniqueId = `cat-${trimmedInput}-${Date.now()}`;
        const formData = new FormData();
        formData.append("key", uniqueId);
        formData.append("value", uniqueId);
        formData.append("file", fileInfo.file);

        const res = await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (res.status) {
          imageFileName = res.data.file_name;
        } else {
          toast.error("Failed to upload image");
          return;
        }
      } catch (err) {
        const errorMsg =
          err.response?.data?.message ||
          "An error occurred during image upload.";
        toast.error(errorMsg);
        return;
      }
    }

    const newService = {
      title: trimmedInput,
      description: newDescription.trim(),
      image: imageFileName,
      id: services.length,
    };
    const updatedServices = [...services, newService];
    setServices(updatedServices);
    await updateServicesOnServer(updatedServices);
    resetForm();
  };

  const onEdit = (service) => setEditServiceId(service.id);
  const onCancelEdit = () => setEditServiceId(null);

  const onSaveEdit = async (serviceId, newTitle, newDescription) => {
    if (!newTitle.trim()) {
      toast.error("Service name cannot be empty");
      return;
    }
    const updatedServices = services.map((service) =>
      service.id === serviceId
        ? { ...service, title: newTitle, description: newDescription }
        : service
    );
    setServices(updatedServices);
    setEditServiceId(null);
    await updateServicesOnServer(updatedServices);
  };

  const onDelete = (serviceId, confirmDelete = false) => {
    if (confirmDelete) {
      removeService(serviceId);
      setDeleteServiceId(null);
    } else {
      if (serviceId === null) {
        setDeleteServiceId(null);
      } else {
        const service = services.find((s) => s.id === serviceId);
        const serviceExists = blogsList.some(
          (blog) => blog.service === service.title
        );
        if (serviceExists) {
          toast.error(
            `Cannot delete service "${service.title}" - Blogs exist!`
          );
        } else {
          setDeleteServiceId(serviceId);
        }
      }
    }
  };

  const removeService = async (serviceId) => {
    const updatedServices = services.filter(
      (service) => service.id !== serviceId
    );
    setServices(updatedServices);
    await updateServicesOnServer(updatedServices);
  };

  const onImageUpload = (service) => {
    setSelectedServiceForImageUpload(service);
    fileInputRef.current && fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file || !selectedServiceForImageUpload) return;
    try {
      const uniqueId =
        `service-${selectedServiceForImageUpload.title}-${selectedServiceForImageUpload.id}`
          ?.replaceAll(" ", "-")
          ?.toLowerCase();
      const formData = new FormData();
      formData.append("key", uniqueId);
      formData.append("value", uniqueId);
      formData.append("file", file);

      const res = await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (res.status) {
        const updatedServices = services.map((service) =>
          service.id === selectedServiceForImageUpload.id
            ? { ...service, image: res.data.file_name }
            : service
        );
        setServices(updatedServices);
        await updateServicesOnServer(updatedServices);
        toast.success("Service Image Updated.");
      }
    } catch (err) {
      const errorMsg =
        err.response?.data?.message || "An error occurred during image upload.";
      toast.error(errorMsg);
    } finally {
      setSelectedServiceForImageUpload(null);
      fileInputRef.current.value = null;
    }
  };

  const handleNewFile = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      setFileInfo({
        file,
        preview: URL.createObjectURL(file),
      });
    }
  }, []);

  const onPreviewImage = (service) => {
    const imageUrl = `${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${service?.image}`;
    setImagePreviewSrc(imageUrl);
  };

  return (
    <div
      className={`z-50 shadow-r shadow-black/15 bg-white dark:bg-gray-900 absolute transparent-scrollbar
        transition-all duration-500 ease-in-out
        ${open && "left-64"}
        ${
          isCustomizing === "services_listing"
            ? "w-96 opacity-100 h-[calc(100vh-6rem)] top-0 overflow-y-scroll"
            : "w-0 opacity-0 h-0 bottom-0 overflow-hidden"
        }
        border-r border-gray-200 dark:border-gray-800`}
    >
      <SectionHead
        title="Services Listing"
        setIsCustomizing={setIsCustomizing}
      />
      <div className="p-6 flex flex-col">
        <>
          {!showAddForm ? (
            <button
              onClick={() => setShowAddForm(true)}
              className="btnPrimary mb-6 py-2 flex items-center justify-center gap-2 w-full"
            >
              <Plus className="w-5 h-5" />
              Add New Service
            </button>
          ) : (
            <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm border border-gray-200 dark:border-gray-700 mb-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-medium text-gray-700 dark:text-gray-300">
                  Add New Service
                </h3>
                <button
                  onClick={resetForm}
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <div className="space-y-4">
                {/* Image Upload Section */}
                <div className="relative group">
                  <div className="aspect-video w-full rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700">
                    {fileInfo?.preview ? (
                      <img
                        src={fileInfo.preview}
                        alt="Preview"
                        className="w-full h-full object-cover"
                        onClick={() => setImagePreviewSrc(fileInfo.preview)}
                      />
                    ) : (
                      <div className="w-full h-full flex flex-col items-center justify-center">
                        <span className="text-sm text-gray-400 mb-2">
                          No image uploaded
                        </span>
                        <label
                          htmlFor="imageUpload"
                          className="px-4 py-2 border border-gray-300 hover:border-gray-400 
                            dark:border-gray-700 dark:hover:border-gray-600 rounded-md 
                            cursor-pointer transition-colors flex items-center gap-2"
                        >
                          <Camera className="w-5 h-5" />
                          <span className="text-sm">Choose Image</span>
                        </label>
                      </div>
                    )}
                    {fileInfo?.preview && (
                      <div
                        className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 
                          transition-opacity flex items-center justify-center cursor-pointer"
                        onClick={() => {
                          document.getElementById("imageUpload").click();
                        }}
                      >
                        <Camera className="w-6 h-6 text-white" />
                        <span className="text-white ml-2 text-sm">
                          Change Image
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                {/* Service Name Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Service Name
                  </label>
                  <input
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    className="inputField w-full py-2"
                    placeholder="Enter service name"
                  />
                </div>

                {/* Service Description */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Service Description
                  </label>
                  <textarea
                    value={newDescription}
                    onChange={(e) => setNewDescription(e.target.value)}
                    className="inputField w-full py-2 min-h-[100px] resize-y"
                    placeholder="Enter service description"
                  />
                </div>

                {/* Action Buttons */}
                <div className="flex justify-end gap-2">
                  <button
                    onClick={resetForm}
                    className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 
                      hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors"
                  >
                    Cancel
                  </button>
                  <button onClick={addService} className="btnPrimary py-2 px-6">
                    Add Service
                  </button>
                </div>
              </div>

              <input
                type="file"
                onChange={handleNewFile}
                id="imageUpload"
                className="hidden"
                accept="image/*"
              />
            </div>
          )}
        </>

        <div className="space-y-2">
          {isLoading ? (
            <LoaderCircle className="animate-spin h-10 w-10 mx-auto my-12 text-primary" />
          ) : services.length === 0 ? (
            <div className="text-center py-8 text-gray-500 dark:text-gray-400">
              No services added yet
            </div>
          ) : (
            services.map((service, index) => (
              <ServiceRow
                key={service.id}
                service={service}
                isEditing={editServiceId === service.id}
                isDeleting={deleteServiceId === service.id}
                onEdit={onEdit}
                onDelete={onDelete}
                onImageUpload={onImageUpload}
                onPreviewImage={onPreviewImage}
                onSaveEdit={onSaveEdit}
                onCancelEdit={onCancelEdit}
                hasPermission={hasPermission}
                project_id={project_id}
              />
            ))
          )}
        </div>

        {imagePreviewSrc && (
          <ImagePreviewModal
            imageSrc={imagePreviewSrc}
            onClose={() => setImagePreviewSrc(null)}
          />
        )}

        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
}

function ServiceRow({
  service,
  isEditing,
  isDeleting,
  onEdit,
  onDelete,
  onImageUpload,
  onPreviewImage,
  onSaveEdit,
  onCancelEdit,
  hasPermission,
  project_id,
}) {
  const [editInput, setEditInput] = useState(service.title);
  const [description, setDescription] = useState(service.description || "");

  useEffect(() => {
    if (!isEditing) {
      setEditInput(service.title);
      setDescription(service.description || "");
    }
  }, [isEditing, service.title, service.description]);

  const handleEditKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSaveEdit(service.id, editInput.trim(), description.trim());
    } else if (e.key === "Escape") {
      onCancelEdit();
    }
  };

  if (isEditing) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm border border-gray-200 dark:border-gray-700">
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-medium text-gray-700 dark:text-gray-300">
            Edit Service
          </h3>
          <button
            onClick={onCancelEdit}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-4">
          {/* Image Section */}
          <div className="relative group">
            <div className="aspect-video w-full rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700">
              {service.image ? (
                <img
                  src={`${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${service?.image}`}
                  className="w-full h-full object-cover"
                  alt="Service"
                  onClick={() => onPreviewImage(service)}
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <span className="text-sm text-gray-400">
                    No image uploaded
                  </span>
                </div>
              )}
              <div
                className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 
                transition-opacity flex items-center justify-center cursor-pointer"
                onClick={() => onImageUpload(service)}
              >
                <Camera className="w-6 h-6 text-white" />
                <span className="text-white ml-2 text-sm">Change Image</span>
              </div>
            </div>
          </div>

          {/* Service Name Input */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Service Name
            </label>
            <input
              value={editInput}
              onChange={(e) => setEditInput(e.target.value)}
              className="inputField w-full py-2"
              placeholder="Enter service name"
              autoFocus
            />
          </div>

          {/* Service Description */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Service Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onKeyDown={handleEditKeyDown}
              className="inputField w-full py-2 min-h-[100px] resize-y"
              placeholder="Enter service description"
            />
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end gap-2 pt-2">
            <button
              onClick={onCancelEdit}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 
              hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={() =>
                onSaveEdit(service.id, editInput.trim(), description.trim())
              }
              className="btnPrimary py-2 px-4 text-sm"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between p-3 bg-gray-50 hover:bg-gray-100 dark:bg-gray-800/50 dark:hover:bg-gray-800/80 rounded-lg transition-colors">
      <div className="flex items-center gap-3 flex-1">
        <div className="w-9 h-9 rounded-md overflow-hidden bg-white dark:bg-gray-700 shadow-sm">
          {service.image ? (
            <img
              src={`${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${service?.image}`}
              className="h-full w-full object-cover cursor-pointer"
              alt="Service"
              onClick={() => onPreviewImage(service)}
            />
          ) : (
            <div className="h-full w-full flex items-center justify-center">
              <span className="text-xs text-gray-400">No Image</span>
            </div>
          )}
        </div>

        <div className="flex-1">
          <span className="capitalize font-medium block">{service.title}</span>
          {service.description && (
            <span className="text-xs text-gray-500 dark:text-gray-400 line-clamp-1">
              {service.description}
            </span>
          )}
        </div>
      </div>

      <div className="flex items-center gap-1">
        <Camera
          className="w-5 h-5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 cursor-pointer transition-colors"
          onClick={() => onImageUpload(service)}
        />
        {/* {hasPermission("Edit Project Service") && ( */}
        <Edit
          className="w-5 h-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 cursor-pointer transition-colors"
          onClick={() => onEdit(service)}
        />
        {/* )} */}
        {/* {hasPermission("Delete Project Service") && ( */}
        <DeleteAction
          service={service}
          isDeleting={isDeleting}
          onDelete={onDelete}
        />
        {/* )} */}
      </div>
    </div>
  );
}

// Delete Service
const DeleteAction = ({ service, isDeleting, onDelete }) => {
  const ref = useRef(null);

  useClickOutside(ref, () => isDeleting && onDelete(null));

  return (
    <div className="relative">
      <Trash onClick={() => onDelete(service.id)} className="deleteIcon" />
      {isDeleting && (
        <div
          ref={ref}
          className="p-5 w-72 absolute top-0 right-0 mr-5 rounded-lg shadow-2xl border border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
        >
          <p className="font-medium">Please confirm to delete service</p>
          <div className="flex items-center justify-end mt-4">
            <button onClick={() => onDelete(null)} className="px-6">
              Cancel
            </button>
            <button
              onClick={() => onDelete(service.id, true)}
              className="btnPrimary bg-red-500"
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Image preview modal
function ImagePreviewModal({ imageSrc, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-10">
      <X
        className="w-12 h-12 absolute top-0 right-0 m-10 text-white cursor-pointer"
        onClick={onClose}
      />
      <div className="bg-gray-200 p-4 rounded shadow-lg max-h-[calc(100vh-200px)] max-w-screen-lg overflow-hidden">
        <img
          src={imageSrc}
          alt="Preview"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
}
