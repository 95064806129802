import React, { useState, useEffect, useMemo, useCallback } from "react";
import useApi from "../../utils/useApi";
import ProjectCard from "./ProjectCard";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import usePermissions from "../../utils/userPermission";
import Checkbox from "../../components/common/CheckBox";
import Pagination from "../../components/common/Pagination";
import TransferProjectSelectBox from "./TransferProjectSelectBox";

import {
  Breadcrumbs,
  ComboBox,
  InputField,
  ListBox,
  Modal,
  SelectBox,
} from "../../components";

import {
  Grid2X2,
  List,
  LoaderCircle,
  PlusCircle,
  RefreshCw,
  SlidersHorizontal,
} from "lucide-react";

const ProjectManager = () => {
  const { request } = useApi();
  const navigate = useNavigate();

  const [projects, setProjects] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [domains, setDomains] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [domain, setDomain] = useState(null);
  const [merchant, setMerchant] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectIndustry, setSelectedIndustry] = useState(null);
  const [template, setTemplate] = useState("");
  const [filterDomain, setFilterDomain] = useState("");
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [grid, setGrid] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [select, setSelect] = useState(false);

  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);
  const merchants = user?.merchants;

  const hasPermission = usePermissions().hasPermission;

  const fetchProjects = useCallback(async () => {
    try {
      const res = await request({ method: "get", url: "projects" });
      setProjects(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchIndustries = useCallback(async () => {
    try {
      const res = await request({ method: "get", url: "industries" });
      setIndustries(res.data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const fetchDomains = useCallback(async () => {
    try {
      const res = await request({
        method: "post",
        url: "domains/report",
        data: {},
      });
      setDomains(res.data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    fetchProjects();
    fetchIndustries();
    fetchDomains();
  }, [fetchProjects, fetchIndustries, fetchDomains]);

  const handleModalToggle = () => setOpen(!open);

  const handleConnect = (project) => {
    setSelectedProject(project);
    handleModalToggle();
  };

  const viewDomains = () => {
    navigate(`/projects/${selectedProject?.project_name}/domains`);
  };

  const connectDomain = async (e) => {
    e.preventDefault();
    try {
      await request({
        method: "post",
        url: `projects/${selectedProject?._id}/connect_domain`,
        data: { domain_id: domain._id },
      });
      toast.success("Your Domain Is Connected");
      viewDomains();
    } catch (err) {
      toast.error(
        err.response?.data?.message ||
          "Operation could not be performed, some error occurred."
      );
    }
  };

  const handleSearchChange = (event) => setSearchQuery(event.target.value);

  const reversedProjects = useMemo(() => [...projects].reverse(), [projects]);

  const filteredProjects = useMemo(() => {
    return reversedProjects.filter((project) => {
      const matchesSearchQuery = project.project_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesIndustry = selectIndustry
        ? project.industry_id._id === selectIndustry._id
        : true;
      const matchesTemplate = template
        ? project.industry_template_id.template_name
            .toLowerCase()
            .includes(template.toLowerCase())
        : true;
      const matchesDomain = filterDomain
        ? project?.domain_id?.domain
            .toLowerCase()
            .includes(filterDomain.toLowerCase())
        : true;

      return (
        matchesSearchQuery &&
        matchesIndustry &&
        matchesTemplate &&
        matchesDomain
      );
    });
  }, [reversedProjects, searchQuery, selectIndustry, template, filterDomain]);

  const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);
  const currentProjects = filteredProjects.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const isSelected = (project_id) =>
    selectedData?.some((item) => item._id === project_id);

  const onSelectAll = (checked) => {
    setSelectedData(checked ? projects : []);
  };

  const onSingleSelect = ({ checked, data }) => {
    setSelectedData((prev) =>
      checked ? [...prev, data] : prev.filter((item) => item._id !== data._id)
    );
  };

  const resetFilters = () => {
    setSearchQuery("");
    setTemplate("");
    setFilterDomain("");
    setSelectedIndustry(null);
  };

  if (!hasPermission("View Projects")) {
    return null;
  }

  return (
    <div className="h-[calc(100vh-3.5rem)] overflow-y-scroll">
      <Toaster />

      <Header
        page={page}
        grid={grid}
        loading={loading}
        setGrid={setGrid}
        totalPages={totalPages}
        industries={industries}
        showFilters={showFilters}
        searchQuery={searchQuery}
        itemsPerPage={itemsPerPage}
        fetchProjects={fetchProjects}
        hasPermission={hasPermission}
        setShowFilters={setShowFilters}
        selectIndustry={selectIndustry}
        setItemsPerPage={setItemsPerPage}
        handlePageChange={handlePageChange}
        handleSearchChange={handleSearchChange}
        setSelectedIndustry={setSelectedIndustry}
      />

      <SubHeader
        startItem={(page - 1) * itemsPerPage + 1}
        select={select}
        merchant={merchant}
        projects={projects}
        merchants={merchants}
        setSelect={setSelect}
        onSelectAll={onSelectAll}
        setMerchant={setMerchant}
        selectedData={selectedData}
        hasPermission={hasPermission}
        fetchProjects={fetchProjects}
        totalItems={filteredProjects.length}
        endItem={Math.min(page * itemsPerPage, filteredProjects.length)}
      />

      {showFilters && (
        <Filters
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
          industries={industries}
          selectIndustry={selectIndustry}
          setSelectedIndustry={setSelectedIndustry}
          template={template}
          setTemplate={setTemplate}
          filterDomain={filterDomain}
          setFilterDomain={setFilterDomain}
          resetFilters={resetFilters}
          setShowFilters={setShowFilters}
        />
      )}

      {loading ? (
        <LoadingState />
      ) : filteredProjects.length > 0 ? (
        <ProjectsGrid
          grid={grid}
          currentProjects={currentProjects}
          industries={industries}
          fetchProjects={fetchProjects}
          handleConnect={handleConnect}
          isSelected={isSelected}
          onSingleSelect={onSingleSelect}
          select={select}
          startItem={(page - 1) * itemsPerPage + 1}
          endItem={Math.min(page * itemsPerPage, filteredProjects.length)}
          totalItems={filteredProjects.length}
          totalPages={totalPages}
          page={page}
          handlePageChange={handlePageChange}
        />
      ) : (
        <NoProjectsFound />
      )}

      <ConnectDomainModal
        open={open}
        handleModalToggle={handleModalToggle}
        connectDomain={connectDomain}
        domain={domain}
        setDomain={setDomain}
        domains={domains}
      />
    </div>
  );
};

const Header = ({
  loading,
  fetchProjects,
  totalPages,
  page,
  setItemsPerPage,
  itemsPerPage,
  setGrid,
  grid,
  setShowFilters,
  showFilters,
  selectIndustry,
  setSelectedIndustry,
  industries,
  searchQuery,
  handleSearchChange,
  hasPermission,
  handlePageChange,
}) => (
  <div className="flex items-center flex-wrap gap-3 w-full py-2 px-6 bg-warmGray dark:bg-gray-800">
    <div className="text-white flex items-center gap-5">
      <h4>My Projects</h4>
      <RefreshCw
        onClick={fetchProjects}
        className={`w-5 cursor-pointer dark:text-white/80 ${
          loading && "animate-spin"
        }`}
      />
    </div>
    <Pagination
      totalPages={totalPages}
      currentPage={page}
      handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
    />
    <SelectBox
      value={itemsPerPage}
      onChange={(e) => {
        handlePageChange(1);
        setItemsPerPage(Number(e.target.value));
      }}
      options={[10, 20, 50, 100, 300]}
      buttonClass="py-[5px]"
      inputStyle="text-base"
    />
    <div className="flex items-center flex-1 bg-white dark:bg-gray-600 rounded">
      <ListBox
        name="industry"
        options={industries?.map((industry) => ({
          _id: industry._id,
          name: industry.industry_name,
        }))}
        selectedOption={selectIndustry}
        setSelectedOption={setSelectedIndustry}
        optionsStyle="mt-8"
        placeholder="Filter By Industry"
        inputStyle="border-none py-1"
        className="w-48"
      />
      <input
        type="text"
        className="border-l h-full py-1 px-5 outline-none bg-transparent flex-1 dark:border-white/20"
        placeholder="Search Projects"
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </div>
    <button
      onClick={() => setShowFilters(!showFilters)}
      className="btnOutlined py-1 text-base"
    >
      <SlidersHorizontal className="h-4 w-4 text-gray-100 dark:text-gray-300" />
      Filters
    </button>
    <div className="border-white/20 text-white dark:bg-gray-800 p-1 rounded border dark:border-white/30 flex items-center justify-center">
      <button
        onClick={() => setGrid(true)}
        className={`cursor-pointer rounded hover:bg-gray-300 dark:hover:text-white hover:text-black dark:hover:bg-gray-900 py-1 px-4 ${
          grid && "bg-gray-300 text-black dark:text-white dark:bg-gray-900"
        }`}
      >
        <Grid2X2 className="w-4 h-4" />
      </button>
      <button
        onClick={() => setGrid(false)}
        className={`cursor-pointer rounded hover:bg-gray-300 dark:hover:text-white hover:text-black dark:hover:bg-gray-900 py-1 px-4 ${
          !grid && "bg-gray-300 text-black dark:text-white dark:bg-gray-900"
        }`}
      >
        <List className="w-4 h-4" />
      </button>
    </div>
    {hasPermission("add project") && (
      <Link to="/new-project" className="btnPrimary text-base py-1 px-3">
        <PlusCircle className="h-4 w-4" /> Add Project
      </Link>
    )}
  </div>
);

const SubHeader = ({
  startItem,
  endItem,
  totalItems,
  select,
  setSelect,
  selectedData,
  onSelectAll,
  merchants,
  merchant,
  setMerchant,
  fetchProjects,
  hasPermission,
  projects,
}) => (
  <div className="flex items-center flex-wrap justify-between w-full px-6 mb-2 mt-4">
    <div className="flex items-center gap-5">
      <Breadcrumbs />
      <p className="text-gray-700 dark:text-gray-400 whitespace-nowrap -mt-1">
        (Showing {startItem} to {endItem} of {totalItems} Projects)
      </p>
    </div>
    {hasPermission("transfer projects") && (
      <div className="flex items-center justify-center gap-2">
        {select ? (
          <div className="flex items-center justify-center gap-2">
            <button
              className="btnWhite bg-red-200 text-red-700"
              onClick={() => setSelect(false)}
            >
              Cancel Select
            </button>
            <Checkbox
              className="btnWhite px-3"
              label="Select All"
              onChange={(e) => onSelectAll(e.target.checked)}
              checked={
                projects?.length && projects?.length === selectedData?.length
              }
            />
          </div>
        ) : (
          <button className="btnWhite" onClick={() => setSelect(true)}>
            Select Projects
          </button>
        )}
        <TransferProjectSelectBox
          merchants={merchants}
          merchant={merchant}
          setMerchant={setMerchant}
          selectedData={selectedData}
          fetchProjects={fetchProjects}
        />
      </div>
    )}
  </div>
);

const Filters = ({
  searchQuery,
  handleSearchChange,
  industries,
  selectIndustry,
  setSelectedIndustry,
  template,
  setTemplate,
  filterDomain,
  setFilterDomain,
  resetFilters,
  setShowFilters,
}) => (
  <div className="px-6">
    <div className="bg-white p-5 w-full rounded">
      <h5 className="border-b">Add Filters</h5>
      <div className="grid grid-cols-2 gap-5 mt-5">
        <InputField
          label="Project Name"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <ListBox
          name="industry"
          options={industries?.map((industry) => ({
            _id: industry._id,
            name: industry.industry_name,
          }))}
          selectedOption={selectIndustry}
          setSelectedOption={setSelectedIndustry}
          optionsStyle="mt-8"
          placeholder="Category"
          label="Category"
        />
        <InputField
          label="Template"
          value={template}
          onChange={(e) => setTemplate(e.target.value)}
        />
        <InputField
          label="Domain"
          value={filterDomain}
          onChange={(e) => setFilterDomain(e.target.value)}
        />
      </div>
      <div className="flex items-center justify-end gap-2 mt-5">
        <button
          onClick={() => setShowFilters(false)}
          className="btnPrimary bg-red-600"
        >
          Close
        </button>
        <button onClick={resetFilters} className="btnPrimary">
          Reset
        </button>
      </div>
    </div>
  </div>
);

const ProjectsGrid = ({
  grid,
  currentProjects,
  industries,
  fetchProjects,
  handleConnect,
  isSelected,
  onSingleSelect,
  select,
  startItem,
  endItem,
  totalItems,
  totalPages,
  page,
  handlePageChange,
}) => (
  <>
    <div
      className={`grid px-6 ${
        grid
          ? "grid-cols-1 lg:grid-cols-4 gap-6"
          : " md:grid-cols-2 lg:grid-cols-1 gap-4"
      } mt-5`}
    >
      {currentProjects.map((project, index) => (
        <ProjectCard
          key={index}
          grid={grid}
          industries={industries}
          project={project}
          getProjects={fetchProjects}
          handleConnect={handleConnect}
          isSelected={isSelected}
          onSingleSelect={onSingleSelect}
          select={select}
        />
      ))}
    </div>
    <div className="flex items-center justify-between my-6 px-6">
      <p className="text-gray-700 dark:text-gray-400 whitespace-nowrap">
        Showing {startItem} to {endItem} of {totalItems} Projects
      </p>
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        handlePageChange={handlePageChange}
        className="text-black dark:text-white"
      />
    </div>
  </>
);

const NoProjectsFound = () => (
  <div className="text-center text-gray-500 py-44">No projects found</div>
);

const LoadingState = () => (
  <div className="flex flex-col items-center w-full p-44 gap-5">
    <LoaderCircle className="h-12 w-12 animate-spin text-primary" />
    <p>Loading Projects...</p>
  </div>
);

const ConnectDomainModal = ({
  open,
  handleModalToggle,
  connectDomain,
  domain,
  setDomain,
  domains,
}) => (
  <Modal
    open={open}
    handleModal={handleModalToggle}
    className="max-w-xl"
    title={<span className="text-2xl">Connect Domain</span>}
    handleModalSubmit={connectDomain}
  >
    <div className="flex items-end gap-2 mt-1">
      <ComboBox
        label="Select An Existing Domain"
        placeholder="Select"
        selectedOption={domain}
        setSelectedOption={setDomain}
        options={domains?.map((item) => ({
          _id: item._id,
          name: item.domain,
        }))}
        optionPadding="py-1 px-3"
        optionsListHeight="max-h-36"
        className="flex-1"
      />
      <button
        disabled={!domain}
        onClick={connectDomain}
        className="btnPrimary bg-green-500 text-base disabled:cursor-not-allowed"
      >
        Connect
      </button>
    </div>
    <div className="flex items-center justify-center w-full gap-5 mt-7 mb-5">
      <div className="h-[1px] bg-gray-300 dark:bg-white/20 flex-1"></div>
      <p className="text-center text-gray-400 my-2">Or</p>
      <div className="h-[1px] bg-gray-300 dark:bg-white/20 flex-1"></div>
    </div>
    <div className="flex items-end gap-3">
      <InputField
        label="Add a new domain"
        placeholder="mywebsite.com"
        disabled
      />
      <button
        disabled
        className="btnPrimary text-base disabled:cursor-not-allowed"
      >
        Add
      </button>
    </div>
  </Modal>
);

export default ProjectManager;
