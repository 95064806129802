import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Breadcrumbs, SelectBox } from "../../components";
import useApi from "../../utils/useApi";
import {
  BoxSelect,
  BringToFront,
  LoaderCircle,
  Minimize,
  X,
} from "lucide-react";
import AddUpdateDomain from "./AddUpdateDomain";
import DomainCard from "./DomainCard";
import DomainFilters from "./DomainFilters";
import toast, { Toaster } from "react-hot-toast";
import usePermissions from "../../utils/userPermission";
import Checkbox from "../../components/common/CheckBox";
import Dmheader from "./Dmheader";
import Pagination from "../../components/common/Pagination";

const DomainsManager = () => {
  const [domains, setDomains] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(250);
  const [searchQuery, setSearchQuery] = useState("");

  const { isLoading, error, request } = useApi();
  const deleteRef = useRef({});
  const [loading, setLoading] = useState(false);
  const domainFiltersRef = useRef(null);
  const [filters, setFilters] = useState({});

  const [industries, setIndustries] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [deleteD, setDelete] = useState(null);
  const [expandedCards, setExpandedCards] = useState({});
  const [isAllExpanded, setIsAllExpanded] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [domainToUpdate, setUpdate] = useState({});
  const [modalType, setModaltype] = useState("");
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);

  const [projects, setProjects] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [merchant, setMerchant] = useState({});

  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);
  const merchants = user?.merchants;

  const { hasPermission } = usePermissions();

  const handleModal = useCallback(() => setOpen((prev) => !prev), []);
  const handleNew = useCallback(() => {
    setModaltype("new");
    handleModal();
  }, [handleModal]);

  const handleUpdate = useCallback(
    (domain) => {
      setUpdate(domain);
      setModaltype("update");
      handleModal();
    },
    [handleModal]
  );

  const getDomains = useCallback(
    async (filters) => {
      setLoading(true);
      try {
        const res = await request({
          method: "post",
          url: "domains/report",
          data: {
            page: page.toString(),
            size: pageSize,
            filters: filters ? filters : "",
            sort_field: "",
            sort_by: "",
          },
        });
        setTotalItems(res.totalItems);
        setDomains(res.data);
        setExpandedCards(
          res.data?.reduce((acc, _, index) => ({ ...acc, [index]: true }), {})
        );
        setIsAllExpanded(true);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    },
    [page, pageSize]
  );

  useEffect(() => {
    getDomains();
  }, [page, pageSize]);

  const handleSearch = useCallback(
    (e) => setSearchQuery(e.target.value.toLowerCase()),
    []
  );

  const [deleting, setDeleting] = useState(false);
  const deleteDomain = useCallback(
    async (e, domain) => {
      e.preventDefault();
      setDeleting(true);
      try {
        await request({
          method: "delete",
          url: `domains/${domain._id}`,
        });
        setDomains((prevDomains) =>
          prevDomains.filter((d) => d._id !== domain._id)
        );
        setDelete(null);
        toast.success(`${domain.domain} domain removed.`);
      } catch (err) {
        console.error(err);
      }
      setDeleting(false);
    },
    [request]
  );

  const [hasFetchedProjects, setHasFetchedProjects] = useState(false);

  const getProjects = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: "projects",
      });
      setProjects(res.data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (hasPermission("connect domain") && !hasFetchedProjects) {
      const timer = setTimeout(() => {
        getProjects();
        setHasFetchedProjects(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [hasPermission, hasFetchedProjects, getProjects]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        domainFiltersRef.current &&
        !domainFiltersRef.current.contains(event.target)
      ) {
        setShowFilters(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (error) toast.error(error);
  }, [error]);

  const handleCollapseAll = useCallback(() => {
    setExpandedCards((prev) =>
      Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );
    setIsAllExpanded(false);
  }, []);

  const handleExpandAll = useCallback(() => {
    setExpandedCards((prev) =>
      Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: true }), {})
    );
    setIsAllExpanded(true);
  }, []);

  const toggleExpandCollapse = useCallback(() => {
    isAllExpanded ? handleCollapseAll() : handleExpandAll();
  }, [isAllExpanded, handleCollapseAll, handleExpandAll]);

  const startItem = (page - 1) * pageSize + 1;
  const endItem = Math.min(page * pageSize, totalItems);

  const getIndustries = useCallback(async () => {
    try {
      const data = await request({ method: "get", url: "industries" });
      setIndustries(data.data);
    } catch (err) {
      console.error(err);
    }
  }, [request]);

  useEffect(() => {
    getIndustries();
  }, []);

  const [buyTypes, setBuyTypes] = useState([]);
  const [registrars, setRegistrars] = useState([]);
  const [niches, setNiches] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [domainStatuses, setDomainStatuses] = useState([]);
  const [domainTypes, setDomainTypes] = useState([]);
  const [hostingProviders, setHostingProviders] = useState([]);

  const getData = useCallback(
    async (url, setState) => {
      try {
        const data = await request({ method: "get", url });
        setState(data.data);
      } catch (err) {
        console.error(err);
      }
    },
    [request]
  );

  useEffect(() => {
    getData("masters/buy_type", setBuyTypes);
    getData("masters/registrar", setRegistrars);
    getData("masters/niche", setNiches);
    getData("masters/association", setAssociations);
    getData("masters/domain_status", setDomainStatuses);
    getData("masters/hosting_provider", setHostingProviders);
    getData("masters/domain_type", setDomainTypes);
  }, []);

  const isSelected = useCallback(
    (domain_id) => {
      return selectedData.some((item) => item._id === domain_id);
    },
    [selectedData]
  );

  const onSelectAll = useCallback(
    (checked) => {
      if (checked) {
        setSelectedData(domains);
      } else {
        setSelectedData([]);
      }
    },
    [domains]
  );

  const onSingleSelect = useCallback(({ checked, data }) => {
    setSelectedData((prev) => {
      if (checked) return [...prev, data];
      return prev.filter((item) => item._id !== data._id);
    });
  }, []);

  const [transfering, setTransfering] = useState(false);
  const transferDomains = useCallback(async () => {
    setTransfering(true);
    if (selectedData.length < 1) return toast.error("No Domains Selected");
    if (!merchant._id) return toast.error("No Merchant Selected");

    try {
      await request({
        method: "post",
        url: `domains/change_merchant/${merchant._id}`,
        data: { domain_ids: selectedData.map((item) => item._id) },
      });
      toast.success(`Domains Transferred Successfully to ${merchant.name}`);
      getDomains();
      setDropdown(false);
      setMerchant({});
    } catch (err) {
      console.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }
    setTransfering(false);
  }, [selectedData, merchant, request]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target))
        setDropdown(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handlePageChange = useCallback((newPage) => setPage(newPage), []);
  const totalPages = Math.ceil(totalItems / pageSize);

  const filteredDomains =
    useMemo(() => {
      return domains?.filter((domain) =>
        domain.domain.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }, [domains, searchQuery]) || [];

  const [showSelect, setShowSelect] = useState(false);
  const onShowSelect = useCallback(() => {
    setShowSelect((prev) => !prev);
  }, []);

  return (
    hasPermission("View Domains") && (
      <div>
        <Toaster position="top-center" reverseOrder={false} />
        <Dmheader
          page={page}
          pageSize={pageSize}
          setPageSize={setPageSize}
          isLoading={isLoading}
          getDomains={getDomains}
          totalPages={totalPages}
          setShowFilters={setShowFilters}
          handleNew={handleNew}
          hasPermission={hasPermission}
          handlePageChange={handlePageChange}
          showFilters={showFilters}
          searchQuery={searchQuery}
          handleSearch={handleSearch}
        />
        <div className="flex items-center justify-between w-full flex-wrap px-6 py-3">
          <div className="flex items-center gap-5">
            <Breadcrumbs />
            <p className="text-gray-700 dark:text-gray-400 whitespace-nowrap">
              (Showing {startItem} to {endItem} of {totalItems} domains)
            </p>
          </div>
          <div className="flex items-center justify-center gap-2">
            {!showSelect ? (
              <button onClick={onShowSelect} className="btnWhite px-3">
                <BoxSelect className="w-4 h-4" />
                Select
              </button>
            ) : (
              <>
                {hasPermission("Transfer Domains") && (
                  <Checkbox
                    className="btnWhite px-3"
                    label="Select All"
                    onChange={(e) => onSelectAll(e.target.checked)}
                    checked={
                      filteredDomains.length > 0 &&
                      filteredDomains.length === selectedData.length
                    }
                  />
                )}
                <button
                  onClick={onShowSelect}
                  className="btnPrimary bg-red-500 px-3"
                >
                  <X className="w-4 h-4" />
                  Cancel Select
                </button>
              </>
            )}
            {hasPermission("Transfer Domains") && (
              <TransferDomainSelectBox
                dropdown={dropdown}
                setDropdown={setDropdown}
                ref={dropdownRef}
                merchants={merchants}
                merchant={merchant}
                setMerchant={setMerchant}
                transferDomains={transferDomains}
                transfering={transfering}
              />
            )}
            <button onClick={toggleExpandCollapse} className="btnWhite px-3">
              <Minimize className="w-4 h-4" />
              {isAllExpanded ? "Collapse All" : "Expand All"}
            </button>
          </div>
        </div>
        <div className="px-6 pb-5">
          {showFilters && (
            <DomainFilters
              setShowFilters={setShowFilters}
              domainFiltersRef={domainFiltersRef}
              buyTypes={buyTypes}
              registrars={registrars}
              niches={niches}
              associations={associations}
              domainStatuses={domainStatuses}
              hostingProviders={hostingProviders}
              domainTypes={domainTypes}
              getDomains={getDomains}
              setFilters={setFilters}
            />
          )}
          <div className="">
            {loading ? (
              <div className="flex justify-center items-center text-center h-[70vh] flex-col gap-3">
                <LoaderCircle className="animate-spin h-16 w-16 text-primary" />
                Loading Domains..
              </div>
            ) : filteredDomains.length === 0 ? (
              <div className="text-center text-gray-600 dark:text-gray-300 py-44">
                No domains available.
              </div>
            ) : (
              <div className="flex flex-col overflow-hidden">
                {filteredDomains.map((item, index) => (
                  <DomainCard
                    key={index}
                    item={item}
                    index={
                      pageSize === "all"
                        ? index + 1
                        : (page - 1) * pageSize + index + 1
                    }
                    handleUpdate={handleUpdate}
                    deleteRef={deleteRef}
                    deleteD={deleteD}
                    setDelete={setDelete}
                    projects={projects}
                    deleteDomain={deleteDomain}
                    isOpen={expandedCards[index]}
                    setIsOpen={() =>
                      setExpandedCards({
                        ...expandedCards,
                        [index]: !expandedCards[index],
                      })
                    }
                    industries={industries}
                    isSelected={isSelected}
                    onSingleSelect={onSingleSelect}
                    getDomains={getDomains}
                    deleting={deleting}
                    showSelect={showSelect}
                  />
                ))}
                <div className="flex items-center justify-between gap-5 mt-6">
                  <p className="text-gray-700 dark:text-gray-400 whitespace-nowrap -mt-1">
                    Showing {startItem} to {endItem} of {totalItems} domains
                  </p>
                  <div className="flex items-center justify-end">
                    <Pagination
                      totalPages={totalPages}
                      currentPage={page}
                      handlePageChange={handlePageChange}
                      className="text-black dark:text-white"
                    />
                    <SelectBox
                      value={pageSize}
                      onChange={(e) => setPageSize(e.target.value)}
                      options={[250, 100, 50, "all"]}
                      buttonClass="py-[5px]"
                      inputStyle="text-base text-black "
                      className="text-black border border-gray-300 dark:border-white/20 mr-4 rounded-md"
                    />

                    <button
                      onClick={toggleExpandCollapse}
                      className="btnWhite px-3"
                    >
                      <Minimize className="w-4 h-4" />
                      {isAllExpanded ? "Collapse All" : "Expand All"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {(hasPermission("Add Domain") || hasPermission("edit domain")) && (
            <AddUpdateDomain
              open={open}
              handleModal={handleModal}
              cancelButtonRef={cancelButtonRef}
              getDomains={getDomains}
              modalType={modalType}
              domainToUpdate={domainToUpdate}
              buyTypes={buyTypes}
              registrars={registrars}
              niches={niches}
              associations={associations}
              domainStatuses={domainStatuses}
              hostingProviders={hostingProviders}
              domainTypes={domainTypes}
              filters={filters}
            />
          )}
        </div>
      </div>
    )
  );
};

const TransferDomainSelectBox = React.forwardRef(
  (
    {
      dropdown,
      setDropdown,
      merchant,
      merchants,
      setMerchant,
      transferDomains,
      transfering,
    },
    ref
  ) => (
    <div className="relative text-black dark:text-white/80">
      {dropdown && (
        <div
          ref={ref}
          className="flex-col w-[300px] z-50 items-center absolute top-0 right-0 mt-10 p-5 bg-white dark:bg-gray-800 rounded-md capitalize shadow-xl"
        >
          <p className="font-medium mb-2 border-b">Select a merchant.</p>
          <div className="flex flex-col">
            {merchants?.map((item, index) => (
              <label
                key={index}
                className={`py-2 px-3 border-b flex items-center gap-2 cursor-pointer last:border-none hover:bg-gray-100 hover:text-black dark:hover:bg-white/10 rounded w-full transition-all text-left ${
                  item._id === merchant?._id && "bg-warmGray text-white"
                }`}
                htmlFor={`merchant_${item._id}`}
              >
                <input
                  type="radio"
                  id={`merchant_${item._id}`}
                  name="merchant"
                  value={item._id}
                  checked={item._id === merchant?._id}
                  onChange={(e) => setMerchant(item)}
                />
                {item.name}
              </label>
            ))}
          </div>
          <div className="flex items-center justify-end mt-3">
            <button
              type="button"
              onClick={transferDomains}
              className="btnPrimary bg-black"
            >
              {transfering && <LoaderCircle className="w-4 h-4 animate-spin" />}
              {transfering ? <p>Transfering</p> : <p>Transfer Now</p>}
            </button>
          </div>
        </div>
      )}
      <button
        onClick={() => setDropdown((prev) => !prev)}
        className="btnWhite relative px-3"
      >
        <BringToFront className="w-4 h-4" />
        Transfer Domains
      </button>
    </div>
  )
);

export default DomainsManager;
