import React, { useState } from "react";
import dayjs from "dayjs";
import { BadgeCheck, CheckCheck, ChevronUp, Copy, Layers3 } from "lucide-react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function ReportDomainCard({ index, item, isOpen, setIsOpen }) {
  const [isCopied, setIsCopied] = useState(null);

  const copyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(index);
        setTimeout(() => {
          setIsCopied(null);
        }, 2000);
        toast.success("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy to clipboard");
      });
  };

  const renderDetailItem = (label, value, className) => (
    <p className={className}>
      <b className="text-gray-600 dark:text-white">{label}:</b> {value}
    </p>
  );

  const renderNameServers = () =>
    item?.cloudflair_details?.name_servers.map((ns, idx) => (
      <div className="flex items-center gap-2" key={idx}>
        {isCopied === idx ? (
          <CheckCheck className="w-4 text-green-500" />
        ) : (
          <Copy
            className="w-4 cursor-pointer text-gray-500 dark:text-white/80 hover:text-primary transition"
            onClick={() => copyToClipboard(ns, idx)}
          />
        )}
        <p className="flex-1 text-gray-700 dark:text-gray-300">{ns}</p>
      </div>
    ));

  const navigate = useNavigate();
  const manageProject = (project_name) => {
    navigate(`/projects/${project_name?.toLowerCase()?.replaceAll(" ", "-")}`);
  };

  const viewBacklinkReport = (domain) => {
    navigate(`/reports/${domain?.domain}`, {
      state: {
        domain_id: domain._id,
      },
    });
  };

  return (
    <div
      className={`bg-white dark:bg-gray-800 transition-all duration-300 ${
        isOpen
          ? "mb-5 mt-2 rounded-xl shadow-lg border border-gray-100 dark:border-white/10"
          : "last:shadow-md border-b border-gray-100 dark:border-white/10"
      }`}
    >
      <div
        className={`grid md:grid-cols-domainCard items-center px-6 py-2 ${
          isOpen ? "py-4" : "py-2"
        }`}
      >
        <div className="flex items-center gap-3 text-sm">
          <span className="font-medium text-gray-500 dark:text-gray-400">
            {index}
          </span>
          <div className="flex items-center gap-2">
            <a
              href={`http://${item.domain}`}
              target="_blank"
              rel="noreferrer"
              className="text-primary hover:text-primary/80 font-medium transition-all"
            >
              {item.domain}
            </a>
            <div className="pagesIcon relative">
              <Layers3 className="w-4 h-4 text-gray-400 hover:text-secondary transition-colors cursor-pointer" />
              <div
                className={`pagesList bg-white dark:bg-gray-700 z-20 absolute top-0 left-0 p-5 rounded-lg shadow-xl flex flex-col gap-1 mt-3 min-h-20 min-w-20 ${
                  item.ahref_backlink_custom_pages?.length > 18 &&
                  "overflow-y-scroll max-h-96"
                }`}
              >
                {item.ahref_backlink_custom_pages?.length > 0 ? (
                  item.ahref_backlink_custom_pages?.map((link, index) => (
                    <p key={index} className="whitespace-nowrap">
                      {index + 1}.{" "}
                      <a
                        href={`http://${item.domain}${link}`}
                        target="_blank"
                        rel="noreferrer"
                        className="underline hover:text-primary transition-all cursor-pointer"
                      >
                        {link}
                      </a>
                    </p>
                  ))
                ) : (
                  <p className="text-gray-400 dark:text-gray-200 whitespace-nowrap">
                    No Pages To Show
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-4 text-sm my-2 md:my-0">
          <button
            onClick={() => viewBacklinkReport(item)}
            className="underline text-secondary font-medium hover:text-primary/80 transition-all"
          >
            Backlink Report
          </button>

          <div className="flex items-center gap-4">
            <span
              className={`px-2 py-1 rounded-full text-xs w-16 text-center font-medium ${
                item?.domain_status_id?.name === "Live"
                  ? "bg-green-50 text-green-700"
                  : "bg-gray-100 text-gray-700"
              }`}
            >
              {item?.domain_status_id?.name}
            </span>

            <span className="text-gray-600 dark:text-gray-300 text-xs">
              {item?.niche_id?.name}
            </span>
          </div>

          <div className="flex items-center gap-1">
            <span className="text-gray-500 text-xs">Project:</span>
            {item?.project_id?.project_name ? (
              <span
                onClick={() => manageProject(item?.project_id?.project_name)}
                className="bg-green-50 text-green-600 hover:bg-green-600 hover:text-white px-1.5 py-1 rounded-full text-xs font-medium cursor-pointer transition-all flex items-center gap-1"
              >
                {item?.project_id?.project_name}
                <BadgeCheck className="w-3.5 h-3.5" />
              </span>
            ) : (
              <span className="text-xs text-gray-400">Not Connected</span>
            )}
          </div>
        </div>

        <div className="flex items-center justify-end gap-4">
          <span className="text-xs text-gray-500 dark:text-gray-400">
            {dayjs(item?.createdAt)?.format("D-MMM-YYYY")}
          </span>
          <button
            onClick={setIsOpen}
            className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
          >
            <ChevronUp
              className={`w-5 transition-transform duration-300 text-gray-400 ${
                isOpen && "rotate-180"
              }`}
            />
          </button>
        </div>
      </div>

      <div
        className={`transition-all duration-300 ease-in-out px-6 ${
          isOpen ? "max-h-screen pb-6" : "max-h-0 overflow-hidden"
        }`}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 bg-gray-50 dark:bg-gray-900/50 rounded-xl p-4">
          <div className="space-y-2">
            {["Price", "Buy Type", "Traffic", "Trust Flow"].map((label) => (
              <div key={label} className="flex justify-between items-center">
                <span className="text-gray-500 text-sm">{label}</span>
                <span className="text-gray-900 dark:text-white font-medium text-sm">
                  {item[label.toLowerCase().replace(" ", "_")] || "-"}
                </span>
              </div>
            ))}
          </div>
          <div>
            {renderDetailItem("Domain Type", item?.domain_type_id?.name)}
            {renderDetailItem("Association", item?.association_id?.name)}
            {renderDetailItem("Referring Domains", item?.refering_domains)}
            {renderDetailItem("Indexed", item?.domain_indexed ? "Yes" : "No")}
          </div>
          <div>
            {renderDetailItem("Age", item?.age)}
            {renderDetailItem("Articles Count", item?.articles_count)}
            {renderDetailItem("Backlinks", item?.backLinks)}
            {renderDetailItem("Registrar", item?.registrar_id?.name)}
          </div>
          <div>
            {renderDetailItem("Citation Flow", item?.citation_flow)}
            {renderDetailItem("Organic Kw", item?.oraganic_kw)}
            {renderDetailItem("UR", item?.ur)}
            {renderDetailItem("DR", item?.dr)}
          </div>
        </div>

        {item?.ahref_info && (
          <div className="mt-6">
            <h6 className="text-sm font-semibold text-secondary mb-2">
              Ahref Information
            </h6>
            <div className="bg-gray-50 dark:bg-gray-900/50 rounded-xl p-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
                {renderDetailItem(
                  "Domain Rating",
                  item?.ahref_info?.domain_rating?.domain_rating
                )}
                {renderDetailItem(
                  "ahrefs rank",
                  item?.ahref_info?.domain_rating?.ahrefs_rank
                )}
                {renderDetailItem(
                  "Live",
                  item?.ahref_info?.backlinks_stats?.live
                )}
                {renderDetailItem(
                  "All Time",
                  item?.ahref_info?.backlinks_stats?.all_time
                )}
                {renderDetailItem(
                  "live refdomains",
                  item?.ahref_info?.backlinks_stats?.live_refdomains
                )}
                {renderDetailItem(
                  "all time refdomains",
                  item?.ahref_info?.backlinks_stats?.all_time_refdomains
                )}
              </div>
            </div>
          </div>
        )}

        {item?.cloudflair_account && (
          <div className="mt-6">
            <h6 className="text-sm font-semibold text-secondary mb-2">
              Cloudflare Details
            </h6>
            <div className="bg-gray-50 dark:bg-gray-900/50 rounded-xl p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
                {renderDetailItem(
                  "Auth Email",
                  item?.cloudflair_account?.auth_email
                )}
                {renderDetailItem(
                  "Auth Key",
                  item?.cloudflair_account?.auth_key
                )}
                {renderDetailItem(
                  "Account Id",
                  item?.cloudflair_account?.account_id
                )}
                {renderDetailItem(
                  "Domain Status",
                  item?.cloudflair_details?.status
                )}
                <div>
                  {renderDetailItem("Nameservers", "")}
                  <div className="flex items-center gap-5 flex-wrap mt-1">
                    {renderNameServers()}
                  </div>
                </div>
                {renderDetailItem("Type", item?.cloudflair_details?.type)}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
