import React from "react";
import ManageArticle from "./ManageArticle";
import { ArticleProvider } from "../ArticleContext";
import ArticlePreview from "./ArticlePreview";

export default function WriteArticle() {
  return (
    <ArticleProvider>
      <div className="grid grid-cols-1 lg:grid-cols-article">
        <ArticlePreview />
        <ManageArticle />
      </div>
    </ArticleProvider>
  );
}
