import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Camera,
  CheckCheck,
  Copy,
  Info,
  LoaderCircle,
  Save,
  X,
} from "lucide-react";
import { formatFileSize } from "../../ProjectGallery";
import useApi from "../../../../../utils/useApi";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { InputField } from "../../../../../components";
import imageCompression from "browser-image-compression";
import { debounce } from "lodash";

// Component for rendering individual image items
const ImageItem = ({ item, image, copyToClipboard, isCopied }) => (
  <div className="relative">
    <div className="relative overflow-hidden bg-black rounded-md h-24">
      <img
        alt="preview"
        className="object-fill absolute m-auto min-w-full min-h-full"
        src={image}
      />
    </div>
    <button
      className="btnWhite absolute bottom-0 right-0 m-2 p-1.5 gap-1"
      onClick={(e) => copyToClipboard(item, e)}
    >
      {isCopied === item.key ? (
        <CheckCheck className="w-4 h-4 text-green-500" />
      ) : (
        <Copy className="w-4 h-4 text-gray-500 dark:text-white/80" />
      )}
      Copy
    </button>
  </div>
);

export default function ProjectGallery({
  project_id,
  handleModal,
  sidebar,
  toggleSidebar,
}) {
  const { request } = useApi();
  const [images, setImages] = useState([]);
  const [isCopied, setIsCopied] = useState(null);
  const [galleryFile, setGalleryFile] = useState(null);
  const [image, setImage] = useState({});
  const [compressing, setCompressing] = useState(false);
  const [selectedSize, setSelectedSize] = useState(50);
  const [originalFile, setOriginalFile] = useState(null);

  // Fetch gallery images
  const getGallery = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/gallery`,
      });
      setImages(res.data[0]?.value || []);
    } catch (err) {
      console.error("Failed to fetch gallery:", err);
      toast.error("Failed to fetch gallery.");
      setImages([]);
    }
  }, [project_id, request]);

  useEffect(() => {
    getGallery();
  }, [project_id]);

  // Handle copying image URL to clipboard
  const copyToClipboard = useCallback(
    (item, e) => {
      e.preventDefault();
      navigator.clipboard
        .writeText(
          `![${item.imageAltText}](${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${item.image} "${item.imageTitle}")`
        )
        .then(() => {
          setIsCopied(item.key);
          setTimeout(() => setIsCopied(null), 2000);
          toast.success("Image URL Copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          toast.error("Failed to copy to clipboard");
        });
    },
    [project_id, handleModal]
  );

  // Update compression handler to use selected size
  const compressImage = useCallback(async (file, targetSize) => {
    try {
      setCompressing(true);
      const compressionOptions = {
        maxSizeMB: targetSize / 1024, // Convert KB to MB
        maxWidthOrHeight: 1000,
        useWebWorker: true,
        initialQuality: 0.5,
        fileType: "image/jpeg",
      };

      const compressedFile = await imageCompression(file, compressionOptions);

      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          setGalleryFile({ file: compressedFile, preview: reader.result });
          setImage((prevImage) => ({
            ...prevImage,
            fileSize: formatFileSize(compressedFile.size),
            fileType: compressedFile.type,
            dimensions: `${width}x${height}`,
            error: null,
          }));
        };
      };
      reader.readAsDataURL(compressedFile);
    } catch (err) {
      console.error("Error compressing image:", err);
      toast.error("Failed to compress image");
    } finally {
      setCompressing(false);
    }
  }, []);

  // Update compression handler to use debounce
  const debouncedCompress = useCallback(
    debounce((file, size) => {
      compressImage(file, size);
    }, 300),
    []
  );

  // Handle slider change
  const handleSizeChange = useCallback(
    (e) => {
      const newSize = parseInt(e.target.value);
      setSelectedSize(newSize);
      if (originalFile) {
        debouncedCompress(originalFile, newSize);
      }
    },
    [originalFile, debouncedCompress]
  );

  // Modify handleFileChange to store original file
  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      setOriginalFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          setGalleryFile({ file, preview: reader.result });
          setImage((prevImage) => ({
            ...prevImage,
            fileSize: formatFileSize(file.size),
            fileType: file.type,
            dimensions: `${width}x${height}`,
            error: null,
          }));
        };
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const handleChange = (e) => {
    setImage((prevImage) => ({
      ...prevImage,
      [e.target.name]: e.target.value,
    }));
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const createUpdate = useCallback(
    async (event) => {
      event.preventDefault();

      // Check file size before uploading
      if (galleryFile?.file && galleryFile.file.size > 100 * 1024) {
        toast.error(
          "Image size must be under 100KB. Please compress the image first."
        );
        return;
      }

      setIsUpdating(true);

      if (!image.imageTitle || !image.imageAltText) {
        toast.error("Image title and alt text cannot be empty.");
        setIsUpdating(false);
        return;
      }

      const uniqueKey = image.key || `image-${uuidv4()}`;
      const updatedImage = { ...image, key: uniqueKey };

      try {
        const formData = new FormData();
        formData.append("key", uniqueKey);
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(updatedImage));
        if (galleryFile?.file) {
          formData.append("file", galleryFile.file);
        }

        const res = await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (res.status) {
          const imageWithResponseData = {
            ...updatedImage,
            _id: res.data._id,
            key: res.data.key,
            image: res.data.file_name,
            createdAt: res.data.createdAt,
            updatedAt: res.data.updatedAt,
          };

          const updatedImagesList = [...images];
          const imgIndex = updatedImagesList.findIndex(
            (img) => img.key === uniqueKey
          );

          if (imgIndex !== -1) {
            updatedImagesList[imgIndex] = imageWithResponseData;
          } else {
            updatedImagesList.push(imageWithResponseData);
          }

          const updatedFormData = new FormData();
          updatedFormData.append("key", "gallery");
          updatedFormData.append("value_type", "JSON");
          updatedFormData.append("value", JSON.stringify(updatedImagesList));

          await request({
            method: "post",
            url: `projects/${project_id}/data`,
            data: updatedFormData,
            headers: { "Content-Type": "multipart/form-data" },
          });

          toast.success("Image updated.");
          getGallery();
        }
        setGalleryFile(null);
        setImage({});
      } catch (err) {
        console.error("Failed to add/update image:", err);
        toast.error("Failed to add/update image.");
      } finally {
        setIsUpdating(false);
      }
    },
    [image, galleryFile, images, getGallery, project_id, request]
  );

  const addFromRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addFromRef.current && !addFromRef.current.contains(event.target)) {
        if (sidebar) toggleSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebar, toggleSidebar]);

  // Modify handlePaste to store original file
  const handlePaste = useCallback((e) => {
    const items = e.clipboardData?.items;

    if (!items) return;

    for (const item of items) {
      if (item.type.indexOf("image") === 0) {
        const file = item.getAsFile();
        if (file) {
          setOriginalFile(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;

            img.onload = () => {
              const width = img.width;
              const height = img.height;

              setGalleryFile({ file, preview: reader.result });
              setImage((prevImage) => ({
                ...prevImage,
                fileSize: formatFileSize(file.size),
                fileType: file.type,
                dimensions: `${width}x${height}`,
                error: null,
              }));
            };
          };
          reader.readAsDataURL(file);
        }
      }
    }
  }, []);

  // Add effect to handle paste events
  useEffect(() => {
    if (sidebar) {
      document.addEventListener("paste", handlePaste);
      return () => document.removeEventListener("paste", handlePaste);
    }
  }, [sidebar, handlePaste]);

  // Clear original file when closing/resetting
  useEffect(() => {
    if (!sidebar) {
      setOriginalFile(null);
    }
  }, [sidebar]);

  return (
    <div
      ref={addFromRef}
      className={`flex flex-col w-[450px] h-screen overflow-y-scroll z-50 fixed right-0 top-0 bg-white dark:bg-gray-800 ${
        sidebar ? "shadow-l shadow-black/20 dark:shadow-gray-600/40" : ""
      }`}
      style={{ transform: sidebar ? "translateX(0)" : "translateX(100%)" }}
    >
      <div className="sticky top-0 bg-white dark:bg-gray-800 z-20 pt-5 px-5">
        <div className="flex items-center justify-between">
          <h3>Gallery</h3>
          <div className="flex items-center justify-end gap-2">
            <label className="btnWhite cursor-pointer">
              <Camera className="w-4 h-4" />
              Add Image
              <input
                type="file"
                onChange={handleFileChange}
                className="hidden"
              />
            </label>
            <button className="btnPrimary bg-red-500" onClick={toggleSidebar}>
              Close
            </button>
          </div>
        </div>

        <div className="relative flex-1 mb-2 mt-3">
          <input
            type="text"
            className="border py-2 px-3 rounded-md w-full"
            placeholder="Click here to paste image"
            onPaste={(e) => {
              e.preventDefault();
              handlePaste(e);
            }}
          />
          <Copy className="w-4 h-4 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500" />
        </div>

        <p className="text-secondary text-sm mb-5 mt-2 flex items-center gap-1">
          <Info className="w-4 h-4" /> Copy image URL from here and paste in
          image editor.
        </p>
      </div>

      {/* Gallery Display */}
      {galleryFile ? (
        <div className="p-5">
          {/* Image Preview */}
          <div className="relative rounded-lg overflow-hidden mb-4">
            <img
              src={galleryFile.preview}
              alt="Preview"
              className="w-full h-auto"
            />
          </div>

          {/* Image Stats */}
          <div className="grid grid-cols-3 gap-3 mb-4">
            <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-md">
              <p className="text-xs text-gray-500 dark:text-gray-400">
                Current Size
              </p>
              <p className="font-medium">{image.fileSize}</p>
            </div>
            <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-md">
              <p className="text-xs text-gray-500 dark:text-gray-400">Format</p>
              <p className="font-medium">
                {image.fileType?.split("/")[1].toUpperCase()}
              </p>
            </div>
            <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-md">
              <p className="text-xs text-gray-500 dark:text-gray-400">
                Dimensions
              </p>
              <p className="font-medium">{image.dimensions}</p>
            </div>
          </div>

          {/* Compression Slider */}
          <div className="mb-4">
            <label className="text-sm font-medium">
              Compress Image Size: {selectedSize}
            </label>
            <input
              type="range"
              min="5"
              max="100"
              value={selectedSize}
              onChange={handleSizeChange}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 mt-2"
            />
            <div className="flex justify-between text-xs text-gray-500 mt-1">
              <span>5</span>
              <span>100</span>
            </div>
          </div>

          {/* Image Details Form */}
          <div className="space-y-4">
            <InputField
              label="Image Title"
              name="imageTitle"
              placeholder="Enter image title"
              value={image?.imageTitle || ""}
              onChange={handleChange}
            />
            <InputField
              label="Alt Text"
              name="imageAltText"
              placeholder="Enter image alt text"
              value={image?.imageAltText || ""}
              onChange={handleChange}
            />

            <button
              onClick={createUpdate}
              disabled={isUpdating}
              className="w-full bg-green-500 hover:bg-green-600 text-white py-2.5 rounded-md font-medium transition-colors duration-200 flex items-center justify-center gap-2"
            >
              {isUpdating ? (
                <>
                  <LoaderCircle className="w-4 h-4 animate-spin" />
                  Uploading...
                </>
              ) : (
                "Save & Upload"
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-5 px-6 pb-6">
          {Array.isArray(images) && images.length > 0 ? (
            images.map((item, index) => (
              <ImageItem
                key={index}
                item={item}
                isCopied={isCopied}
                project_id={project_id}
                copyToClipboard={copyToClipboard}
                image={`${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${item.image}`}
              />
            ))
          ) : (
            <div className="col-span-2 text-center py-10 text-gray-500">
              No images in gallery yet. Add your first image above.
            </div>
          )}
        </div>
      )}
    </div>
  );
}
