import React, { useEffect, useState, useCallback, useMemo } from "react";
import useApi from "../../../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import { useProjectContext } from "../ProjectContext";
import { InputField, TextArea } from "../../../../components";
import {
  CameraIcon,
  LoaderCircle,
  Save,
  ImageIcon,
  UploadCloud,
  Edit2,
} from "lucide-react";

export default function ProjectBanner() {
  const { request } = useApi();
  const { project_id } = useProjectContext();

  const fontSizeOptions = [
    "12px",
    "14px",
    "16px",
    "18px",
    "24px",
    "32px",
    "48px",
    "64px",
    "72px",
    "80px",
    "96px",
  ];

  const [bannerData, setBannerData] = useState({
    title: "",
    tagline: "",
    imageTitle: "",
    altImage: "",
    opacity: "",
    textColor: "#FFFFF",
    titleFontSize: "32px",
    taglineFontSize: "18px",
  });

  const [fileInfo, setFileInfo] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const getBannerData = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/banner`,
      });

      const bannerValues = res.data?.[0]?.value || {};
      const file = res.data?.[0]?.file_name;

      setBannerData(bannerValues);

      setFileInfo({
        file,
        preview: file
          ? `${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${file}`
          : null,
      });
    } catch (err) {
      console.error(err);
      toast.error("Failed to fetch banner data.");
    }
  }, [project_id, request]);

  useEffect(() => {
    getBannerData();
  }, [project_id]);

  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 100 * 1024) {
        toast.error("Image size must be less than 100KB");
        e.target.value = "";
        return;
      }

      setFileInfo({
        file,
        preview: URL.createObjectURL(file),
      });
    }
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setBannerData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const updateKeyValue = useCallback(
    async (event) => {
      event.preventDefault();
      setIsUpdating(true);

      try {
        const formData = new FormData();
        formData.append("key", "banner");
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(bannerData));

        if (fileInfo?.file) {
          formData.append("file", fileInfo.file);
        }

        await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success("Banner Data updated.");
        getBannerData();
      } catch (err) {
        const errorMsg =
          err.response?.data?.message ||
          "Operation could not be performed, some error occurred.";
        toast.error(errorMsg);
      } finally {
        setIsUpdating(false);
      }
    },
    [bannerData, fileInfo, getBannerData, project_id, request]
  );

  const bannerStyle = useMemo(
    () => ({
      backgroundColor: `rgba(0, 0, 0, ${bannerData.opacity / 100})`,
      color: bannerData.textColor,
    }),
    [bannerData.opacity, bannerData.textColor]
  );

  return (
    <div className="h-[calc(100vh-6rem)] overflow-y-scroll px-8 py-6 flex flex-col items-center">
      <Toaster />
      <div className="max-w-6xl w-full">
        <div className="flex items-center justify-between mb-8">
          <div>
            <div className="flex items-center gap-3 mb-2">
              <h2 className="text-3xl font-bold">Project Banner</h2>
              <div className="h-8 w-[2px] bg-gradient-to-b from-primary/50 to-violet-500/50 rounded-full" />
              <span className="text-sm px-3 py-1 rounded-full bg-primary/10 text-primary border border-primary/20">
                Design Mode
              </span>
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Create an immersive banner experience for your project
            </p>
          </div>
        </div>
        <div className="rounded-2xl p-10 bg-white dark:bg-gray-800 h-fit mt-6">
          {/* Banner Image Input Section - Updated UI */}
          <div className="overflow-hidden relative h-96 transition-all w-full rounded-xl bg-gray-100 dark:bg-gray-900 border-2 border-dashed border-gray-300 dark:border-gray-700 flex items-center justify-center group/banner">
            {fileInfo?.preview ? (
              <img
                src={fileInfo.preview}
                loading="lazy"
                alt={bannerData.altImage || "Project Banner"}
                className="w-full h-full object-cover absolute top-0 transition-transform duration-500 group-hover/banner:scale-105"
              />
            ) : (
              <div className="flex flex-col items-center justify-center text-gray-400">
                <ImageIcon className="w-16 h-16 mb-4" />
                <p className="text-lg font-medium">No banner image uploaded</p>
                <p className="text-sm">Recommended size: 1920 x 1080px</p>
              </div>
            )}

            <div
              className="flex flex-col items-center justify-center z-10 w-full h-full transition-opacity"
              style={bannerStyle}
            >
              <div className="flex items-center flex-col w-full max-w-4xl space-y-4 px-8">
                {/* Title Input */}
                <div className="w-full group relative">
                  <div className="absolute -left-4 top-1/2 -translate-y-1/2 w-1 h-0 group-hover:h-full bg-white/50 transition-all duration-300 rounded-full" />
                  <input
                    type="text"
                    name="title"
                    value={bannerData.title}
                    onChange={handleChange}
                    placeholder="Add a powerful title"
                    className="w-full outline-none text-center bg-gradient-to-r from-white/10 to-transparent backdrop-blur-[2px] rounded-2xl transition-all duration-300
                      focus:from-white/20 focus:backdrop-blur-sm focus:shadow-[0_0_20px_rgba(255,255,255,0.1)]
                      selection:bg-white/30"
                    style={{
                      color: bannerData.textColor,
                      fontSize: bannerData.titleFontSize,
                      lineHeight: "1.2",
                      padding: "1.5rem",
                      border: "none",
                      background: "transparent",
                    }}
                  />
                  <div className="absolute right-4 top-4">
                    <div className="p-2 bg-white/10 hover:bg-white/20 rounded-full backdrop-blur-sm transition-colors">
                      <Edit2 className="w-4 h-4 text-white" />
                    </div>
                  </div>
                </div>

                {/* Tagline Input */}
                <div className="w-full group relative">
                  <div className="absolute -left-4 top-1/2 -translate-y-1/2 w-1 h-0 group-hover:h-full bg-white/30 transition-all duration-300 rounded-full" />
                  <input
                    type="text"
                    name="tagline"
                    value={bannerData.tagline}
                    onChange={handleChange}
                    placeholder="Add a compelling tagline"
                    className="w-full outline-none text-center bg-gradient-to-r from-black/20 to-transparent backdrop-blur-[2px] rounded-2xl transition-all duration-300
                      focus:from-black/30 focus:backdrop-blur-sm focus:shadow-[0_0_20px_rgba(0,0,0,0.2)]
                      selection:bg-white/30"
                    style={{
                      color: bannerData.textColor,
                      fontSize: bannerData.taglineFontSize,
                      lineHeight: "1.5",
                      padding: "1.25rem",
                      border: "none",
                      background: "transparent",
                    }}
                  />
                  <div className="absolute right-4 top-4">
                    <div className="p-2 bg-white/10 hover:bg-white/20 rounded-full backdrop-blur-sm transition-colors">
                      <Edit2 className="w-4 h-4 text-white" />
                    </div>
                  </div>
                </div>

                {/* Quick Style Controls */}
                <div className="absolute bottom-5 right-5 flex items-center gap-3 p-2 bg-black/40 backdrop-blur-md rounded-xl border border-white/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <button
                    className="p-2 hover:bg-white/10 rounded-lg transition-colors"
                    onClick={() =>
                      handleChange({
                        target: { name: "titleFontSize", value: "64px" },
                      })
                    }
                  >
                    <span className="text-white/70 text-lg font-bold">T</span>
                  </button>
                  <div className="w-px h-6 bg-white/20" />
                  <input
                    type="color"
                    name="textColor"
                    value={bannerData.textColor}
                    onChange={handleChange}
                    className="w-8 h-8 rounded-lg cursor-pointer border-2 border-white/20"
                  />
                </div>
              </div>

              <input
                type="file"
                onChange={handleFileChange}
                id="imageUpload"
                className="hidden"
                accept="image/*"
              />
              <label
                htmlFor="imageUpload"
                className="absolute bottom-6 right-6 inline-flex items-center gap-3 px-5 py-3 bg-black/40 hover:bg-black/60 text-white rounded-xl cursor-pointer transition-all duration-300 border border-white/20 hover:border-white/40 backdrop-blur-xl hover:shadow-xl hover:-translate-y-0.5 group/upload"
              >
                <CameraIcon className="w-5 h-5 transition-transform group-hover/upload:scale-110" />
                <span className="font-medium">Change Banner</span>
                <span className="text-xs text-white/70 bg-white/10 px-2 py-0.5 rounded-full">
                  Max: 100KB
                </span>
              </label>
            </div>
          </div>
          <div className="space-y-6 mt-7">
            {/* Main Controls Grid */}
            <div className="grid grid-cols-12 gap-6">
              {/* Left Column - Image Details */}
              <div className="col-span-8 space-y-6">
                {/* Banner Style Selector - if needed */}
                {bannerData?.types && (
                  <div className="bg-white dark:bg-gray-800 rounded-xl p-6 border border-gray-100 dark:border-gray-700 shadow-sm">
                    <h3 className="text-base font-semibold mb-4">
                      Banner Style
                    </h3>
                    <select
                      name="active"
                      value={bannerData.active}
                      onChange={handleChange}
                      className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900 text-sm capitalize focus:ring-2 focus:ring-primary/20 transition-shadow"
                    >
                      {bannerData.types.map((style, index) => (
                        <option
                          className="capitalize"
                          key={index}
                          value={style}
                        >
                          {style?.replaceAll("_", " ")}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div className="bg-white dark:bg-gray-800 rounded-xl p-6 border border-gray-100 dark:border-gray-700 shadow-sm">
                  <h3 className="text-base font-semibold mb-4 flex items-center gap-2">
                    <ImageIcon className="w-4 h-4 text-primary" />
                    Banner Details
                  </h3>
                  <div className="grid grid-cols-2 gap-4">
                    <InputField
                      name="imageTitle"
                      label="Image Title"
                      placeholder="Enter a descriptive title"
                      value={bannerData.imageTitle}
                      onChange={handleChange}
                      className="bg-gray-50 dark:bg-gray-900"
                    />
                    <InputField
                      name="altImage"
                      label="Alt Text"
                      placeholder="Describe image for accessibility"
                      value={bannerData.altImage}
                      onChange={handleChange}
                      className="bg-gray-50 dark:bg-gray-900"
                    />
                  </div>
                </div>

                {/* Opacity Control */}
                <div className="bg-white dark:bg-gray-800 rounded-xl p-6 border border-gray-100 dark:border-gray-700 shadow-sm">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-base font-semibold flex items-center gap-2">
                      <div className="w-4 h-4 rounded bg-primary/20" />
                      Background Overlay
                    </h3>
                    <span className="px-2.5 py-1 rounded-lg bg-primary/10 text-primary text-sm font-medium">
                      {bannerData.opacity}%
                    </span>
                  </div>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    name="opacity"
                    value={bannerData.opacity}
                    onChange={handleChange}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 accent-primary"
                  />
                </div>
              </div>

              {/* Right Column - Typography Controls */}
              <div className="col-span-4 space-y-6">
                {/* Text Color */}
                <div className="bg-white dark:bg-gray-800 rounded-xl p-6 border border-gray-100 dark:border-gray-700 shadow-sm">
                  <h3 className="text-base font-semibold mb-1 flex items-center gap-2">
                    <span className="w-4 h-4 rounded bg-primary" />
                    Text Color
                  </h3>
                  <input
                    type="color"
                    name="textColor"
                    value={bannerData.textColor}
                    onChange={handleChange}
                    className="w-full h-12 rounded-lg cursor-pointer border-0"
                  />

                  <h3 className="text-base font-semibold mt-5 mb-4 border-b flex items-center gap-2">
                    <span className="text-lg font-bold text-primary">T</span>
                    Typography
                  </h3>
                  <div className="space-y-4">
                    <div>
                      <label className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-1.5 block">
                        Title Size
                      </label>
                      <select
                        name="titleFontSize"
                        value={bannerData.titleFontSize}
                        onChange={handleChange}
                        className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900 text-sm focus:ring-2 focus:ring-primary/20 transition-shadow"
                      >
                        {fontSizeOptions.map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-1.5 block">
                        Tagline Size
                      </label>
                      <select
                        name="taglineFontSize"
                        value={bannerData.taglineFontSize}
                        onChange={handleChange}
                        className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900 text-sm focus:ring-2 focus:ring-primary/20 transition-shadow"
                      >
                        {fontSizeOptions.map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Save Button */}
            <div className="flex items-center justify-end pt-4">
              <button
                onClick={updateKeyValue}
                disabled={isUpdating}
                className="inline-flex items-center gap-2 px-6 py-2.5 bg-primary hover:bg-primary/90 text-white rounded-xl transition-all disabled:opacity-70 disabled:cursor-not-allowed shadow-lg shadow-primary/25 hover:shadow-xl hover:shadow-primary/20"
              >
                {isUpdating ? (
                  <LoaderCircle className="w-5 h-5 animate-spin" />
                ) : (
                  <Save className="w-5 h-5" />
                )}
                <span className="font-medium">
                  {isUpdating ? "Saving Changes..." : "Save Changes"}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
