import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useProjectContext } from "../ProjectContext";
import usePermissions from "../../../../utils/userPermission";

import {
  Box,
  ChevronDown,
  CloudCog,
  Contact,
  Dock,
  GlobeLock,
  Image,
  Images,
  LayoutList,
  LayoutPanelTop,
  LineChart,
  ListTree,
  Newspaper,
  NewspaperIcon,
  Podcast,
  Stars,
  Tag,
} from "lucide-react";

const getIconClassName = (currentPath, pathEnd) =>
  `w-4 text-gray-500 ${currentPath.endsWith(pathEnd) ? "text-white" : ""}`;

const ProjectMenu = ({ setIsCustomizing, isCustomizing, open }) => {
  const { project } = useParams();
  const navigate = useNavigate();
  const { projectToManage, setIframeURL } = useProjectContext();
  const location = useLocation();
  const currentPath = location.pathname;
  const { hasPermission } = usePermissions();
  const [customize, setCustomize] = useState(false);

  const renderButton = (label, onClick, permission, IconComponent, pathEnd) =>
    hasPermission(permission) && (
      <div className="relative group">
        <button
          key={label}
          onClick={() => {
            setIsCustomizing("");
            onClick();
          }}
          className={`btnDropdown hover:rounded-md px-3 ${
            currentPath.endsWith(pathEnd)
              ? "bg-primary hover:bg-primary rounded-lg text-white"
              : "rounded-none"
          } ${!open && "justify-center"}`}
        >
          <IconComponent className={getIconClassName(currentPath, pathEnd)} />
          {open && label}
        </button>
        {!open && (
          <div
            className="fixed hidden group-hover:block z-50"
            style={{
              left: "calc(var(--sidebar-width, 64px) + 0.5rem)",
              top: "var(--button-top)",
              transform: "translateY(-50%)",
            }}
          >
            <div className="relative bg-gray-800 text-white text-sm py-1 px-2 rounded whitespace-nowrap">
              {label}
              <div className="absolute w-2 h-2 bg-gray-800 transform rotate-45 left-[-4px] top-1/2 -translate-y-1/2" />
            </div>
          </div>
        )}
      </div>
    );

  useEffect(() => {
    if (!open) {
      const updateTooltipPositions = () => {
        requestAnimationFrame(() => {
          const buttons = document.querySelectorAll(".btnDropdown");
          buttons.forEach((button) => {
            const rect = button.getBoundingClientRect();
            const tooltip = button.parentElement.querySelector(".fixed");
            if (tooltip) {
              tooltip.style.setProperty(
                "--button-top",
                `${rect.top + rect.height / 2}px`
              );
            }
          });
        });
      };

      // Initial update
      updateTooltipPositions();

      // Update on scroll
      window.addEventListener("scroll", updateTooltipPositions);

      // Update on resize
      window.addEventListener("resize", updateTooltipPositions);

      return () => {
        window.removeEventListener("scroll", updateTooltipPositions);
        window.removeEventListener("resize", updateTooltipPositions);
      };
    }
  }, [open]);

  return (
    <div className="px-1">
      {open && (
        <div className="grid grid-cols-3 border-b py-1">
          <button
            className={`text-center pb-2 py-1 hover:text-primary hover:underline transition-all border-r ${
              currentPath.endsWith(project) && "text-primary underline rounded"
            }`}
            onClick={() => navigate(`/projects/${project}`)}
          >
            Preview
          </button>
          <a
            href={`https://${projectToManage?.project_name?.replaceAll(
              " ",
              "-"
            )}.sitebuilderz.com`}
            target="_blank"
            rel="noreferrer"
            className="text-center pb-2 py-1 hover:text-primary hover:underline transition-all border-r"
          >
            Staging
          </a>
          {projectToManage?.domain_id?.domain && (
            <a
              href={`https://${projectToManage?.domain_id?.domain}`}
              target="_blank"
              rel="noreferrer"
              className="text-center pb-2 py-1 hover:text-primary hover:underline transition-all"
            >
              On Web
            </a>
          )}
        </div>
      )}

      {renderButton(
        "Manage Domain",
        () => navigate(`/projects/${project}/manage-domain`),
        "Manage Domain",
        Box,
        "manage-domain"
      )}
      {renderButton(
        "AI Manager",
        () => navigate(`/projects/${project}/ai-manager`),
        "project seo",
        Stars,
        "ai-manager"
      )}
      {renderButton(
        "Tag Manager",
        () => navigate(`/projects/${project}/data`),
        "project tag manager",
        Tag,
        "data"
      )}
      {renderButton(
        "Project Articles",
        () => navigate(`/projects/${project}/articles`),
        "project articles",
        Newspaper,
        "articles"
      )}
      {renderButton(
        "Project Categories",
        () => navigate(`/projects/${project}/project-categories`),
        "View Project Categories",
        ListTree,
        "project-categories"
      )}
      {renderButton(
        "SEO",
        () => navigate(`/projects/${project}/seo`),
        "project seo",
        LineChart,
        "seo"
      )}

      {renderButton(
        "Gallery",
        () => navigate(`/projects/${project}/gallery`),
        "view images",
        Images,
        "gallery"
      )}

      {hasPermission("customize project") && (
        <div className="relative group">
          <div
            onClick={() => setCustomize(!customize)}
            className={`btnDropdown rounded-none px-3 cursor-pointer ${
              !open ? "justify-center" : "justify-between"
            }`}
          >
            <div className="flex items-center gap-3">
              <LayoutPanelTop className="w-4 text-gray-500" />
              {open && "Customize Project"}
            </div>
            {open && (
              <ChevronDown
                className={`w-4 transition-all ${
                  customize ? "rotate-0" : "rotate-180"
                }`}
              />
            )}
          </div>
          {!open && (
            <div
              className="fixed hidden group-hover:block z-50"
              style={{
                left: "calc(var(--sidebar-width, 64px) + 0.5rem)",
                top: "var(--button-top)",
                transform: "translateY(-50%)",
              }}
            >
              <div className="relative bg-gray-800 text-white text-sm py-1 px-2 rounded whitespace-nowrap">
                Customize Project
                <div className="absolute w-2 h-2 bg-gray-800 transform rotate-45 left-[-4px] top-1/2 -translate-y-1/2" />
              </div>
            </div>
          )}
        </div>
      )}

      {customize && (
        <div
          className={`${
            open
              ? "pl-4 mt-1 border-l-2 border-gray-200 dark:border-gray-700 ml-4"
              : ""
          }`}
        >
          <div className="relative group">
            <button
              onClick={() => {
                setIsCustomizing("project identity");
                navigate(`/projects/${project}`);
              }}
              className={`btnDropdown hover:rounded-md px-3 ${
                isCustomizing === "project identity"
                  ? "bg-primary hover:bg-primary rounded-lg text-white"
                  : "rounded-none"
              } ${!open && "justify-center"}`}
            >
              <Dock
                className={`w-4 ${
                  isCustomizing === "project identity"
                    ? "text-white"
                    : "text-gray-500"
                }`}
              />
              {open && "Project Identity"}
            </button>
            {!open && (
              <div
                className="fixed mt-1 hidden group-hover:block z-50"
                style={{
                  left: "calc(var(--sidebar-width, 64px) + 0.5rem)",
                  top: "var(--button-top)",
                  transform: "translateY(-50%)",
                }}
              >
                <div className="relative bg-gray-800 text-white text-sm py-1 px-2 rounded whitespace-nowrap">
                  Project Identity
                  <div className="absolute w-2 h-2 bg-gray-800 transform rotate-45 left-[-4px] top-1/2 -translate-y-1/2" />
                </div>
              </div>
            )}
          </div>

          {hasPermission("View layout") && (
            <div className="relative group">
              <button
                onClick={() => {
                  setIsCustomizing("layout");
                  navigate(`/projects/${project}`);
                }}
                className={`btnDropdown hover:rounded-md px-3 ${
                  isCustomizing === "layout"
                    ? "bg-primary hover:bg-primary rounded-lg text-white"
                    : "rounded-none"
                } ${!open && "justify-center"}`}
              >
                <LayoutList
                  className={`w-4 ${
                    isCustomizing === "layout" ? "text-white" : "text-gray-500"
                  }`}
                />
                {open && "Project Layout"}
              </button>
              {!open && (
                <div
                  className="fixed hidden group-hover:block z-50"
                  style={{
                    left: "calc(var(--sidebar-width, 64px) + 0.5rem)",
                    top: "var(--button-top)",
                    transform: "translateY(-50%)",
                  }}
                >
                  <div className="relative bg-gray-800 text-white text-sm py-1 px-2 rounded whitespace-nowrap">
                    Project Layout
                    <div className="absolute w-2 h-2 bg-gray-800 transform rotate-45 left-[-4px] top-1/2 -translate-y-1/2" />
                  </div>
                </div>
              )}
            </div>
          )}

          {hasPermission("View layout") && (
            <button
              onClick={() => {
                setIsCustomizing("layout_styles");
                navigate(`/projects/${project}`);
              }}
              className={`btnDropdown hover:rounded-md px-3 ${
                isCustomizing === "layout_styles"
                  ? "bg-primary hover:bg-primary rounded-lg text-white"
                  : "rounded-none"
              } ${!open && "justify-center"}`}
            >
              <Dock
                className={`w-4 ${
                  isCustomizing === "layout_styles"
                    ? "text-white"
                    : "text-gray-500"
                }`}
              />
              {open && "Layout Styles"}
            </button>
          )}

          {projectToManage?.industry_id?.industry_name === "business" && (
            <button
              onClick={() => {
                setIsCustomizing("services_listing");
                navigate(`/projects/${project}`);
              }}
              className={`btnDropdown hover:rounded-md px-3 ${
                isCustomizing === "services_listing"
                  ? "bg-primary hover:bg-primary rounded-lg text-white"
                  : "rounded-none"
              } ${!open && "justify-center"}`}
            >
              <CloudCog className="w-4" />
              {open && "Services Listing"}
            </button>
          )}

          {hasPermission("customize project") && (
            <button
              onClick={() => {
                setIsCustomizing("contact_details");
                setIframeURL(
                  `https://${projectToManage?.slug}.sitebuilderz.com/contact`
                );
                navigate(`/projects/${project}`);
              }}
              className={`btnDropdown hover:rounded-md px-3 ${
                isCustomizing === "contact_details"
                  ? "bg-primary hover:bg-primary rounded-lg text-white"
                  : "rounded-none"
              } ${!open && "justify-center"}`}
            >
              <Contact className="w-4" />
              {open && "Contact Details"}
            </button>
          )}

          {renderButton(
            "Project Banner",
            () => navigate(`/projects/${project}/banner`),
            "customize project",
            Image,
            "banner"
          )}
          {renderButton(
            "About Us",
            () => navigate(`/projects/${project}/about-us`),
            "customize project",
            Podcast,
            "about-us"
          )}
          {renderButton(
            "Terms & Conditions",
            () => navigate(`/projects/${project}/terms`),
            "customize project",
            NewspaperIcon,
            "terms"
          )}
          {renderButton(
            "Privacy Policy",
            () => navigate(`/projects/${project}/policy`),
            "customize project",
            GlobeLock,
            "policy"
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectMenu;
