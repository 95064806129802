import React from "react";
import { CheckCircle, ExternalLink } from "lucide-react";

export default function SelectTemplate({
  image,
  title,
  selectedValue,
  setSelectedValue,
  themeId,
  demoURL,
}) {
  const handleChange = () => setSelectedValue(themeId);
  const isSelected = selectedValue === themeId;

  return (
    <div
      className={`relative rounded-xl overflow-hidden cursor-pointer transition-all duration-300
        ${
          isSelected
            ? "ring-2 ring-primary ring-offset-2 dark:ring-offset-gray-900"
            : "hover:ring-2 hover:ring-gray-200 dark:hover:ring-gray-700"
        }`}
      onClick={handleChange}
    >
      {/* Image */}
      <div className="relative aspect-[4/3] overflow-hidden bg-gray-100 dark:bg-gray-800">
        <img src={image} alt={title} className="w-full h-full object-cover" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/0 to-black/0" />

        {/* Selected Badge */}
        {isSelected && (
          <div className="absolute top-3 right-3">
            <div className="bg-primary text-white px-3 py-1 rounded-full text-sm font-medium flex items-center gap-1.5">
              <CheckCircle className="w-4 h-4" />
              Selected
            </div>
          </div>
        )}
      </div>

      {/* Content */}
      <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
        <h3 className="font-medium mb-3">{title}</h3>

        {/* Actions */}
        <div className="flex items-center gap-2 text-sm">
          <a
            href={`https://${demoURL}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
            className="flex items-center gap-1.5 px-3 py-1.5 bg-white/20 hover:bg-white/30 
                     rounded-lg backdrop-blur-sm transition-colors"
          >
            <ExternalLink className="w-4 h-4" />
            Preview
          </a>
        </div>
      </div>
    </div>
  );
}
