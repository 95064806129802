import React, { useState, useEffect, useRef, useCallback } from "react";
import { useProjectContext } from "../ProjectContext";
import { Breadcrumbs } from "../../../../components";
import { Search, Stars, X } from "lucide-react";
import ProjectSidebar from "../ProjectSidebar";
import { useLocation } from "react-router-dom";
import AIChat from "./AIChat";

export default function Project({ children }) {
  const [open, setOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const { projects, ViewSwitch, project_id } = useProjectContext();
  const [showAIDropdown, setShowAIDropdown] = useState(false);
  const location = useLocation();

  const filteredProjects = projects.filter((project) =>
    project.project_name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleBlur = () => {
    if (!searchTerm) {
      setSearchTerm("");
    }
  };

  const inputRef = useRef(null);

  const handleClickOutside = useCallback((e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setSearchTerm("");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <div className="border-b sticky top-12 px-2 z-10 h-10 dark:border-white/20 flex items-center justify-between flex-wrap w-full text-sm backdrop-blur-md bg-warmGray dark:bg-gray-950/30">
        <div className="flex items-center gap-5 flex-1 text-white">
          <div
            ref={inputRef}
            className="flex items-center relative gap-2 py-[3px] bg-gray-700 font-normal w-[239px] px-2 rounded"
          >
            <Search className="w-4" />
            <input
              className="bg-transparent flex-1 outline-none placeholder:text-gray-200"
              placeholder="Search projects"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onBlur={handleBlur}
            />
            {searchTerm && (
              <div className="absolute top-0 left-0 py-1 w-full rounded-md flex flex-col bg-white dark:bg-gray-900 dark:text-white/90 shadow-lg mt-8 text-black">
                {filteredProjects.map((project) => (
                  <div
                    key={project._id}
                    className="px-4 py-2 border-b last:border-none dark:border-white/20"
                  >
                    <p>{project.project_name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <ViewSwitch />
          <Breadcrumbs className="text-white/80 !w-fit" />
          <div className="relative ml-auto mr-6">
            <button
              onClick={() => setShowAIDropdown(!showAIDropdown)}
              className="flex items-center gap-2 px-2 pr-3 py-1 bg-gradient-to-r from-orange-500 to-primary text-white rounded-md shadow-lg hover:shadow-xl transition-all duration-300 relative overflow-hidden group"
            >
              <Stars className="h-4 w-4 group-hover:rotate-180 transition-transform duration-500" />
              <span className="relative z-10">Create With AI</span>
              <div className="absolute inset-0 bg-white/10 group-hover:bg-white/20 transition-colors duration-300" />
            </button>
          </div>
        </div>
      </div>

      <div
        className={`grid w-full ${
          open ? "grid-cols-manageTemplate" : "grid-cols-[4rem_1fr]"
        }`}
      >
        <ProjectSidebar open={open} setOpen={setOpen} />
        <div className="grid">
          <div className="overflow-hidden">{children}</div>
        </div>
      </div>

      {showAIDropdown && (
        <AIChat
          showAIDropdown={showAIDropdown}
          setShowAIDropdown={setShowAIDropdown}
          currentProject={projects.find((p) => p._id === project_id)}
          currentPath={location.pathname}
        />
      )}
    </>
  );
}
