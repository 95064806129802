import {
  Home,
  Templates,
  Industries,
  Users,
  Merchants,
  Roles,
  TemplateData,
  TemplatePreview,
  DomainsManager,
  HostingProvider,
  Registrar,
  BuyType,
  DomainType,
  Niche,
  Associations,
  DomainStatus,
  AccountSettings,
  TemplateArticles,
  WriteArticle,
  ProjectPreview,
  ProjectData,
  ProjectArticles,
  ProjectBanner,
  CDN,
  WritePjArticle,
  ProjectSEO,
  TemplateSEO,
  Permissions,
  ArticleCategories,
  ProjArticleCategories,
  Reports,
  BacklinkReport,
  ProjectManager,
  NewProject,
  ManageProjectDomain,
  ProjectTerms,
  ProjectPolicy,
  AboutOwner,
  ContactDetails,
  ProjectGallery,
  TemplateGallery,
  TempIndustries,
  AIContentManager,
} from "../pages";

export const routes = [
  {
    path: "/",
    element: Home,
    permission: "",
    pageTitle: "Home",
  },
  {
    path: "/home",
    element: Home,
    permission: "",
    pageTitle: "Home",
  },
  {
    path: "/projects",
    element: ProjectManager,
    permission: "View Projects",
    pageTitle: "Projects",
  },
  {
    path: "/new-project",
    element: NewProject,
    permission: "add project",
    pageTitle: "New Project",
  },
  {
    path: "/domains",
    element: DomainsManager,
    permission: "View Domains",
    pageTitle: "My Domains",
  },
  {
    path: "/registrar",
    element: Registrar,
    permission: "view registrars",
    pageTitle: "Registrars",
  },
  {
    path: "/hosting-provider",
    element: HostingProvider,
    permission: "View hosting providers",
    pageTitle: "Hosting Providers",
  },
  {
    path: "/buy-type",
    element: BuyType,
    permission: "view buy types",
    pageTitle: "Buy Types",
  },
  {
    path: "/associations",
    element: Associations,
    permission: "View Associations",
    pageTitle: "Associations",
  },
  {
    path: "/niche",
    element: Niche,
    permission: "view niches",
    pageTitle: "Niches",
  },
  {
    path: "/domain-status",
    element: DomainStatus,
    permission: "View Domain Status",
    pageTitle: "Domain Status",
  },
  {
    path: "/merchants",
    element: Merchants,
    permission: "View Merchants",
    pageTitle: "Merchants",
  },
  {
    path: "/users",
    element: Users,
    permission: "View Users",
    pageTitle: "Users",
  },
  {
    path: "/permissions",
    element: Permissions,
    permission: "View Permissions",
    pageTitle: "Permissions",
  },
  {
    path: "/roles",
    element: Roles,
    permission: "View Roles",
    pageTitle: "Roles",
  },
  {
    path: "/categories",
    element: Industries,
    permission: "View Template Categories",
    pageTitle: "Categories",
  },
  {
    path: "/cdn",
    element: CDN,
    permission: "View CDN",
    pageTitle: "CDN",
  },
  {
    path: "/account-settings",
    element: AccountSettings,
    permission: "",
    pageTitle: "Account Settings",
  },
  {
    path: "/templates",
    element: Templates,
    permission: "View Templates",
    pageTitle: "Templates",
  },

  {
    path: "/:reports/:domain_id",
    element: BacklinkReport,
    permission: "View Backlink Report",
    pageTitle: "Backlink Report",
  },

  {
    path: "/reports",
    element: Reports,
    permission: "reports",
    pageTitle: "Reports",
  },
  {
    path: "/domain-type",
    element: DomainType,
    permission: "view domain type",
    pageTitle: "Domain Type",
  },
];

export const projectManagerRoutes = [
  {
    path: "/projects/:project",
    element: ProjectPreview,
    permission: "",
    pageTitle: "Project",
  },
  {
    path: "/projects/:project/data",
    element: ProjectData,
    permission: "project tag manager",
    pageTitle: "Project Tags",
  },
  {
    path: "/projects/:project/articles",
    element: ProjectArticles,
    permission: "Project Articles",
    pageTitle: "Project Articles",
  },
  {
    path: "/projects/:project/project-categories",
    element: ProjArticleCategories,
    permission: "Project Categories",
    pageTitle: "Project Categories",
  },
  {
    path: "/projects/:project/seo",
    element: ProjectSEO,
    permission: "Project Seo",
    pageTitle: "Project SEO",
  },
  {
    path: "/projects/:project/gallery",
    element: ProjectGallery,
    permission: "view images",
    pageTitle: "Gallery",
  },
  {
    path: "/projects/:project/articles/write",
    element: WritePjArticle,
    permission: "add project article",
    pageTitle: "Write Article",
  },
  {
    path: "/projects/:project/articles/:articleKey",
    element: WritePjArticle,
    permission: "edit project article",
    pageTitle: "Edit Article",
  },
  {
    path: "/projects/:project/manage-domain",
    element: ManageProjectDomain,
    permission: "Manage Domain",
    pageTitle: "Domain",
  },
  {
    path: "/projects/:project/banner",
    element: ProjectBanner,
    permission: "Customize Project",
    pageTitle: "Project Banner",
  },
  {
    path: "/projects/:project/terms",
    element: ProjectTerms,
    permission: "Customize Project",
    pageTitle: "Project Terms",
  },
  {
    path: "/projects/:project/policy",
    element: ProjectPolicy,
    permission: "Customize Project",
    pageTitle: "Privacy Policy",
  },
  {
    path: "/projects/:project/about-us",
    element: AboutOwner,
    permission: "Customize Project",
    pageTitle: "About Us",
  },
  {
    path: "/projects/:project/contact-details",
    element: ContactDetails,
    permission: "Customize Project",
    pageTitle: "Contact Details",
  },
  {
    path: "/projects/:project/ai-manager",
    element: AIContentManager,
    permission: "Customize Project",
    pageTitle: "AI Content Manager",
  },
];

export const templateManagerRoutes = [
  {
    path: "/templates/:template",
    element: TemplatePreview,
    permission: "",
    pageTitle: "Template",
  },
  {
    path: "/templates/:template/data",
    element: TemplateData,
    permission: "Template Tag Manager",
    pageTitle: "Template Tags",
  },
  {
    path: "/templates/:template/seo",
    element: TemplateSEO,
    permission: "Template Seo",
    pageTitle: "Template SEO",
  },
  {
    path: "/templates/:template/gallery",
    element: TemplateGallery,
    permission: "Template Tag Manager",
    pageTitle: "Template Gallery",
  },
  {
    path: "/templates/:template/industries",
    element: TempIndustries,
    permission: "Template Tag Manager",
    pageTitle: "Template Industries",
  },
  {
    path: "/templates/:template/articles",
    element: TemplateArticles,
    permission: "View Template Articles",
    pageTitle: "Template Articles",
  },
  {
    path: "/templates/:template/article-categories",
    element: ArticleCategories,
    permission: "view article categories",
    pageTitle: "Article Categories",
  },
  {
    path: "/templates/:template/articles/write",
    element: WriteArticle,
    permission: "Add Template Article",
    pageTitle: "Add Article",
  },
  {
    path: "/templates/:template/articles/:articleKey",
    element: WriteArticle,
    permission: "Edit Template Article",
    pageTitle: "Edit Article",
  },
];
