import React, { useMemo, useState, useCallback } from "react";
import Header from "./Header";
import ArticleCard from "./ArticleCard";
import { LoaderCircle } from "lucide-react";
import { useArticleContext } from "./ArticleContext";

export default function ArticlesList() {
  const {
    grid,
    blogsList,
    loadingArticles,
    deleteConfirmation,
    setDeleteConfirmation,
  } = useArticleContext();

  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [select, setSelect] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const filteredArticles = useMemo(() => {
    // Ensure blogsList is an array
    const articles = Array.isArray(blogsList) ? blogsList : [];
    
    setCurrentPage(1);
    return articles
      .filter((blog) => {
        const matchesSearch =
          searchTerm === "" ||
          blog?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          blog?.author?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          blog?.article_category
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase());

        const matchesCategory =
          filterCategory === "" ||
          blog?.article_category === filterCategory ||
          (filterCategory === "Must Read" && blog?.isMustRead) ||
          (filterCategory === "Featured" && blog?.isFeatured) ||
          (filterCategory === "Popular" && blog?.isPopular);

        return matchesSearch && matchesCategory;
      })
      .reverse();
  }, [blogsList, searchTerm, filterCategory]);

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );
  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);

  const paginate = useCallback((pageNumber) => setCurrentPage(pageNumber), []);

  const isSelected = useCallback(
    (project_id) => {
      return selectedData.some((item) => item._id === project_id);
    },
    [selectedData]
  );

  const onSelectAll = useCallback(
    (checked) => {
      if (checked) {
        setSelectedData(filteredArticles);
      } else {
        setSelectedData([]);
      }
    },
    [filteredArticles]
  );

  const onSingleSelect = useCallback(({ checked, data }) => {
    if (checked) {
      setSelectedData((prev) => [...prev, data]);
    } else {
      setSelectedData((prev) => prev.filter((item) => item._id !== data._id));
    }
  }, []);

  return (
    <div className="flex flex-col">
      <Header
        paginate={paginate}
        currentPage={currentPage}
        totalPages={totalPages}
        searchTerm={searchTerm}
        setSearchTerm={(e) => setSearchTerm(e.target.value)}
        filterCategory={filterCategory}
        setFilterCategory={setFilterCategory}
        select={select}
        setSelect={setSelect}
        onSelectAll={onSelectAll}
        filteredArticles={filteredArticles}
        selectedData={selectedData}
      />

      {loadingArticles ? (
        <div className="flex flex-col items-center w-full p-24 gap-5">
          <LoaderCircle className="h-12 w-12 animate-spin text-primary" />
          <p>Loading Articles...</p>
        </div>
      ) : (
        <div className="flex flex-col items-center w-full">
          {filteredArticles.length > 0 ? (
            <div
              className={`grid grid-cols-1 ${
                grid
                  ? "md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6"
                  : "gap-4"
              } w-full mt-6`}
            >
              {currentArticles.map((blog, index) => (
                <ArticleCard
                  key={blog.key}
                  blog={blog}
                  deleteConfirmation={deleteConfirmation}
                  setDeleteConfirmation={setDeleteConfirmation}
                  index={index}
                  isSelected={isSelected}
                  onSingleSelect={onSingleSelect}
                  select={select}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center w-full p-40 text-white">
              No Articles
            </div>
          )}
        </div>
      )}
    </div>
  );
}
