import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { InputField } from "../../../../components";
import { Loader, Search, AlertCircle } from "lucide-react";

const MapGenerator = ({ onUpdateMapDetails, mapDetails }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });

  const handleInputChange = (e) => {
    onUpdateMapDetails({ location: e.target.value });
  };

  const handleSearch = async () => {
    if (!process.env.REACT_APP_MAP_API_KEY) {
      toast.error("Google Maps API key is not configured");
      return;
    }

    onUpdateMapDetails({ loading: true, error: "" });

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          mapDetails.location
        )}&key=${process.env.REACT_APP_MAP_API_KEY}`
      );

      const { data } = response;

      if (data.status === "OK" && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        const mapUrl = `https://www.google.com/maps?q=${lat},${lng}`;

        onUpdateMapDetails({
          center: { lat, lng },
          mapUrl,
          loading: false,
        });

        toast.success("Location found successfully!");
      } else {
        throw new Error(
          data.status === "ZERO_RESULTS"
            ? "Location not found"
            : "Failed to get location"
        );
      }
    } catch (error) {
      onUpdateMapDetails({
        loading: false,
        error: error.message || "Failed to fetch location",
      });
      toast.error(error.message || "Failed to fetch location");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  if (loadError) {
    return (
      <div className="text-red-500 flex items-center">
        <AlertCircle className="mr-2 h-5 w-5" />
        Error loading Google Maps
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <Toaster />
      <div className="flex items-end w-full gap-2">
        <InputField
          label="Your Business Location"
          value={mapDetails?.location}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Enter location"
        />
        <button
          onClick={handleSearch}
          className="btnPrimary text-base"
          disabled={mapDetails?.loading}
        >
          {mapDetails?.loading ? (
            <Loader className="animate-spin mr-2 h-5 w-5 text-white" />
          ) : (
            <>
              <Search className="mr-2 h-5 w-5" />
              Search
            </>
          )}
        </button>
      </div>

      {mapDetails?.error && (
        <p className="text-red-500 mb-4 flex items-center">
          <AlertCircle className="mr-2 h-5 w-5" /> {mapDetails?.error}
        </p>
      )}

      {mapDetails?.mapUrl && (
        <div>
          <h4 className="mb-2 mt-5">Your Location</h4>
          {!isLoaded ? (
            <div className="flex items-center justify-center h-[500px] w-full max-w-4xl rounded-md bg-gray-100">
              <Loader className="animate-spin h-8 w-8 text-gray-500" />
            </div>
          ) : (
            <GoogleMap
              mapContainerClassName="h-[200px] w-full max-w-4xl rounded-md"
              center={mapDetails?.center}
              zoom={12}
              options={{
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: true,
                zoomControl: true,
              }}
            >
              {mapDetails?.center && <Marker position={mapDetails.center} />}
            </GoogleMap>
          )}
        </div>
      )}
    </div>
  );
};

export default MapGenerator;
