import React, { useEffect, useRef, useState } from "react";
import { useProjectContext } from "../ProjectContext";
import { ComboBox, InputField, Modal } from "../../../../components";
import {
  CheckCheck,
  CloudOff,
  Copy,
  LoaderCircle,
  ShieldAlertIcon,
} from "lucide-react";
import useApi from "../../../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import usePermissions from "../../../../utils/userPermission";

export default function ManageProjectDomain() {
  const { request } = useApi();
  const { projectToManage, projectLoading } = useProjectContext();
  const [domain, setDomain] = useState(null);
  const [domains, setDomains] = useState([]);

  const getDomains = async () => {
    if (projectToManage?.domain_id) return;
    try {
      const res = await request({
        method: "post",
        url: "domains/report",
        data: {
          page: "",
          size: "",
          filters: "",
          sort_field: "",
          sort_by: "",
        },
      });
      setDomains(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getDomains();
  }, []);

  const connectDomain = async (e) => {
    e.preventDefault();
    try {
      await request({
        method: "post",
        url: `projects/${projectToManage?._id}/connect_domain`,
        data: { domain_id: domain._id },
      });
      toast.success("Your Domain Is Connected");
      window.location.reload();
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }
  };

  const [confirmDisconnect, setConfirmDisconnect] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const confirmDisconnectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        confirmDisconnectRef.current &&
        !confirmDisconnectRef.current.contains(event.target)
      ) {
        setConfirmDisconnect(false);
      }
    };

    if (confirmDisconnect) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [confirmDisconnect]);

  const disConnectDomain = async (e) => {
    e.preventDefault();
    setDisconnecting(true);
    try {
      await request({
        method: "post",
        url: `projects/${projectToManage?._id}/disconnect_domain`,
      });
      toast.success("Domain Disconnected");
      window.location.reload();
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }
    setDisconnecting(false);
  };

  const [isCopied, setIsCopied] = useState(null);
  const copyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(index);
        setTimeout(() => {
          setIsCopied(null);
        }, 2000);
        toast.success("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy to clipboard");
      });
  };

  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const { hasPermission } = usePermissions();

  return (
    hasPermission("Manage Domain") && (
      <div className="h-[calc(100vh-6rem)] overflow-y-scroll px-4 sm:px-8 py-6">
        <Toaster />

        <div className="max-w-4xl mx-auto">
          <div className="mb-8">
            <h3 className="text-3xl font-bold">Domain Management</h3>
            <p className="text-gray-600 dark:text-gray-300 text-lg mt-2">
              Configure and manage domains for your Production Deployments.
            </p>
          </div>

          {projectLoading ? (
            <div className="flex flex-col items-center justify-center min-h-[400px] bg-white dark:bg-gray-800 rounded-xl shadow-lg backdrop-blur-sm bg-opacity-50">
              <LoaderCircle className="h-12 w-12 animate-spin text-primary" />
              <p className="mt-4 text-gray-600 dark:text-gray-300 animate-pulse">
                Loading domain information...
              </p>
            </div>
          ) : (
            <div className="space-y-6">
              {/* Connected Domain Card */}
              {projectToManage?.domain_id ? (
                <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-100 dark:border-gray-700 transition-all hover:shadow-xl">
                  <div className="p-6">
                    <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
                      <div>
                        <div className="flex items-center gap-2 mb-2">
                          <div className="w-2 h-2 rounded-full bg-primary animate-pulse"></div>
                          <h4 className="text-xl font-semibold">
                            Connected Domain
                          </h4>
                        </div>
                        <div className="flex flex-wrap items-center gap-3">
                          <p className="text-lg font-medium text-primary">
                            {projectToManage?.domain_id?.domain}
                          </p>
                          <span
                            className={`px-4 py-1.5 text-sm rounded-full font-medium transition-colors ${
                              projectToManage?.domain_id?.cloudflair_details
                                ?.status === "pending"
                                ? "bg-yellow-100 text-yellow-700 dark:bg-yellow-900 dark:text-yellow-300"
                                : "bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-300"
                            }`}
                          >
                            {projectToManage?.domain_id?.cloudflair_details
                              ?.status === "pending"
                              ? "Pending DNS"
                              : "Active"}
                          </span>
                        </div>
                      </div>
                      {hasPermission("disconnect domain") && (
                        <button
                          onClick={() => setConfirmDisconnect(true)}
                          className="px-4 py-2.5 bg-red-50 text-red-600 hover:bg-red-100 rounded-lg transition-all duration-200 flex items-center gap-2 hover:scale-105 active:scale-95"
                        >
                          <CloudOff className="w-4 h-4" />
                          Disconnect
                        </button>
                      )}
                    </div>

                    {/* Nameservers Section */}
                    {projectToManage?.domain_id?.cloudflair_details?.status ===
                      "pending" && (
                      <div className="mt-6 bg-gray-50 dark:bg-gray-900 rounded-xl p-6">
                        <div className="flex items-center gap-3 mb-4">
                          <h5 className="text-lg font-semibold">Nameservers</h5>
                          <div className="px-3 py-1 bg-primary/10 text-primary text-sm rounded-full">
                            Required Setup
                          </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                          {projectToManage?.domain_id?.cloudflair_details?.name_servers.map(
                            (item, index) => (
                              <div
                                key={index}
                                className="bg-white dark:bg-gray-800 rounded-lg p-4 flex items-center justify-between border-2 border-gray-100 dark:border-gray-700 hover:border-primary transition-all duration-200 group"
                              >
                                <code className="text-sm font-medium">
                                  {item}
                                </code>
                                <button
                                  onClick={() => copyToClipboard(item, index)}
                                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-all duration-200 group-hover:scale-110"
                                >
                                  {isCopied === index ? (
                                    <CheckCheck className="w-5 h-5 text-green-500" />
                                  ) : (
                                    <Copy className="w-5 h-5 text-gray-400 group-hover:text-primary" />
                                  )}
                                </button>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg border border-gray-100 dark:border-gray-700">
                  <div className="flex items-center gap-3 mb-4">
                    <div className="w-2 h-2 rounded-full bg-primary"></div>
                    <p className="text-xl font-bold">
                      Assign Domain To Project
                    </p>
                  </div>
                  {hasPermission("connect domain") ? (
                    <div className="flex flex-col sm:flex-row items-stretch sm:items-end gap-3">
                      <ComboBox
                        label="Select An Existing Domain"
                        placeholder="Select domain"
                        selectedOption={domain}
                        setSelectedOption={setDomain}
                        options={domains?.map((item) => ({
                          _id: item._id,
                          name: item.domain,
                        }))}
                        className="flex-1"
                      />
                      <button
                        disabled={!domain}
                        onClick={connectDomain}
                        className="btnPrimary text-base disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 hover:scale-105 active:scale-95"
                      >
                        Add Domain
                      </button>
                    </div>
                  ) : (
                    <div className="p-4 bg-yellow-50 dark:bg-yellow-900/50 text-yellow-700 dark:text-yellow-300 rounded-lg">
                      You aren't authorized to assign domain to this project.
                    </div>
                  )}
                </div>
              )}

              {/* Free Domain Card */}
              <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-100 dark:border-gray-700 p-6 transition-all hover:shadow-xl">
                <div className="flex items-center gap-3 mb-4">
                  <div className="w-2 h-2 rounded-full bg-primary"></div>
                  <h4 className="text-xl font-semibold">Free Domain</h4>
                </div>
                <a
                  href={`http://${projectToManage?.slug}.sitebuilderz.com`}
                  target="_blank"
                  rel="noreferrer"
                  className="inline-block text-primary hover:text-primary-dark transition-colors break-all p-4 bg-gray-50 dark:bg-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  https://{projectToManage?.slug}.sitebuilderz.com
                </a>
              </div>
            </div>
          )}
        </div>

        {confirmDisconnect && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 dark:bg-black/40 bg-opacity-50 z-50">
            <div
              ref={confirmDisconnectRef}
              className="bg-white dark:bg-gray-800 p-6 rounded shadow-lg max-w-md"
            >
              <div className="flex gap-3">
                <ShieldAlertIcon className="h-12 w-10 text-yellow-400 mb-3" />
                <p className="flex-1">
                  Are you sure? You want to disconnect domain{" "}
                  <span className="underline text-primary">
                    {projectToManage?.domain_id?.domain}
                  </span>{" "}
                  from project {projectToManage?.project_name}.
                </p>
              </div>
              <div className="flex items-center justify-end gap-6 mt-6">
                <button onClick={() => setConfirmDisconnect(false)}>
                  Cancel
                </button>
                <button
                  onClick={disConnectDomain}
                  className="btnPrimary bg-red-500"
                >
                  {disconnecting ? (
                    <LoaderCircle className="w-4 h-4 animate-spin" />
                  ) : (
                    <CloudOff className="w-4 h-4" />
                  )}
                  {disconnecting ? <p>Disconnecting</p> : <p>Disconnect</p>}
                </button>
              </div>
            </div>
          </div>
        )}

        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-3xl"
          title={
            <span className="text-2xl">Steps to point domain to registrar</span>
          }
        >
          <div className="flex items-end gap-2">
            <InputField
              label="Your Domain"
              value={projectToManage?.domain_id?.domain}
              disabled
              className="flex-1"
            />
            <div
              className={`px-4 py-2 rounded whitespace-nowrap font-medium ${
                projectToManage?.domain_id?.cloudflair_details?.status ===
                  "pending" && "bg-red-100 text-red-500"
              }`}
            >
              Pending DNS
            </div>
          </div>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">
              1. Log in to your domain registrar account:
            </b>
            Visit the website where you registered your domain name and log in
            to your account using your username and password.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">
              2. Locate your domain settings:
            </b>{" "}
            Once logged in, navigate to the domain management section. This
            might be labeled as "Domain Management," "My Domains," or something
            similar.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">
              3. Find the nameserver settings:
            </b>{" "}
            Look for an option like "Nameservers," "DNS Management," or "DNS
            Settings" within the domain management interface. Click on it to
            access your domain's DNS settings.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">
              4. View current nameservers:
            </b>{" "}
            Before making any changes, take note of the current nameservers
            listed for your domain. This information might be useful in case you
            need to revert the changes later.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">
              5. Edit nameserver settings:
            </b>
            Now, you should see fields where you can enter the new nameserver
            information. Delete the existing nameservers and replace them with
            the ones we provided you.
          </p>
          <img src="/img/cloudflare.png" className="w-44 my-6" alt="" />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
            {projectToManage?.domain_id?.cloudflair_details?.name_servers.map(
              (item, index) => (
                <div
                  className="bg-white dark:bg-gray-900 dark:border-white/20 shadow-md border rounded-md py-2 px-4 flex items-center gap-2 relative"
                  key={index}
                >
                  <p className="flex-1">{item}</p>
                  {isCopied === index ? (
                    <CheckCheck className="w-4 text-green-500" />
                  ) : (
                    <Copy
                      className="w-4 cursor-pointer text-gray-500 dark:text-white/80 hover:text-primary transition-all"
                      onClick={() => copyToClipboard(item, index)}
                    />
                  )}
                </div>
              )
            )}
          </div>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">6. Save changes:</b>
            After entering the new nameserver information, look for a "Save" or
            "Update" button and click on it to save your changes.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">7. Confirm changes:</b>
            Some registrars may require you to confirm the changes via email or
            another method. Follow any prompts or instructions to confirm the
            nameserver update.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">
              8. Wait for propagation:
            </b>
            DNS changes can take some time to propagate across the internet.
            This process typically takes a few hours to up to 48 hours, although
            it's often much quicker.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">9. Verify changes:</b>
            Once the propagation is complete, we will notify you via email also
            you can verify that the nameserver change was successful by using
            online tools like DNS lookup or by visiting your website to ensure
            it loads correctly.
          </p>
        </Modal>
      </div>
    )
  );
}
