import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Camera,
  CheckCheck,
  Copy,
  Edit,
  Grid2X2,
  List,
  Trash,
} from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import useApi from "../../../../utils/useApi";
import { useProjectContext } from "../ProjectContext";
import AddUpdateImage from "./AddUpdateImage";

// Utility function to format file sizes
export const formatFileSize = (size) => {
  if (size < 1024) return `${size} bytes`;
  let i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / Math.pow(1024, i)).toFixed(2)} ${
    ["B", "KB", "MB", "GB", "TB"][i]
  }`;
};

// Component to toggle between grid and list views
const GridToggle = ({ grid, setGrid }) => (
  <div className="bg-white dark:bg-gray-800 p-1 rounded-md border border-gray-300 dark:border-white/30 flex items-center justify-center">
    {["grid", "list"].map((view, idx) => (
      <button
        key={view}
        onClick={() => setGrid(view === "grid")}
        className={`cursor-pointer rounded hover:bg-gray-200 dark:hover:bg-gray-900 py-1 px-4 ${
          grid === (view === "grid") ? "bg-gray-200 dark:bg-gray-900" : ""
        }`}
      >
        {view === "grid" ? (
          <Grid2X2 className="h-5 w-5" />
        ) : (
          <List className="h-5 w-5" />
        )}
      </button>
    ))}
  </div>
);

// Component to handle file input
const FileInput = ({ handleFileChange }) => (
  <div className="relative">
    <input
      type="file"
      onChange={handleFileChange}
      id="imageUpload"
      className="hidden"
      accept="image/*"
      multiple
    />
    <label htmlFor="imageUpload" className="btnWhite px-3">
      Add Image
      <Camera className="w-4 h-4" />
    </label>
  </div>
);

// Image list header component
const ImageListHeader = () => (
  <div className="grid grid-cols-imageDetail text-sm font-medium items-center gap-5 border-b border-gray-400 pb-1 mb-3 w-full">
    <p>Image</p>
    <p>Actions</p>
    <p>File Type</p>
    <p>Size</p>
    <p>Dimensions</p>
    <p>Title</p>
    <p>Alt Text</p>
  </div>
);

// Component to render individual image items
const ImageItem = ({
  item,
  grid,
  image,
  handleEditImage,
  handleDeleteClick,
  copyToClipboard,
  isCopied,
}) => (
  <div
    className={`relative ${
      grid
        ? ""
        : "grid grid-cols-imageDetail border-b dark:border-white/20 py-2 gap-5 last:mb-4"
    }`}
  >
    <div
      className={`relative overflow-hidden bg-black rounded-md ${
        grid ? "h-40" : "h-9"
      }`}
    >
      <img alt="preview" className="w-full min-h-full" src={image} />
    </div>
    <div
      className={`flex items-center gap-2 ${grid ? "mt-2 justify-end" : ""}`}
    >
      {isCopied === item.key ? (
        <CheckCheck className="w-5 h-5 text-green-500" />
      ) : (
        <Copy
          className="w-5 h-5 cursor-pointer text-gray-500 dark:text-white/80 hover:text-primary transition"
          onClick={() => copyToClipboard(item)}
        />
      )}
      <Edit
        className="w-5 h-5 hover:text-primary cursor-pointer"
        onClick={() => handleEditImage(item)}
      />
      <Trash
        className="deleteIcon cursor-pointer w-5 h-5 text-red-500"
        onClick={() => handleDeleteClick(item.key)}
      />
    </div>
    {!grid && (
      <>
        <p className="overflow-hidden">{item?.fileType}</p>
        <p className="overflow-hidden">{item?.fileSize}</p>
        <p className="overflow-hidden">{item?.dimensions}</p>
        <p className="overflow-hidden">{item?.imageTitle}</p>
        <p className="overflow-hidden">{item?.imageAltText}</p>
      </>
    )}
  </div>
);

// Delete confirmation popup component
const DeleteConfirmationPopup = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg">
        <h2 className="text-lg font-bold">Are you sure?</h2>
        <p>
          Do you really want to delete this image? This process cannot be
          undone.
        </p>
        <div className="flex justify-end mt-4">
          <button className="btnPrimary bg-red-500 text-sm" onClick={onConfirm}>
            Delete
          </button>
          <button
            className="bg-gray-300 px-4 py-2 rounded-md text-sm"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

// Main ProjectGallery component
export default function ProjectGallery() {
  const { request } = useApi();
  const { project_id } = useProjectContext();
  const { imageKey } = useParams();

  const [images, setImages] = useState([]);
  const [image, setImage] = useState({});
  const [fileInfo, setFileInfo] = useState(null);
  const [sidebar, setSidebar] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [grid, setGrid] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState("");
  const [deletingArticle, setDeletingArticle] = useState(false);
  const [isCopied, setIsCopied] = useState(null);

  const toggleSidebar = useCallback(() => {
    setSidebar((prevSidebar) => !prevSidebar);
  }, []);

  const getGallery = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/gallery`,
      });
      setImages(res.data[0]?.value || []);
    } catch (err) {
      console.error("Failed to fetch gallery:", err);
      toast.error("Failed to fetch gallery.");
      setImages([]);
    }
  }, [project_id, request]);

  useEffect(() => {
    getGallery();
  }, [project_id]);

  useEffect(() => {
    if (imageKey && images.length > 0) {
      const imageToEdit = images.find((item) => item.key === imageKey);
      if (imageToEdit) {
        setImage(imageToEdit);
        setFileInfo(null);
        setSidebar(true);
      }
    }
  }, [imageKey, images]);

  const handleChange = (e) => {
    setImage((prevImage) => ({
      ...prevImage,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileChange = useCallback((e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      const fileSizeKB = file.size / 1024; // Convert bytes to KB
      if (fileSizeKB > 100) {
        toast.error("Image size must be less than 100KB");
        e.target.value = ""; // Reset the input
        return;
      }

      const img = new Image();
      img.onload = () => {
        setImage((prevImage) => ({
          ...prevImage,
          fileSize: formatFileSize(file.size),
          fileType: file.type,
          dimensions: `${img.width}x${img.height}`,
        }));
      };
      img.src = URL.createObjectURL(file);
      setFileInfo({ file, preview: img.src });
      setSidebar(true);
    }
  }, []);

  const createUpdate = useCallback(
    async (event) => {
      event.preventDefault();
      setIsUpdating(true);

      if (!image.imageTitle || !image.imageAltText) {
        toast.error("Image title and alt text cannot be empty.");
        setIsUpdating(false); // Reset the updating state
        return;
      }

      const uniqueKey = image.key || `image-${uuidv4()}`;
      const updatedImage = { ...image, key: uniqueKey };

      try {
        const formData = new FormData();
        formData.append("key", uniqueKey);
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(updatedImage));
        if (fileInfo?.file) {
          formData.append("file", fileInfo.file);
        }

        const res = await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (res.status) {
          const imageWithResponseData = {
            ...updatedImage,
            _id: res.data._id,
            key: res.data.key,
            image: res.data.file_name,
            createdAt: res.data.createdAt,
            updatedAt: res.data.updatedAt,
          };

          const updatedImagesList = [...images];
          const imgIndex = updatedImagesList.findIndex(
            (img) => img.key === uniqueKey
          );

          if (imgIndex !== -1) {
            updatedImagesList[imgIndex] = imageWithResponseData;
          } else {
            updatedImagesList.push(imageWithResponseData);
          }

          const updatedFormData = new FormData();
          updatedFormData.append("key", "gallery");
          updatedFormData.append("value_type", "JSON");
          updatedFormData.append("value", JSON.stringify(updatedImagesList));

          await request({
            method: "post",
            url: `projects/${project_id}/data`,
            data: updatedFormData,
            headers: { "Content-Type": "multipart/form-data" },
          });

          toast.success("Image updated.");
          getGallery();
        }
        setFileInfo(null);
        setImage({});
        setSidebar(false);
      } catch (err) {
        console.error("Failed to add/update image:", err);
        toast.error("Failed to add/update image.");
      } finally {
        setIsUpdating(false);
      }
    },
    [image, fileInfo, images, getGallery, project_id, request]
  );

  const handleEditImage = useCallback(
    async (image) => {
      setSidebar(true);
      try {
        const res = await request({
          method: "get",
          url: `projects/${project_id}/data/${image.key}`,
        });

        const img = res.data?.[0]?.value || {};
        const file = res.data?.[0]?.file_name;

        setImage({
          key: img.key,
          _id: img._id,
          createdAt: image.createdAt,
          updatedAt: image.updatedAt,
          fileSize: image.fileSize,
          fileType: image.fileType,
          dimensions: image.dimensions,
          imageTitle: img.imageTitle || "",
          imageAltText: img.imageAltText || "",
        });

        setFileInfo({
          file,
          preview: file
            ? `${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${image.image}`
            : null,
        });
      } catch (err) {
        console.error(err);
        toast.error("Failed to fetch banner data.");
      }
    },
    [project_id, request]
  );

  const handleDeleteClick = useCallback((key) => {
    setKeyToDelete(key);
    setDeleteConfirmation(true);
  }, []);

  const deleteFromList = useCallback(async () => {
    if (!keyToDelete) return;
    setDeletingArticle(true);

    const updatedImagesList = images.filter(
      (image) => image.key !== keyToDelete
    );

    setImages(updatedImagesList);

    try {
      const formData = new FormData();
      formData.append("key", "gallery");
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(updatedImagesList));

      const res = await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (res.status) {
        toast.success("Image Removed.");
      }
    } catch (err) {
      console.error("Error deleting image:", err);
      toast.error("Failed to delete image.");
    } finally {
      setDeletingArticle(false);
      setDeleteConfirmation(false);
    }
  }, [keyToDelete, images, project_id, request]);

  const copyToClipboard = useCallback(
    (item) => {
      navigator.clipboard
        .writeText(
          `${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${item.image}`
        )
        .then(() => {
          setIsCopied(item.key);
          setTimeout(() => setIsCopied(null), 2000);
          toast.success("Image URL Copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          toast.error("Failed to copy to clipboard");
        });
    },
    [project_id]
  );

  const renderImages = useMemo(
    () =>
      Array.isArray(images) && images.length > 0
        ? images.map((item, index) => (
            <ImageItem
              key={index}
              item={item}
              grid={grid}
              project_id={project_id}
              handleEditImage={handleEditImage}
              handleDeleteClick={handleDeleteClick}
              copyToClipboard={copyToClipboard}
              isCopied={isCopied}
              image={`${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${item.image}`}
            />
          ))
        : null,
    [
      images,
      grid,
      project_id,
      handleEditImage,
      handleDeleteClick,
      copyToClipboard,
      isCopied,
    ]
  );

  return (
    <div className="h-[calc(100vh-6rem)] overflow-y-scroll px-8 pt-6">
      <Toaster />
      <div className="flex items-center justify-between mb-5">
        <div>
          <h2 className="font-bold">Project Gallery</h2>
          <p className="text-gray-500">
            You can use these images only between articles.
          </p>
        </div>
        <div className="flex items-center justify-end gap-2">
          <GridToggle grid={grid} setGrid={setGrid} />
          <FileInput handleFileChange={handleFileChange} />
        </div>
      </div>

      <div
        className={`grid ${
          grid
            ? "grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-5 mt-6"
            : "grid-cols-1 overflow-x-scroll"
        }`}
      >
        {grid ? null : <ImageListHeader />}
        {renderImages}
      </div>

      <AddUpdateImage
        sidebar={sidebar}
        toggleSidebar={toggleSidebar}
        handleChange={handleChange}
        isUpdating={isUpdating}
        createUpdate={createUpdate}
        handleFileChange={handleFileChange}
        fileInfo={fileInfo}
        image={image}
      />

      <DeleteConfirmationPopup
        isOpen={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        onConfirm={deleteFromList}
      />
    </div>
  );
}
