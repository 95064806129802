import React, { useEffect, useState, useCallback, memo } from "react";

import Robots from "./Robots";
import useApi from "../../../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import {
  LoaderCircle,
  Save,
  Settings,
  Home,
  Info,
  Phone,
  Shield,
  FileText,
  FolderOpen,
  Tags,
  Search,
  BookMarked,
  BarChart3,
  FileCheck,
  FileText as MetaIcon,
  BadgeCheck,
} from "lucide-react";
import { useProjectContext } from "../ProjectContext";
import { InputField, TextArea } from "../../../../components";
import CircularProgressBar from "../../../../components/common/CircularProgress";

const useMetaData = (project_id, key, request) => {
  const [data, setData] = useState({ title: "", description: "" });

  const fetchMetaData = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/${key}`,
      });
      const { title = "", description = "" } = res.data[0]?.value || {};
      setData({ title, description });
    } catch (err) {
      console.error(err);
    }
  }, [project_id, key, request]);

  useEffect(() => {
    fetchMetaData();
  }, []);

  return [data, setData, fetchMetaData];
};

// Custom hook for fetching tag list
const useTagList = (project_id, request) => {
  const [tagList, setTagList] = useState([]);

  const fetchTagList = useCallback(async () => {
    try {
      const data = await request({
        method: "get",
        url: `projects/${project_id}/data/tag_list`,
      });
      setTagList(data?.data[0]?.value || []);
    } catch (err) {
      console.error(err);
    }
  }, [project_id, request]);

  useEffect(() => {
    fetchTagList();
  }, []);

  return tagList;
};

// Reusable component for meta sections
const MetaSection = memo(
  ({ sectionId, label, metaData, onMetaChange, onUpdateMeta, isUpdating }) => (
    <section
      id={sectionId}
      className="px-8 py-7 shadow-lg bg-white dark:bg-gray-800 rounded-xl transition-all hover:shadow-xl"
    >
      <div className="flex items-center justify-between border-b border-gray-100 dark:border-gray-700 pb-4 mb-6">
        <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
          {label}
        </h4>
        <button
          onClick={onUpdateMeta}
          className="inline-flex items-center gap-2 bg-primary hover:bg-primary/90 text-white py-2 px-4 rounded-lg transition-all"
        >
          {isUpdating ? (
            <LoaderCircle className="w-4 h-4 animate-spin" />
          ) : (
            <Save className="w-4 h-4" />
          )}
          <span className="text-sm font-medium">
            {isUpdating ? "Updating..." : "Save Changes"}
          </span>
        </button>
      </div>

      <div className="space-y-6">
        <div>
          <InputField
            label="Meta Title"
            name="title"
            placeholder="Enter meta title..."
            value={metaData.title}
            onChange={onMetaChange}
            className="w-full"
          />
          <CharacterCount
            label="Recommended Characters: 50-60"
            currentCount={metaData.title.length}
            isWithinRange={
              metaData.title.length >= 50 && metaData.title.length <= 60
            }
          />
        </div>

        <div>
          <TextArea
            label="Meta Description"
            name="description"
            placeholder="Enter meta description..."
            value={metaData.description}
            onChange={onMetaChange}
            className="w-full"
          />
          <CharacterCount
            label="Recommended Characters: 150-160"
            currentCount={metaData.description.length}
            isWithinRange={
              metaData.description.length >= 150 &&
              metaData.description.length <= 160
            }
          />
        </div>
      </div>
    </section>
  )
);

// Character count display component
const CharacterCount = ({ label, currentCount, isWithinRange }) => (
  <div className="flex items-center justify-between mt-2">
    <p className="text-sm text-gray-500 dark:text-gray-400">{label}</p>
    <div className="flex items-center gap-2">
      <span
        className={`px-2 py-1 rounded-md text-xs font-medium
        ${
          isWithinRange
            ? "bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400"
            : "bg-red-100 text-red-700 dark:bg-red-900/30 dark:text-red-400"
        }`}
      >
        {currentCount} characters
      </span>
      {!isWithinRange && <span className="text-red-500">⚠️</span>}
    </div>
  </div>
);

// Blog Tags component
const BlogTags = memo(({ tagList }) => (
  <section
    id="blogTags"
    className="px-7 py-6 bg-white dark:bg-gray-800 rounded-lg"
  >
    <h4 className="border-b pb-4 mb-5 font-bold">Blog Tags</h4>
    <div className="flex items-center flex-wrap gap-2 mt-6">
      {tagList.map((item, index) => (
        <TagItem key={index} tag={item.tag} count={item.article_ids?.length} />
      ))}
    </div>
  </section>
));

const TagItem = ({ tag, count }) => (
  <p className="px-3 py-1 rounded bg-orange-100 text-primary text-sm shadow">
    {tag}{" "}
    {count > 1 && (
      <span className="rounded-full bg-primary text-white px-1 text-xs ml-1">
        {count}
      </span>
    )}
  </p>
);

export default function ProjectSEO() {
  const { request } = useApi();
  const { project_id } = useProjectContext();
  const [activeSection, setActiveSection] = useState("robots");
  const [isUpdating, setIsUpdating] = useState("");

  // **Added progress state**
  const [progress, setProgress] = useState(0);

  // Fetch and manage meta data states
  const [metaHome, setMetaHome, getMetaHome] = useMetaData(
    project_id,
    "meta_home",
    request
  );
  const [metaAbout, setMetaAbout, getMetaAbout] = useMetaData(
    project_id,
    "meta_about",
    request
  );
  const [metaContact, setMetaContact, getMetaContact] = useMetaData(
    project_id,
    "meta_contact",
    request
  );
  const [metaPrivacy, setMetaPrivacy, getMetaPrivacy] = useMetaData(
    project_id,
    "meta_privacy",
    request
  );
  const [metaTerms, setMetaTerms, getMetaTerms] = useMetaData(
    project_id,
    "meta_terms",
    request
  );
  const [metaCategory, setMetaCategory, getMetaCategory] = useMetaData(
    project_id,
    "meta_category",
    request
  );
  const [metaTags, setMetaTags, getMetaTags] = useMetaData(
    project_id,
    "meta_tags",
    request
  );
  const [metaTag, setMetaTag, getMetaTag] = useMetaData(
    project_id,
    "meta_tag",
    request
  );

  // Fetch tag list
  const tagList = useTagList(project_id, request);

  const handleMetaChange = (e, setMeta) => {
    setMeta((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const updateMeta = useCallback(
    async (metaKey, metaData, fetchMeta) => {
      setIsUpdating(metaKey);
      try {
        const formData = new FormData();
        formData.append("key", metaKey);
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(metaData));
        const res = await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status) {
          const updatedFormData = new FormData();
          updatedFormData.append("key", "seo_status");
          updatedFormData.append("value_type", "JSON");
          updatedFormData.append("value", progress);

          await request({
            method: "post",
            url: `projects/${project_id}/data`,
            data: updatedFormData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          toast.success(`${metaKey.replace("meta_", "")} page meta updated.`);
        }

        fetchMeta();
      } catch (err) {
        toast.error(
          err.response?.data?.message ||
            "Operation could not be performed, some error occurred."
        );
      }
      setIsUpdating("");
    },
    [project_id, request]
  );

  useEffect(() => {
    const fields = [
      metaHome.title,
      metaHome.description,
      metaAbout.title,
      metaAbout.description,
      metaContact.title,
      metaContact.description,
      metaPrivacy.title,
      metaPrivacy.description,
      metaTerms.title,
      metaTerms.description,
      metaCategory.title,
      metaCategory.description,
      metaTag.title,
      metaTag.description,
      metaTags.title,
      metaTags.description,
    ];

    const totalFields = fields.length; // Now totalFields is 16
    const filledFields = fields.filter(
      (field) => field && field.trim() !== ""
    ).length;
    const progressPercentage = (filledFields / totalFields) * 100;
    setProgress(progressPercentage);
  }, [
    metaTag,
    metaHome,
    metaTags,
    metaAbout,
    metaTerms,
    metaContact,
    metaPrivacy,
    metaCategory,
  ]);

  const scrollToSection = (id) => {
    setActiveSection(id);
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    const sections = document.querySelectorAll("section[id]");
    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <div className="h-[calc(100vh-6rem)] overflow-y-scroll">
      {/* <Toaster position="top-right" /> */}

      {/* Main Content */}
      <div className="mx-auto px-8 py-6">
        <div className="grid grid-cols-[280px_1fr] gap-8">
          {/* Left Sidebar Navigation */}
          <div className="space-y-6">
            <div
              className="bg-white dark:bg-gray-800 rounded-2xl p-6 sticky top-6
              border border-gray-200 dark:border-gray-700 shadow-lg"
            >
              <div className="mb-6">
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  SEO Settings
                </h3>
              </div>
              <nav className="space-y-1">
                {[
                  {
                    section: "Technical SEO",
                    items: [
                      { id: "robots", label: "Robots.txt", icon: Settings },
                    ],
                  },
                  {
                    section: "Page Meta Tags",
                    items: [
                      { id: "meta_home", label: "Home Page", icon: Home },
                      { id: "meta_about", label: "About Page", icon: Info },
                      {
                        id: "meta_contact",
                        label: "Contact Page",
                        icon: Phone,
                      },
                      {
                        id: "meta_privacy",
                        label: "Privacy Policy",
                        icon: Shield,
                      },
                      {
                        id: "meta_terms",
                        label: "Terms & Conditions",
                        icon: FileText,
                      },
                    ],
                  },
                  {
                    section: "Blog SEO",
                    items: [
                      {
                        id: "meta_category",
                        label: "Category Page",
                        icon: FolderOpen,
                      },
                      { id: "meta_tags", label: "Tags Overview", icon: Tags },
                      { id: "meta_tag", label: "Single Tag", icon: Search },
                      {
                        id: "blogTags",
                        label: "Tag Management",
                        icon: BookMarked,
                      },
                    ],
                  },
                ].map((group, groupIndex) => (
                  <div
                    key={group.section}
                    className={groupIndex !== 0 ? "mt-6" : ""}
                  >
                    <h4 className="text-xs font-medium mt-5 text-gray-500 dark:text-gray-400 uppercase tracking-wider mb-2">
                      {group.section}
                    </h4>
                    {group.items.map(({ id, label, icon: Icon }) => (
                      <button
                        key={id}
                        onClick={() => scrollToSection(id)}
                        className={`w-full flex items-center gap-3 px-4 py-3 rounded-xl transition-all
                          ${
                            activeSection === id
                              ? "bg-gradient-to-r from-primary/20 to-primary/5 text-primary font-medium"
                              : "hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-300"
                          }`}
                      >
                        <Icon className="w-5 h-5" />
                        <span className="text-sm">{label}</span>
                      </button>
                    ))}
                  </div>
                ))}
              </nav>
            </div>
          </div>

          {/* Main Content Area */}
          <div className="space-y-6">
            {/* Header Section */}

            <div>
              <h2 className="text-3xl font-bold ">
                Search Engine Optimization
              </h2>
              <p className="text-gray-600 dark:text-gray-300">
                Optimize your site's visibility in search engines
              </p>
            </div>

            {/* Quick Stats */}
            <div className="grid grid-cols-4 gap-4 mb-6">
              <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 border border-gray-200 dark:border-gray-700">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-primary/10 rounded-xl">
                    <BarChart3 className="w-6 h-6 text-primary" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Completed Pages
                    </p>
                    <p className="text-2xl font-semibold text-primary">
                      {Math.round(progress / 10)} / 10
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 border border-gray-200 dark:border-gray-700">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-green-100 dark:bg-green-900/30 rounded-xl">
                    <FileCheck className="w-6 h-6 text-green-600 dark:text-green-400" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Meta Titles
                    </p>
                    <p className="text-2xl font-semibold text-green-600 dark:text-green-400">
                      8 / 10
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 border border-gray-200 dark:border-gray-700">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-blue-100 dark:bg-blue-900/30 rounded-xl">
                    <MetaIcon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Meta Descriptions
                    </p>
                    <p className="text-2xl font-semibold text-blue-600 dark:text-blue-400">
                      7 / 10
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 border border-gray-200 dark:border-gray-700">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-gradient-to-bl from-primary/5 to-primary/20 rounded-xl">
                    <BadgeCheck className="w-6 h-6 text-primary" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Overall Progress
                    </p>
                    <p className="text-2xl font-semibold text-primary dark:text-blue-400">
                      {Math.round(progress)}%
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Technical SEO Section */}
            <section id="robots" className="space-y-6">
              <Robots />
            </section>

            {/* Page Meta Tags Sections */}
            <MetaSection
              sectionId="meta_home"
              label="Home Page Meta Tags"
              metaData={metaHome}
              onMetaChange={(e) => handleMetaChange(e, setMetaHome)}
              onUpdateMeta={() =>
                updateMeta("meta_home", metaHome, getMetaHome)
              }
              isUpdating={isUpdating === "meta_home"}
            />

            <MetaSection
              sectionId="meta_about"
              label="About Page Meta Tags"
              metaData={metaAbout}
              onMetaChange={(e) => handleMetaChange(e, setMetaAbout)}
              onUpdateMeta={() =>
                updateMeta("meta_about", metaAbout, getMetaAbout)
              }
              isUpdating={isUpdating === "meta_about"}
            />

            <MetaSection
              sectionId="meta_contact"
              label="Contact Page Meta Tags"
              metaData={metaContact}
              onMetaChange={(e) => handleMetaChange(e, setMetaContact)}
              onUpdateMeta={() =>
                updateMeta("meta_contact", metaContact, getMetaContact)
              }
              isUpdating={isUpdating === "meta_contact"}
            />

            <MetaSection
              sectionId="meta_privacy"
              label="Privacy Policy Meta Tags"
              metaData={metaPrivacy}
              onMetaChange={(e) => handleMetaChange(e, setMetaPrivacy)}
              onUpdateMeta={() =>
                updateMeta("meta_privacy", metaPrivacy, getMetaPrivacy)
              }
              isUpdating={isUpdating === "meta_privacy"}
            />

            <MetaSection
              sectionId="meta_terms"
              label="Terms & Conditions Meta Tags"
              metaData={metaTerms}
              onMetaChange={(e) => handleMetaChange(e, setMetaTerms)}
              onUpdateMeta={() =>
                updateMeta("meta_terms", metaTerms, getMetaTerms)
              }
              isUpdating={isUpdating === "meta_terms"}
            />

            {/* Blog SEO Sections */}
            <MetaSection
              sectionId="meta_category"
              label="Category Page Meta Tags"
              metaData={metaCategory}
              onMetaChange={(e) => handleMetaChange(e, setMetaCategory)}
              onUpdateMeta={() =>
                updateMeta("meta_category", metaCategory, getMetaCategory)
              }
              isUpdating={isUpdating === "meta_category"}
            />

            <MetaSection
              sectionId="meta_tags"
              label="Tags Overview Meta Tags"
              metaData={metaTags}
              onMetaChange={(e) => handleMetaChange(e, setMetaTags)}
              onUpdateMeta={() =>
                updateMeta("meta_tags", metaTags, getMetaTags)
              }
              isUpdating={isUpdating === "meta_tags"}
            />

            <MetaSection
              sectionId="meta_tag"
              label="Single Tag Meta Tags"
              metaData={metaTag}
              onMetaChange={(e) => handleMetaChange(e, setMetaTag)}
              onUpdateMeta={() => updateMeta("meta_tag", metaTag, getMetaTag)}
              isUpdating={isUpdating === "meta_tag"}
            />

            {/* Blog Tags Management */}
            <section id="blogTags" className="space-y-6">
              <BlogTags tagList={tagList} />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
