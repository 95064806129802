import React, { useEffect, useState, useCallback } from "react";
import { CameraIcon, LoaderCircle, Save } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import useApi from "../../../utils/useApi";
import { useProjectContext } from "./ProjectContext";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import ReactMarkdown from "react-markdown";

export default function AboutOwner() {
  const { request } = useApi();
  const { project_id, refreshIframe } = useProjectContext();

  const [aboutMe, setAboutMe] = useState("");
  const [fileInfo, setFileInfo] = useState({ file: null, preview: "" });
  const [isUpdating, setIsUpdating] = useState(false);

  const getAboutMe = async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/${"about_me"}`,
      });
      setAboutMe(res.data[0].value);
      const file = res.data[0].file_name;
      setFileInfo({
        file: file,
        preview: `${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${file}`,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAboutMe();
  }, [project_id]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileInfo({
        file,
        preview: URL.createObjectURL(file),
      });
    }
  };

  const updateKeyValue = async (event) => {
    event.preventDefault();
    setIsUpdating(true);
    try {
      const formData = new FormData();
      formData.append("key", "about_me");
      formData.append("value", aboutMe);
      if (fileInfo?.file) {
        formData.append("file", fileInfo.file);
      }

      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      toast.success("About Us section updated.");
      getAboutMe();
      refreshIframe();
    } catch (err) {
      toast.error(err.response?.data?.message || "Error updating About Us.");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="h-[calc(100vh-6rem)] overflow-y-scroll px-8 pt-6">
      <Toaster />
      <h2 className="font-bold mb-5">About Us</h2>
      <div className="relative h-44 transition-all w-full rounded-md bg-gray-300 border-white/20 flex items-center justify-center overflow-hidden">
        {fileInfo.preview && (
          <img
            title="Upload an image"
            src={fileInfo.preview}
            loading="lazy"
            alt="About Us"
            className="min-w-full min-h-full object-cover absolute top-0"
          />
        )}
        <div className="flex flex-col items-center justify-center z-10 w-full h-full bg-black/30">
          <input
            type="file"
            onChange={handleFileChange}
            id="imageUpload"
            className="hidden"
            accept="image/*"
          />
          <label
            htmlFor="imageUpload"
            className="btnWhite py-1 px-2 m-3 cursor-pointer hover:bg-primary hover:text-white transition-all flex items-center gap-2 absolute right-0 bottom-0 h-fit"
          >
            Upload Image
            <CameraIcon className="w-4" />
          </label>
        </div>
      </div>
      <p className="mt-3 font-semibold">About Us Text</p>
      <p className="text-xs mb-1 text-gray-500">(This is markdown text)</p>
      <MdEditor
        value={aboutMe}
        onChange={({ text }) => setAboutMe(text)}
        renderHTML={(text) => <ReactMarkdown>{text}</ReactMarkdown>}
        className="w-full min-h-[calc(100vh-30rem)]"
        placeholder="# This is a markdown editor"
      />
      <button
        title="Save & Update"
        disabled={isUpdating}
        onClick={updateKeyValue}
        className={`btnPrimary bg-secondary mt-5 ml-auto px-3 ${
          isUpdating ? "cursor-wait" : ""
        }`}
      >
        {isUpdating ? (
          <LoaderCircle className="w-4 animate-spin" />
        ) : (
          <Save className="w-4 h-4" />
        )}
        {isUpdating ? "Updating" : "Save & Update"}
      </button>
    </div>
  );
}
