import { X } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import AIContentManager from "../AIContentManager";

export default function AIChat({
  showAIDropdown,
  setShowAIDropdown,
  currentProject,
  currentPath,
}) {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [activeTab, setActiveTab] = useState("manager");

  // Auto-scroll to bottom when new messages arrive
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Escape key to close sidebar
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape" && showAIDropdown) {
        setShowAIDropdown(false);
      }
    };

    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, [showAIDropdown, setShowAIDropdown]);

  useEffect(() => {
    if (showAIDropdown) {
      inputRef.current?.focus();
    }
  }, [showAIDropdown]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        showAIDropdown
      ) {
        setShowAIDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showAIDropdown, setShowAIDropdown]);

  const getContextInfo = () => {
    if (!currentProject) return { project: "No Project", section: "Dashboard" };

    const pathSegments = currentPath?.split("/").filter(Boolean);
    const currentSection = pathSegments?.[pathSegments.length - 1];

    // Convert path to readable format
    const section = currentSection
      ? currentSection
          .replace(/-/g, " ")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : "Dashboard";

    return {
      project: currentProject.project_name || "No Project",
      section,
    };
  };

  const handleSendMessage = async (e) => {
    e?.preventDefault();
    if (!inputMessage.trim() || isLoading) return;

    const userMessage = { role: "user", content: inputMessage.trim() };

    try {
      setMessages((prev) => [...prev, userMessage]);
      setInputMessage("");
      setIsLoading(true);

      const response = await fetch("/api/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: userMessage.content,
          context: {
            project: project,
            section: section,
            currentPath: currentPath,
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setMessages((prev) => [
        ...prev,
        { role: "assistant", content: data.message },
      ]);
      setTimeout(() => inputRef.current?.focus(), 100);
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: "Sorry, I encountered an error. Please try again.",
        },
      ]);
      setTimeout(() => inputRef.current?.focus(), 100);
    } finally {
      setIsLoading(false);
    }
  };

  const { project, section } = getContextInfo();

  const MessageBubble = ({ message, isLoading }) => (
    <div
      className={`flex items-start gap-2 mb-4 ${
        message.role === "user" ? "flex-row-reverse" : ""
      }`}
    >
      <div className="w-9 h-9 rounded-full flex-shrink-0 overflow-hidden border-2 border-gray-200/30 dark:border-gray-700/60">
        {message.role === "user" ? (
          <div className="w-full h-full uppercase bg-gradient-to-r from-orange-400 to-primary flex items-center justify-center text-white text-sm">
            {user?.first_name.slice(0, 1) + user?.last_name.slice(0, 1)}
          </div>
        ) : (
          <div className="w-full h-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-white text-sm">
            🤖
          </div>
        )}
      </div>
      <div
        className={`p-3 rounded-lg backdrop-blur-md ${
          message.role === "user"
            ? "bg-primary/15 max-w-[85%] border border-primary/30 shadow-lg shadow-primary/10"
            : "bg-gray-100/60 dark:bg-neutral-800/60 max-w-[85%] border border-gray-200/30 dark:border-gray-700/60 shadow-md"
        }`}
      >
        <p className="text-sm text-gray-900 dark:text-gray-100 leading-relaxed whitespace-pre-wrap">
          {message.content}
        </p>
      </div>
    </div>
  );

  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div
      ref={containerRef}
      className={`fixed top-0 right-0 z-50 h-screen w-fit shadow-l shadow-black/20 dark:shadow-gray-600/40 flex flex-col bg-white dark:bg-gray-950 backdrop-blur-xl border-l border-gray-200/20 dark:border-gray-800/50 transform transition-all duration-300 ease-out ${
        showAIDropdown ? "translate-x-0" : "translate-x-full"
      }`}
      onClick={() => inputRef.current?.focus()}
    >
      {/* Header */}
      <div className="px-6 py-1 dark:bg-gray-950 backdrop-blur-md">
        <div className="flex items-center justify-between border-b border-gray-200/50 dark:border-gray-700/50">
          <div className="flex">
            <button
              onClick={() => setActiveTab("manager")}
              className={`px-5 py-3 text-sm font-medium transition-all duration-200 relative
                  ${
                    activeTab === "manager"
                      ? "text-primary"
                      : "text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
                  }`}
            >
              <div className="flex items-center gap-2">
                <span>⚡</span>
                AI Manager
              </div>
              {activeTab === "manager" && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-primary" />
              )}
            </button>
            <button
              onClick={() => setActiveTab("chat")}
              className={`px-5 py-3 text-sm font-medium transition-all duration-200 relative
                  ${
                    activeTab === "chat"
                      ? "text-primary"
                      : "text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
                  }`}
            >
              <div className="flex items-center gap-2">
                <span>💬</span>
                Chat
              </div>
              {activeTab === "chat" && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-primary" />
              )}
            </button>
          </div>

          <button
            onClick={() => setShowAIDropdown(false)}
            className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 
                transition-all duration-200 hover:rotate-90"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {/* Context info - only show in chat tab */}
        {activeTab === "chat" && (
          <div className="flex items-center gap-2 flex-wrap mt-1 py-3">
            <div className="flex items-center text-xs text-gray-600 dark:text-gray-300 bg-gray-100/80 dark:bg-neutral-800/80 rounded-full px-4 py-1.5 w-fit duration-200">
              <span className="flex items-center gap-2">
                <span className="w-3.5 h-3.5">🗂️</span>
                {project}
              </span>
            </div>
            <div className="flex items-center text-xs text-gray-600 dark:text-gray-300 bg-gray-100/80 dark:bg-neutral-800/80 rounded-full px-4 py-1.5 w-fit duration-200">
              <span className="flex items-center gap-2">
                <span className="w-3.5 h-3.5">📁</span>
                {section}
              </span>
            </div>
          </div>
        )}
      </div>

      {activeTab === "chat" ? (
        <div className="flex-1 flex flex-col w-[400px]">
          {/* Chat Messages */}
          <div className="flex-1 overflow-y-auto py-1 bg-gradient-to-r from-primary/25 via-orange-500/25 to-pink-500/25">
            <div className="h-full overflow-y-auto p-4 bg-white dark:bg-gray-900 custom-scrollbar backdrop-blur-sm">
              {messages.length === 0 ? (
                <div className="text-center text-gray-500 dark:text-gray-400 mt-32 space-y-3">
                  <div className="w-16 h-16 mx-auto mb-6 rounded-2xl bg-gradient-to-r from-primary via-orange-500 to-pink-500 p-[4px] flex items-center justify-center group hover:scale-110 transition-transform duration-300">
                    <div className="w-full h-full rounded-xl bg-white dark:bg-gray-900 flex items-center justify-center">
                      <span className="text-2xl group-hover:animate-bounce">
                        🤖
                      </span>
                    </div>
                  </div>
                  <p className="text-sm font-medium">No messages yet</p>
                  <p className="text-xs">
                    Ask anything about {section.toLowerCase()}
                  </p>
                </div>
              ) : (
                <>
                  {messages.map((message, index) => (
                    <MessageBubble key={index} message={message} />
                  ))}
                  {isLoading && (
                    <div className="bg-gray-100/60 dark:bg-neutral-800/60 p-4 rounded-2xl mr-auto max-w-[85%] border border-gray-200/30 dark:border-gray-700/60 shadow-lg">
                      <div className="flex gap-2">
                        <div className="w-2 h-2 rounded-full bg-primary animate-bounce" />
                        <div className="w-2 h-2 rounded-full bg-primary animate-bounce [animation-delay:0.2s]" />
                        <div className="w-2 h-2 rounded-full bg-primary animate-bounce [animation-delay:0.4s]" />
                      </div>
                    </div>
                  )}
                  <div ref={messagesEndRef} />
                </>
              )}
            </div>
          </div>

          {/* Input Form */}
          <div className="px-5 py-6 border-t border-gray-200/20 dark:border-gray-800/50 bg-white dark:bg-gray-950 backdrop-blur-md">
            <form onSubmit={handleSendMessage} className="relative">
              <input
                ref={inputRef}
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder={`Ask about ${section.toLowerCase()}...`}
                className="w-full p-3 rounded-lg border border-gray-200/30 dark:border-gray-800/60 bg-gray-100/60 dark:bg-neutral-800/60 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-primary/80 pr-28 placeholder:text-gray-500/70"
                disabled={isLoading}
              />
              <button
                type="submit"
                disabled={isLoading || !inputMessage.trim()}
                className="absolute right-2 top-1/2 -translate-y-1/2 px-5 py-2 bg-primary text-white rounded-lg hover:opacity-90 disabled:opacity-80 disabled:cursor-not-allowed transition-all duration-300 text-sm font-medium hover:scale-105 active:scale-95"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      ) : (
        <AIContentManager sidebar />
      )}
    </div>
  );
}
