import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { ArrowRight, CircleCheck, LoaderCircle, Stars } from "lucide-react";
import { Breadcrumbs, InputField, ListBox } from "../../../components";
import { TypeAnimation } from "react-type-animation";
import { useSpring, animated } from "react-spring";

import useApi from "../../../utils/useApi";
import SelectTemplate from "./SelectTemplate";
import Step1Basic from "./Steps/Step1Basic";
import Step2Content from "./Steps/Step2Content";
import Step3Sections from "./Steps/Step3Sections";
import Step4Domain from "./Steps/Step4Domain";

/* -------------------------------------------------------------------------- */
/*                                Sub-Components                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                Main Component                              */
/* -------------------------------------------------------------------------- */

/**
 * NewProject
 * Combines manual project creation and AI-driven site creation wizard.
 */
export default function NewProject() {
  /* ------------------------------ API & State ----------------------------- */
  const { request, isLoading } = useApi();
  const navigate = useNavigate();

  // Basic states
  const [siteCreated, setCreated] = useState(false);
  const [industry, setIndustry] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [selectedThemeId, setSelectedThemeId] = useState(null);
  const [project_name, setProjectName] = useState("");
  const [chat, setChat] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);

  const merchant = JSON.parse(localStorage.getItem("merchant")) || {};
  const stepNames = ["Basic Setup", "Content", "Sections", "Domain"];
  const totalSteps = stepNames.length;

  const [formData, setFormData] = useState({
    projectName: "",
    industryName: "",
    category: "",
    numArticles: "",
    numAuthors: "",
    numTags: "",
    wordCount: "",
    createdBy: "admin",
    siteDomain: "",
    numSections: "",
    bannerResolution: "",
    companyName: "",
    companyWebsite: "",
    companyLocation: "",
    phoneNumber: "",
  });

  /* ---------------------------- Lifecycle Hooks --------------------------- */
  // 1. Fetch list of industries on mount
  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const data = await request({ method: "get", url: "industries" });
        setIndustries(data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchIndustries();
  }, []);

  // 2. Fetch templates when an industry is selected
  useEffect(() => {
    const fetchTemplates = async () => {
      // For manual setup
      if (industry?._id) {
        try {
          const data = await request({
            method: "get",
            url: `industries/${industry._id}/templates?active=1`,
          });
          setTemplates(data.data);
        } catch (err) {
          console.error(err);
        }
      }
      // For AI setup
      else if (formData.industry) {
        try {
          const selectedIndustry = industries.find(
            (ind) => ind.industry_name === formData.industry
          );
          if (selectedIndustry) {
            const data = await request({
              method: "get",
              url: `industries/${selectedIndustry._id}/templates?active=1`,
            });
            setTemplates(data.data);
          }
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchTemplates();
  }, [industry?._id, formData.industry, industries]);

  // Move to next question in AI wizard
  const handleNext = () => {
    // Validate current step
    if (!isStepValid(currentStep)) {
      toast.error("Please fill in all required fields");
      return;
    }

    // Move to next step if not on last step
    if (currentStep < totalSteps) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  // Execute final step from AI wizard
  const handleExecute = async () => {
    try {
      // Extract image dimensions from bannerResolution
      const [height, width] = formData.bannerResolution.split("x").map(Number);

      // Get auth token from localStorage
      const token = localStorage.getItem("token");

      // Prepare request payload
      const payload = {
        projectName: formData.projectName,
        industry: formData.industry,
        category: formData.category,
        titleCount: parseInt(formData.numArticles),
        authorCount: parseInt(formData.numAuthors),
        tagCount: parseInt(formData.numTags),
        wordCount: parseInt(formData.wordCount),
        createdBy: formData.createdBy,
        domain: formData.siteDomain,
        sectionCount: parseInt(formData.numSections),
        executeNow: 1,
        imgHeight: height,
        imgWidth: width,
        additionalData: {
          companyDetails: {
            companyName: formData.companyName,
            website: formData.companyWebsite,
            location: formData.companyLocation,
            phoneNumber: formData.phoneNumber,
          },
        },
      };

      // Make API request with headers
      const response = await request({
        method: "post",
        url: "https://apipm.ecommcube.com/create_project",
        data: payload,
        headers: {
          Authorization: `Bearer f61c1e040031ca554f74b1d58acff7b8`,
          "Content-Type": "application/json",
        },
      });

      toast.success("Project created successfully!");

      navigate(
        `/projects/${formData.projectName.toLowerCase().replaceAll(" ", "-")}`
      );
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to create project");
      console.error("Project creation error:", error);
    }
  };

  // Manage domain and project navigation
  const manageProject = () => {
    navigate(`/projects/${project_name.toLowerCase().replaceAll(" ", "-")}`);
    setProjectName("");
    setSelectedThemeId(null);
  };
  const manageDomains = () => {
    navigate(
      `/projects/${project_name.toLowerCase().replaceAll(" ", "-")}/domains`
    );
    setProjectName("");
    setSelectedThemeId(null);
  };

  // Create a new project in manual setup
  const handleCreateProject = async (e, template_id) => {
    e.preventDefault();
    try {
      await request({
        method: "post",
        url: "projects",
        data: {
          project_name,
          industry_id: industry?._id,
          industry_template_id: template_id,
          merchant_id: merchant?._id,
        },
      });
      toast.success("New Project Created");
      setCreated(true);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const isStepValid = (step) => {
    switch (step) {
      case 1:
        return formData.projectName && formData.industry && formData.templateId;
      case 2:
        if (formData.industry.toLowerCase() === "blog") {
          return (
            formData.numArticles &&
            formData.numAuthors &&
            formData.numTags &&
            formData.wordCount
          );
        }
        return true;
      case 3:
        return (
          Object.values(selectedSections).some((value) => value) &&
          (!selectedSections.banner || formData.bannerResolution)
        );
      case 4:
        return formData.siteDomain;
      default:
        return false;
    }
  };

  // Section selection state
  const [selectedSections, setSelectedSections] = useState({
    banner: false,
    testimonials: false,
    howItWorks: false,
    features: false,
    pricing: false,
    faq: false,
    contactForm: false,
  });

  // Available sections data
  const availableSections = [
    {
      id: "banner",
      name: "Banner Section",
      description: "Hero section with main message",
    },
    {
      id: "featuredPosts",
      name: "Featured Posts",
      description: "Showcase your best or latest blog posts",
    },
    {
      id: "categoryGrid",
      name: "Category Grid",
      description: "Display blog categories in an organized grid",
    },
    {
      id: "popularPosts",
      name: "Popular Posts",
      description: "Section showing most read articles",
    },
    {
      id: "authorSpotlight",
      name: "Author Spotlight",
      description: "Highlight blog authors and contributors",
    },
    {
      id: "newsletter",
      name: "Newsletter Signup",
      description: "Email subscription form for blog updates",
    },
    {
      id: "recentPosts",
      name: "Recent Posts",
      description: "Latest blog posts in chronological order",
    },
    {
      id: "searchSection",
      name: "Search Section",
      description: "Advanced search functionality for blog content",
    },
    {
      id: "relatedPosts",
      name: "Related Posts",
      description: "Suggested posts based on current content",
    },
    {
      id: "testimonials",
      name: "Testimonials",
      description: "Reader reviews and feedback",
    },
    {
      id: "socialFeed",
      name: "Social Media Feed",
      description: "Integration with social media platforms",
    },
    {
      id: "contactForm",
      name: "Contact Form",
      description: "Get in touch section for readers",
    },
    {
      id: "subscriptionPlans",
      name: "Subscription Plans",
      description: "Premium content subscription options",
    },
    {
      id: "readingList",
      name: "Reading List",
      description: "Curated list of recommended posts",
    },

    {
      id: "commentSection",
      name: "Comment Section",
      description: "Reader discussion and engagement area",
    },
  ];

  // Banner resolutions
  const bannerResolutions = [
    { label: "Wide Resolutions (Landscape)", disabled: true },
    { label: "256x512 (Wide - Small)", value: "256x512" },
    { label: "512x1024 (Wide - Medium)", value: "512x1024" },
    { label: "720x1280 (Wide - Large)", value: "720x1280" },
    { label: "Square Resolutions", disabled: true },
    { label: "128x128 (Square - Small)", value: "128x128" },
    { label: "256x256 (Square - Medium)", value: "256x256" },
    { label: "512x512 (Square - Large)", value: "512x512" },
    { label: "Tall Resolution", disabled: true },
    { label: "512x256 (Tall - Small)", value: "512x256" },
    { label: "1024x512 (Tall - Medium)", value: "1024x512" },
    { label: "1280x720 (Tall - Large)", value: "1280x720" },
  ];

  const [headingAnimation] = useSpring(() => ({
    from: { opacity: 0, transform: "translateY(-20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { tension: 200, friction: 20 },
  }));

  const cardAnimation = useSpring({
    from: { transform: "translateY(20px)", opacity: 0 },
    to: { transform: "translateY(0)", opacity: 1 },
    config: { tension: 170, friction: 26 },
  });

  // Add this function before the renderStepContent
  const handleSectionToggle = (sectionId) => {
    setSelectedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));

    // Update the numSections count in formData
    setFormData((prev) => ({
      ...prev,
      numSections: Object.values({
        ...selectedSections,
        [sectionId]: !selectedSections[sectionId],
      }).filter(Boolean).length,
    }));
  };

  // Render step content
  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1Basic
            formData={formData}
            setFormData={setFormData}
            industries={industries}
            templates={templates}
            isLoading={isLoading}
          />
        );
      case 2:
        return (
          <Step2Content
            formData={formData}
            setFormData={setFormData}
            isBlog={formData.industry.toLowerCase() === "blog"}
          />
        );
      case 3:
        return (
          <Step3Sections
            selectedSections={selectedSections}
            handleSectionToggle={handleSectionToggle}
            availableSections={availableSections}
            bannerResolutions={bannerResolutions}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 4:
        return <Step4Domain formData={formData} setFormData={setFormData} />;
      default:
        return null;
    }
  };

  /* ----------------------------- Render ---------------------------------- */
  // Manual Setup
  if (!chat) {
    return (
      <div className="flex flex-col items-center w-full">
        <Toaster />
        {siteCreated && (
          <div
            className="h-full w-full fixed top-0 left-0 bg-gray-400/50 dark:bg-gray-950/50 
                          flex items-center justify-center z-50"
          >
            <div
              className="bg-white dark:bg-gray-800 p-10 shadow-xl rounded-xl text-center w-5/12 
                            flex flex-col items-center gap-5"
            >
              <CircleCheck className="w-16 text-green-500" />
              <h1 className="font-semibold">Congratulations</h1>
              <p className="text-lg">Your Project is ready.</p>
              <div className="flex items-center justify-center gap-2">
                <button onClick={manageProject} className="btnPrimary">
                  Manage Project
                </button>
                <button onClick={manageDomains} className="btnWhite">
                  Connect Domain
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Main Layout for manual setup */}
        <div className="grid grid-cols-1 md:grid-cols-newSiteManual w-full">
          {/* Left Side - Project Form */}
          <div className="border-r dark:border-white/20 py-8 px-10 bg-white dark:bg-gray-900">
            <Breadcrumbs />
            <button
              onClick={() => setChat(true)}
              className="flex items-center justify-center mt-12 gap-2 py-2.5 px-4 rounded-full 
                         bg-gradient-to-r from-orange-500 to-primary text-white w-full shadow-lg 
                         hover:shadow-xl transition-all duration-300 relative overflow-hidden group"
            >
              <Stars className="h-4 w-4 group-hover:rotate-180 transition-transform duration-500" />
              <span className="relative z-10">Create With AI</span>
              <div className="absolute inset-0 bg-white/10 group-hover:bg-white/20 transition-colors duration-300" />
            </button>

            <div className="flex items-center gap-4 my-10">
              <div className="h-px bg-gray-200 dark:bg-white/20 flex-1"></div>
              <p className="text-gray-500 dark:text-white/70 text-sm font-medium">
                or
              </p>
              <div className="h-px bg-gray-200 dark:bg-white/20 flex-1"></div>
            </div>

            <h2 className="text-3xl font-bold capitalize mb-1">
              Create New Project
            </h2>
            <p className="text-gray-500">
              To deploy a new Project, import an existing Git Repository or get
              started with one of our Templates.
            </p>

            <div className="flex flex-col max-w-screen-sm mt-8 gap-5">
              <InputField
                label="What do you want to call this project?"
                placeholder="Enter project name"
                value={project_name}
                onChange={(e) => setProjectName(e.target.value)}
              />
              <ListBox
                label="Select Project Type"
                name="industry"
                options={industries?.map((ind) => ({
                  _id: ind._id,
                  name: ind.industry_name,
                }))}
                selectedOption={industry}
                setSelectedOption={setIndustry}
                placeholder="Select"
              />
              <button
                disabled={!selectedThemeId}
                onClick={(e) => handleCreateProject(e, selectedThemeId)}
                className="btnPrimary py-3 px-6 mt-3 w-fit ml-auto bg-gradient-to-r from-orange-500 to-primary"
              >
                Create Project <ArrowRight className="w-4 h-4" />
              </button>
            </div>
          </div>

          {/* Right Side - Template Selection */}
          <div className="bg-gray-50 dark:bg-gray-800 h-[calc(100vh-3rem)] overflow-y-scroll">
            {industry ? (
              <div className="px-10">
                <h3 className="font-semibold capitalize mt-7 text-lg md:text-2xl">
                  Select a {industry?.name} template for your project
                </h3>
                {isLoading ? (
                  <div className="flex flex-col items-center w-full p-52 gap-5">
                    <LoaderCircle className="h-12 w-12 animate-spin text-primary" />
                    <p>Loading Templates...</p>
                  </div>
                ) : (
                  <div className="mt-5 w-full">
                    {templates?.length > 0 ? (
                      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 mt-5">
                        {templates.map((template, index) => (
                          <SelectTemplate
                            key={index}
                            themeId={template._id}
                            image={`${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template?._id}/thumb/${template.thumbnail}`}
                            title={template.template_name}
                            demoURL={template?.url}
                            selectedValue={selectedThemeId}
                            setSelectedValue={setSelectedThemeId}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="text-center text-gray-500 py-44">
                        No templates found
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <img
                src="/onboarding.webp"
                className="h-[calc(100vh-5rem)] ml-auto mr-auto"
                alt="Onboarding"
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  // AI Setup
  return (
    <div className="w-full">
      <div
        className="relative flex flex-col items-center justify-between p-10 w-full min-h-[calc(100vh-3.5rem)] bg-cover bg-center"
        style={{ backgroundImage: "url('/newProjectBg.jpg')" }}
      >
        <div className="absolute inset-0 bg-gray-100/75 dark:bg-gray-900/50" />

        <div className="max-w-2xl mx-auto w-full">
          <animated.div style={headingAnimation} className="text-center mb-8">
            <h1 className="text-4xl font-bold mb-3 text-gray-800 dark:text-white">
              AI-Powered Site Creator
            </h1>
            <p className="text-gray-600 dark:text-gray-300">
              Let's build your perfect website together, one step at a time
            </p>
          </animated.div>

          {/* Setup Type Toggle */}
          <animated.div style={cardAnimation}>
            {/* Steps Container with White Background */}
            <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-md p-4 mb-5">
              <div className="relative px-2">
                {/* Progress Bar */}
                <div className="w-full bg-gray-200 dark:bg-gray-700 h-1 absolute top-5 left-0">
                  <div
                    className="h-full bg-primary transition-all duration-300"
                    style={{
                      width: `${
                        ((currentStep - 1) / (stepNames.length - 1)) * 100
                      }%`,
                    }}
                  />
                </div>

                {/* Steps */}
                <div className="flex justify-between relative">
                  {stepNames.map((name, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center relative"
                    >
                      <div
                        className={`
                          w-10 h-10 rounded-full flex items-center justify-center 
                          transition-all duration-300 mb-2
                          ${
                            index + 1 <= currentStep
                              ? "bg-primary text-white shadow-lg shadow-primary/30"
                              : "bg-gray-100 dark:bg-gray-700 border-2 border-gray-200 dark:border-gray-600 text-gray-400"
                          }
                        `}
                      >
                        {index + 1 < currentStep ? (
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        ) : (
                          <span className="font-medium">{index + 1}</span>
                        )}
                      </div>
                      <span
                        className={`
                          text-sm font-medium transition-colors duration-300
                          ${
                            index + 1 <= currentStep
                              ? "text-primary"
                              : "text-gray-400 dark:text-gray-500"
                          }
                        `}
                      >
                        {name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
              {/* Step Content */}
              <div>{renderStepContent()}</div>
              {/* Navigation Buttons */}
              <div className="flex justify-between mt-6">
                <button
                  onClick={() => setCurrentStep(currentStep - 1)}
                  className={`px-6 py-2 rounded-lg font-medium
                  ${
                    currentStep === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                  }`}
                  disabled={currentStep === 1}
                >
                  Back
                </button>
                <button
                  onClick={
                    currentStep === totalSteps ? handleExecute : handleNext
                  }
                  disabled={!isStepValid(currentStep)}
                  className={`px-6 py-2 rounded-lg font-medium
                  ${
                    isStepValid(currentStep)
                      ? "bg-primary text-white hover:bg-primary/90"
                      : "bg-gray-200 text-gray-400 cursor-not-allowed"
                  }`}
                >
                  {currentStep === totalSteps ? "Create Website" : "Next"}
                </button>
              </div>
            </div>

            <div className="flex items-center justify-center">
              <button
                onClick={() => setChat(false)}
                className="mt-5 text-primary font-medium"
              >
                Switch to Manual Setup
              </button>
            </div>
          </animated.div>
        </div>
      </div>
    </div>
  );
}
