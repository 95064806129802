import React, { useEffect, useState, useCallback } from "react";
import toast, { Toaster } from "react-hot-toast";
import useApi from "../../../../../utils/useApi";
import { useProjectContext } from "../../ProjectContext";
import SectionHead from "../SectionHead";

export default function LayoutStyles({
  setIsCustomizing,
  isCustomizing,
  open,
}) {
  const { request } = useApi();
  const { project_id, refreshIframe } = useProjectContext();

  const [styles, setStyles] = useState({
    nav_type: { active: "", types: [] },
    blog_type: { active: "", types: [] },
    footer_type: { active: "", types: [] },
  });
  const [isUpdating, setUpdating] = useState(false);
  const [showOptions, setShowOptions] = useState(null);

  const fetchStyleData = useCallback(
    async (key) => {
      try {
        const res = await request({
          method: "get",
          url: `projects/${project_id}/data/${key}`,
        });

        if (res?.data[0]?.value) {
          setStyles((prevStyles) => ({
            ...prevStyles,
            [key]: res.data[0].value,
          }));
        } else {
          throw new Error("Data not found");
        }
      } catch (err) {
        console.error(`Failed to load ${key}:`, err);
        toast.error(`Failed to load ${key.replace("_", " ")} styles.`);
      }
    },
    [project_id, request]
  );

  useEffect(() => {
    if (isCustomizing === "layout_styles") {
      ["nav_type", "blog_type", "footer_type"].forEach(fetchStyleData);
    }
  }, [project_id, isCustomizing]);

  const updateStyle = async (event, styleType, selectedStyle) => {
    event.preventDefault();
    setUpdating(true);

    const updatedStyles = { ...styles[styleType], active: selectedStyle };

    try {
      const formData = new FormData();
      formData.append("key", styleType);
      formData.append("value", JSON.stringify(updatedStyles));
      formData.append("value_type", "JSON");

      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success(
        `${styleType.replace("_", " ")} style updated successfully.`
      );
      setStyles((prevStyles) => ({
        ...prevStyles,
        [styleType]: updatedStyles,
      }));
      refreshIframe();
    } catch (err) {
      console.error(`Failed to update ${styleType}:`, err);
      toast.error(
        err.response?.data?.message ||
          `Error updating ${styleType.replace("_", " ")}.`
      );
    }

    setUpdating(false);
  };

  const renderStyleOptions = (styleType) => {
    const { active, types } = styles[styleType] || {};

    if (!types || types.length === 0) {
      return (
        <div className="px-4 py-3">
          <p className="text-gray-500 italic text-sm">No styles available.</p>
        </div>
      );
    }

    return (
      <div className="p-3 space-y-2">
        {types.map((item, index) => (
          <button
            key={index}
            onClick={(e) => updateStyle(e, styleType, item)}
            className={`w-full px-4 py-2 text-left text-sm transition-all duration-200
              rounded-lg flex items-center justify-between
              ${
                item === active
                  ? "bg-primary text-white hover:bg-primary/90"
                  : "hover:bg-gray-100 dark:hover:bg-gray-800"
              }`}
            disabled={isUpdating}
          >
            <span className="capitalize">{item.replaceAll("_", " ")}</span>
            {item === active && (
              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div
      className={`z-50 shadow-r shadow-black/15 bg-white dark:bg-gray-900 absolute transparent-scrollbar
      transition-all duration-500 ease-in-out
      ${open && "left-64"}
      ${
        isCustomizing === "layout_styles"
          ? "w-96 opacity-100 h-[calc(100vh-6rem)] top-0 overflow-y-scroll"
          : "w-0 opacity-0 h-0 bottom-0 overflow-hidden"
      }
      border-r border-gray-200 dark:border-gray-800`}
    >
      <SectionHead title="Layout Styles" setIsCustomizing={setIsCustomizing} />
      <Toaster />

      <div className="p-5 space-y-3">
        {["nav_type", "blog_type", "footer_type"].map((styleType) => (
          <div
            key={styleType}
            className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden"
          >
            <button
              onClick={() =>
                setShowOptions(showOptions === styleType ? null : styleType)
              }
              className={`w-full px-4 py-3 flex items-center justify-between text-left
                transition-colors duration-200
                ${
                  showOptions === styleType
                    ? "bg-gray-100 dark:bg-gray-800"
                    : "hover:bg-gray-50 dark:hover:bg-gray-800/50"
                }`}
            >
              <span className="font-medium">
                {styleType.split("_")[0].charAt(0).toUpperCase() +
                  styleType.split("_")[0].slice(1)}{" "}
                Styles
              </span>
              <svg
                className={`w-5 h-5 transition-transform duration-200
                  ${showOptions === styleType ? "rotate-180" : ""}`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {showOptions === styleType && renderStyleOptions(styleType)}
          </div>
        ))}
      </div>
    </div>
  );
}
