import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import {
  BadgeCheck,
  CheckCheck,
  ChevronUp,
  Copy,
  Edit,
  Layers3,
  LoaderCircle,
  Trash,
} from "lucide-react";
import toast from "react-hot-toast";
import { CheckBox, ComboBox, InputField, Modal } from "../../components";
import useApi from "../../utils/useApi";
import usePermissions from "../../utils/userPermission";
import SelectTemplate from "../ProjectManager/NewProject/SelectTemplate";
import { useNavigate } from "react-router-dom";

export default function DomainCard({
  index,
  handleUpdate,
  setDelete,
  deleteRef,
  item,
  deleteD,
  deleteDomain,
  isOpen,
  setIsOpen,
  projects,
  industries,
  isSelected,
  onSingleSelect,
  getDomains,
  deleting,
  showSelect,
}) {
  const [isCopied, setIsCopied] = useState(null);

  const copyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(index);
        setTimeout(() => {
          setIsCopied(null);
        }, 2000);
        toast.success("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy to clipboard");
      });
  };

  const renderConfirmationDialog = () => (
    <div
      ref={(el) => (deleteRef.current[index] = el)}
      className="p-5 absolute w-72 ml-5 rounded-md shadow-2xl shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
    >
      <p className="font-medium text-gray-700 dark:text-gray-300">
        Are you sure you want to delete this domain?
      </p>
      <div className="flex items-center justify-end mt-4">
        <button
          onClick={() => setDelete(null)}
          className="px-6 py-1 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 transition"
        >
          Cancel
        </button>
        <button
          onClick={(e) => deleteDomain(e, item)}
          className="btnPrimary py-1 px-3 bg-red-500"
        >
          {deleting ? (
            <LoaderCircle className="w-4 h-4 animate-spin" />
          ) : (
            <Trash className="w-4 h-4" />
          )}
          {deleting ? <p>Deleting</p> : <p>Delete</p>}
        </button>
      </div>
    </div>
  );

  const renderDetailItem = (label, value, className) => (
    <p className={className}>
      <b className="text-gray-600 dark:text-white">{label}:</b> {value}
    </p>
  );

  const renderNameServers = () =>
    item?.cloudflair_details?.name_servers.map((ns, idx) => (
      <div className="flex items-center gap-2" key={idx}>
        {isCopied === idx ? (
          <CheckCheck className="w-4 text-green-500" />
        ) : (
          <Copy
            className="w-4 cursor-pointer text-gray-500 dark:text-white/80 hover:text-primary transition"
            onClick={() => copyToClipboard(ns, idx)}
          />
        )}
        <p className="flex-1 text-gray-700 dark:text-gray-300">{ns}</p>
      </div>
    ));

  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const [selectedProject, setSelectedProject] = useState({});
  const { request, isLoading } = useApi();
  const connectProject = async (e) => {
    e.preventDefault();
    try {
      await request({
        method: "post",
        url: `projects/${selectedProject?._id}/connect_domain`,
        data: { domain_id: item._id },
      });
      toast.success("Your Domain Is Connected");
      handleModal();
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }
  };

  const { hasPermission } = usePermissions();
  const [createNewProject, setCreateNewProject] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [industry, setIndustry] = useState(null);
  const [templates, setTemplates] = useState([]);
  function cleanDomain(domain) {
    const cleanedDomain = domain.replace(/\.[a-z]{2,}$/, "");
    return cleanedDomain.replace(/-/g, " ");
  }
  const [project_name, setProjectName] = useState(cleanDomain(item.domain));
  const [error, setError] = useState("");

  const hasInvalidExtension = (domain) => {
    const invalidExtensions = [".com", ".us", ".buzz", ".blog"];
    return invalidExtensions.some((ext) => domain.endsWith(ext));
  };
  const handleProjectNameChange = (e) => {
    const rawDomain = e.target.value;
    setProjectName(rawDomain);
    if (hasInvalidExtension(rawDomain)) {
      setError("Project name should not end with .com, .us, .buzz, or .ai");
    } else {
      setError("");
    }
  };

  //  Fetch Templates Data
  const getTemplates = async () => {
    if (!industry) return;
    try {
      const data = await request({
        method: "get",
        url: `industries/${industry?._id}/templates?active=1`,
      });
      setTemplates(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (industry?._id) {
      getTemplates();
    }
  }, [industry]);

  const navigate = useNavigate();
  const manageProject = (project_name) => {
    navigate(`/projects/${project_name?.toLowerCase()?.replaceAll(" ", "-")}`);
  };
  const [projectCreated, setProjectCreated] = useState(false);

  const createProject = async (e) => {
    e.preventDefault();

    try {
      const res = await request({
        method: "post",
        url: "projects",
        data: {
          project_name,
          industry_id: industry?._id,
          industry_template_id: selectedTemplate,
        },
      });
      toast.success("New Project Created");
      if (res.status) {
        try {
          await request({
            method: "post",
            url: `projects/${res.data._id}/connect_domain`,
            data: { domain_id: item._id },
          });
          toast.success("Your Domain Is Connected");
          setCreateNewProject(false);
          setProjectCreated(true);
          setSelectedTemplate(null);
          setProjectName("");
          setIndustry(null);
          handleModal();
          getDomains();
        } catch (err) {
          toast.error(
            err.response.data.message ||
              "Operation could not be performed, some error occurred."
          );
        }
      }
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }
  };

  const viewBacklinkReport = (domain) => {
    navigate(`/domains/${domain?.domain}`, {
      state: {
        domain_id: domain._id,
      },
    });
  };

  return (
    <div
      className={`bg-white dark:bg-gray-800 transition-all duration-300 ${
        isOpen
          ? "mb-5 mt-2 py-2 rounded-xl shadow-lg border border-gray-100 dark:border-white/10"
          : "last:shadow-md border-b border-gray-100 dark:border-white/10"
      }`}
    >
      <div
        className={`grid md:grid-cols-domainCard items-center px-6 py-2 ${
          isOpen ? "py-4" : "py-2"
        }`}
      >
        <div className="flex items-center flex-wrap text-sm">
          {showSelect && hasPermission("Transfer Domains") && (
            <CheckBox
              checked={isSelected(item._id)}
              onChange={(e) =>
                onSingleSelect({
                  checked: e.target.checked,
                  data: item,
                })
              }
            />
          )}
          <p className="w-9 font-bold text-gray-700 dark:text-gray-300 ml-2">
            {index}.
          </p>
          <div className="flex items-center">
            {hasPermission("edit domain") && (
              <Edit
                onClick={() => handleUpdate(item)}
                className="editIcon mr-2"
                title="Edit Domain"
              />
            )}
            {hasPermission("delete domain") && (
              <div className="relative mr-3">
                <Trash
                  onClick={() => setDelete(index)}
                  className="w-4 cursor-pointer text-red-400 hover:text-red-600 transition"
                />
                {deleteD === index && renderConfirmationDialog()}
              </div>
            )}
          </div>
          <div className="flex items-center">
            {/* <b className="text-gray-600 dark:text-white mr-1">Domain:</b>{" "} */}
            <a
              href={`http://${item.domain}`}
              target="_blank"
              rel="noreferrer"
              className="underline hover:text-primary transition-all cursor-pointer"
            >
              {item.domain}
            </a>
            <div className="pagesIcon relative">
              <Layers3 className="w-4 h-4 ml-2 cursor-pointer hover:text-secondary" />
              <div
                className={`pagesList bg-white dark:bg-gray-700 z-20 absolute top-0 left-0 p-5 rounded-lg shadow-xl flex flex-col gap-1 mt-3 min-h-20 min-w-20 ${
                  item.ahref_backlink_custom_pages?.length > 18 &&
                  "overflow-y-scroll max-h-96"
                }`}
              >
                {item.ahref_backlink_custom_pages?.length > 0 ? (
                  item.ahref_backlink_custom_pages?.map((link, index) => (
                    <p key={index} className="whitespace-nowrap">
                      {index + 1}.{" "}
                      <a
                        href={`http://${item.domain}${link}`}
                        target="_blank"
                        rel="noreferrer"
                        className="underline hover:text-primary transition-all cursor-pointer"
                      >
                        {link}
                      </a>
                    </p>
                  ))
                ) : (
                  <p className="text-gray-400 dark:text-gray-200 whitespace-nowrap">
                    No Pages To Show
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-4 text-sm my-2 md:my-0">
          <button
            onClick={() => viewBacklinkReport(item)}
            className="underline text-secondary font-medium hover:text-primary/80 transition-all"
          >
            Backlink Report
          </button>

          <div className="flex items-center gap-4">
            <span
              className={`px-2 py-1 rounded-full text-xs w-16 text-center font-medium ${
                item?.domain_status_id?.name === "Live"
                  ? "bg-green-50 text-green-700"
                  : "bg-gray-100 text-gray-700"
              }`}
            >
              {item?.domain_status_id?.name}
            </span>

            <span className="text-gray-600 dark:text-gray-300 text-xs w-24">
              {item?.niche_id?.name}
            </span>
          </div>

          <div className="flex items-center gap-1">
            <span className="text-gray-500 text-xs">Project:</span>
            {item?.project_id?.project_name ? (
              <p
                onClick={() => manageProject(item?.project_id?.project_name)}
                className="bg-green-50 text-green-600 hover:bg-green-600 hover:text-white px-1.5 py-1 rounded-md text-xs font-medium cursor-pointer transition-all flex items-center gap-1"
              >
                {item?.project_id?.project_name}
                <BadgeCheck className="w-3.5 h-3.5" />
              </p>
            ) : (
              hasPermission("connect domain") && (
                <button
                  onClick={handleModal}
                  className="btnPrimary py-1 rounded-full text-xs px-3"
                >
                  Connect Project
                </button>
              )
            )}
          </div>
        </div>

        <div className="flex items-center justify-end flex-wrap gap-3 lg:gap-5 text-sm">
          <p className="text-sm">
            {dayjs(item?.createdAt)?.format("D-MMM-YYYY")}
          </p>
          <button onClick={setIsOpen}>
            <ChevronUp
              className={`w-5 transition-transform transform text-gray-500 ${
                isOpen && "rotate-180"
              }`}
            />
          </button>
        </div>
      </div>
      <div
        className={`overflow-hidden transition-max-height duration-500 ease-in-out px-6 ${
          isOpen ? "max-h-screen py-3" : "max-h-0"
        }`}
      >
        <div className="bg-gray-50 dark:bg-gray-900/50 rounded-xl p-4">
          {" "}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            <div>
              {renderDetailItem("Price", item?.price)}
              {renderDetailItem("Buy Type", item?.buy_type_id?.name)}
              {/* {renderDetailItem("Trust Flow", item?.trust_flow)} */}
            </div>
            <div>
              {renderDetailItem("Domain Type", item?.domain_type_id?.name)}
              {renderDetailItem("Association", item?.association_id?.name)}
              {renderDetailItem("Indexed", item?.domain_indexed ? "Yes" : "No")}
            </div>
            <div>
              {renderDetailItem("Age", item?.age)}
              {renderDetailItem("Articles Count", item?.articles_count)}
              {renderDetailItem("Registrar", item?.registrar_id?.name)}
            </div>
            {/* <div>
            {renderDetailItem("Citation Flow", item?.citation_flow)}
            {renderDetailItem("Organic Kw", item?.oraganic_kw)}
          </div> */}
          </div>
        </div>

        {item?.ahref_info && (
          <>
            <h6 className="font-semibold text-secondary mb-2">
              Ahref Information
            </h6>
            <div className="bg-gray-50 dark:bg-gray-900/50 rounded-xl p-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
                {renderDetailItem(
                  "Domain Rating",
                  item?.ahref_info?.domain_rating?.domain_rating
                )}
                {renderDetailItem(
                  "ahrefs rank",
                  item?.ahref_info?.domain_rating?.ahrefs_rank
                )}
                {renderDetailItem(
                  "Live",
                  item?.ahref_info?.backlinks_stats?.live
                )}
                {renderDetailItem(
                  "All Time",
                  item?.ahref_info?.backlinks_stats?.all_time
                )}
                {renderDetailItem(
                  "live refdomains",
                  item?.ahref_info?.backlinks_stats?.live_refdomains
                )}
                {renderDetailItem(
                  "all time refdomains",
                  item?.ahref_info?.backlinks_stats?.all_time_refdomains
                )}
                {renderDetailItem("Traffic", item?.traffic)}
                {renderDetailItem("Referring Domains", item?.refering_domains)}
                {renderDetailItem("Backlinks", item?.backLinks)}
                {renderDetailItem("UR", item?.ur)}
                {renderDetailItem("DR", item?.dr)}
              </div>
            </div>
          </>
        )}

        {item?.cloudflair_account && (
          <>
            <h6 className="text-sm font-semibold text-secondary mb-2 mt-3">
              Cloudflare Details
            </h6>
            <div className="bg-gray-50 dark:bg-gray-900/50 rounded-xl p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
                <div>
                  {renderDetailItem(
                    "Auth Email",
                    item?.cloudflair_account?.auth_email
                  )}
                  {/* {renderDetailItem("Auth Key", item?.cloudflair_account?.auth_key)}
              {renderDetailItem(
                "Account Id",
                item?.cloudflair_account?.account_id
              )} */}
                  {renderDetailItem(
                    "Domain Status",
                    item?.cloudflair_details?.status
                  )}
                  {renderDetailItem("Type", item?.cloudflair_details?.type)}
                </div>
                <div>
                  {renderDetailItem("Nameservers", "")}
                  <div className="flex items-center gap-5 flex-wrap mt-1">
                    {renderNameServers()}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        style={{ maxWidth: createNewProject ? "60vw" : 500 }}
        title={<span className="text-2xl">Connect Domain To Project</span>}
      >
        {createNewProject ? (
          <button
            title="Create New Project"
            type="button"
            onClick={() => setCreateNewProject(false)}
            className="btnPrimary w-full text-base mt-4"
          >
            Select Existing Project
          </button>
        ) : (
          <>
            <ComboBox
              label="Select An Existing Project"
              placeholder="Select"
              selectedOption={selectedProject}
              setSelectedOption={setSelectedProject}
              options={projects?.map((item) => ({
                _id: item._id,
                name: item.project_name,
              }))}
              className="flex-1"
            />
            <div className="flex items-center justify-end gap-2 mt-5">
              <button
                type="button"
                onClick={handleModal}
                className="btnPrimary bg-red-100 text-red-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={connectProject}
                className="btnPrimary bg-green-600"
              >
                Connect Project
              </button>
            </div>
          </>
        )}
        <div className="flex items-center justify-center w-full gap-5 mt-4 mb-1">
          <div className="h-[1px] bg-gray-300 dark:bg-white/20 flex-1"></div>
          <p className="text-center text-gray-400 my-2">Or</p>
          <div className="h-[1px] bg-gray-300 dark:bg-white/20 flex-1"></div>
        </div>

        {createNewProject ? (
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-5">
              <InputField
                label="Project Name"
                placeholder="Enter project name"
                value={project_name}
                onChange={handleProjectNameChange}
                error={error}
              />
              <ComboBox
                label="Select Template Type"
                name="industry"
                options={industries?.map((industry) => ({
                  _id: industry._id,
                  name: industry.industry_name,
                }))}
                selectedOption={industry}
                setSelectedOption={setIndustry}
                placeholder="Select Project Type"
              />
            </div>
            <div>
              <h6 className="font-semibold capitalize border-b">
                Select {industry?.name} template for your website
              </h6>
              {isLoading ? (
                <div className="flex flex-col items-center w-full p-52 gap-5">
                  <LoaderCircle className="h-12 w-12 animate-spin text-primary" />
                  <p>Loading Templates...</p>
                </div>
              ) : (
                <div className="mt-2 w-full">
                  {templates?.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6 mt-5">
                      {templates.map((template, index) => (
                        <SelectTemplate
                          key={index}
                          page="domain"
                          themeId={template._id}
                          image={`${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template?._id}/thumb/${template.thumbnail}`}
                          title={template.template_name}
                          demoURL={template?.url}
                          selectedValue={selectedTemplate}
                          setSelectedValue={setSelectedTemplate}
                          template_type={template.template_type}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="text-center text-gray-500 py-44">
                      {industry?._id ? (
                        <span className="text-red-400">
                          No templates for this category.
                        </span>
                      ) : (
                        "Please select project type."
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex items-center justify-end gap-2 mt-5">
              <button
                type="button"
                onClick={handleModal}
                className="btnPrimary bg-red-100 text-red-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={createProject}
                className="btnPrimary bg-green-600"
              >
                Create & Connect
              </button>
            </div>
          </div>
        ) : (
          <button
            title="Create New Project"
            type="button"
            onClick={() => setCreateNewProject(true)}
            className="btnPrimary bg-black w-full text-base mt-4"
          >
            Create New Project
          </button>
        )}
      </Modal>
    </div>
  );
}
