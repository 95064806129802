import React, { useState } from "react";
import DarkSwitch from "../components/common/DarkSwitch";
import { InputField } from "../components";
import {
  CircleX,
  Lock,
  User,
  ArrowRight,
  Mail,
  Github,
  Loader2,
} from "lucide-react";
import useAuth from "../utils/useAuth";
import axios from "axios";
import PageSeo from "../components/common/PageSeo";
import { useSpring, animated } from "react-spring";

const Login = () => {
  const { loginUser } = useAuth();
  const [view, setView] = useState("login");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    email: "",
    confirmPassword: "",
    general: "",
  });

  const slideUp = useSpring({
    from: { transform: "translateY(30px)", opacity: 0 },
    to: { transform: "translateY(0)", opacity: 1 },
    delay: 200,
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/login`,
        {
          username: formData.username,
          password: formData.password,
        }
      );
      const { user, accessToken, refreshToken, menu_permissions } =
        res.data.data;
      loginUser(user, { accessToken, refreshToken }, menu_permissions);
      window.location.reload();
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        general: "Username or password incorrect.",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (formData.password !== formData.confirmPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match",
      }));
      setIsLoading(false);
      return;
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/signup`,
        {
          username: formData.username,
          email: formData.email,
          password: formData.password,
        }
      );
      const { user, accessToken, refreshToken, menu_permissions } =
        res.data.data;
      loginUser(user, { accessToken, refreshToken }, menu_permissions);
      window.location.reload();
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        general: "Error creating account. Please try again.",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/forgot-password`,
        {
          email: formData.email,
        }
      );
      setErrors((prev) => ({
        ...prev,
        general: "Password reset instructions sent to your email.",
      }));
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        general: "Error sending reset instructions. Please try again.",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const renderForm = () => {
    switch (view) {
      case "signup":
        return (
          <form onSubmit={handleSignup} className="space-y-6">
            <div className="space-y-4">
              <InputField
                name="username"
                label="Username"
                type="text"
                placeholder="Choose a username"
                value={formData.username}
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
                required={true}
                error={errors.username}
                inputStyle="!border-gray-200 rounded-lg !py-3 !bg-gray-50"
                labelStyle="!text-black"
              />
              <InputField
                name="email"
                label="Email"
                type="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required={true}
                error={errors.email}
                inputStyle="!border-gray-200 rounded-lg !py-3 !bg-gray-50"
                labelStyle="!text-black"
              />
              <InputField
                name="password"
                label="Password"
                type="password"
                placeholder="Create a password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                required={true}
                error={errors.password}
                inputStyle="!border-gray-200 rounded-lg !py-3 !bg-gray-50"
                labelStyle="!text-black"
              />
              <InputField
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                placeholder="Confirm your password"
                value={formData.confirmPassword}
                onChange={(e) =>
                  setFormData({ ...formData, confirmPassword: e.target.value })
                }
                required={true}
                error={errors.confirmPassword}
                inputStyle="!border-gray-200 rounded-lg !py-3 !bg-gray-50"
                labelStyle="!text-black"
              />
            </div>
            <button
              type="submit"
              className="group w-full py-3.5 px-6 rounded-xl bg-gradient-to-r from-orange-500 to-primary text-white font-medium
                hover:from-orange-600 hover:to-primary transition-all duration-300"
            >
              Create Account
              <ArrowRight className="inline-block ml-2 w-4 h-4" />
            </button>
          </form>
        );

      case "forgot":
        return (
          <form onSubmit={handleForgotPassword} className="space-y-6">
            <InputField
              name="email"
              label="Email"
              type="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              required={true}
              error={errors.email}
              inputStyle="!border-gray-200 rounded-lg !py-3 !bg-gray-50"
              labelStyle="!text-black"
            />

            {errors.general && (
              <div className="flex items-center gap-2 text-red-500 bg-red-50 rounded-xl p-3 text-sm border border-red-100">
                <CircleX className="w-4 h-4 flex-shrink-0" />
                <span>{errors.general}</span>
              </div>
            )}

            <button
              type="submit"
              disabled={isLoading}
              className="group w-full py-3.5 px-6 rounded-xl bg-gradient-to-r from-orange-500 to-primary text-white font-medium
                hover:from-orange-600 hover:to-primary transition-all duration-300"
            >
              <span className="flex items-center justify-center gap-2">
                {isLoading ? (
                  <Loader2 className="w-5 h-5 animate-spin" />
                ) : (
                  <>
                    Reset Password
                    <ArrowRight className="w-4 h-4 transition-transform group-hover:translate-x-1" />
                  </>
                )}
              </span>
            </button>

            <p className="text-center text-gray-500 text-sm mt-8">
              Remember your password?{" "}
              <button
                onClick={() => setView("login")}
                className="text-primary hover:text-red-600 ml-1 font-medium"
              >
                Back to login
              </button>
            </p>
          </form>
        );

      default:
        return (
          <form onSubmit={handleLogin} className="space-y-6">
            <div className="space-y-4">
              <div className="relative">
                <InputField
                  name="username"
                  label="Username"
                  type="username"
                  placeholder="Enter your username"
                  value={formData.username}
                  onChange={(e) =>
                    setFormData({ ...formData, username: e.target.value })
                  }
                  required={true}
                  error={errors.username}
                  inputStyle="!border-gray-200 rounded-lg !py-3 !bg-gray-50"
                  labelStyle="!text-black"
                />
              </div>

              <div className="relative">
                <InputField
                  labelStyle="!text-black"
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  required={true}
                  error={errors.password}
                  inputStyle="!border-gray-200 rounded-lg !py-3 !bg-gray-50"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <label className="flex items-center gap-2 cursor-pointer group">
                <input
                  type="checkbox"
                  className="w-4 h-4 rounded border-gray-300 text-primary focus:ring-primary
                    group-hover:border-primary transition-colors"
                />
                <span className="text-sm text-gray-500 group-hover:text-primary transition-colors">
                  Remember me
                </span>
              </label>
              <button
                type="button"
                onClick={() => setView("forgot")}
                className="text-sm text-primary hover:text-orange-600 transition-colors font-medium"
              >
                Forgot Password?
              </button>
            </div>

            {errors.general && (
              <div className="flex items-center gap-2 text-red-500 bg-red-50 rounded-xl p-3 text-sm border border-red-100">
                <CircleX className="w-4 h-4 flex-shrink-0" />
                <span>{errors.general}</span>
              </div>
            )}

            <button
              type="submit"
              disabled={isLoading}
              className="group w-full py-3.5 px-6 rounded-xl bg-gradient-to-r from-orange-500 to-primary text-white font-medium
                hover:from-orange-600 hover:to-primary transform transition-all duration-300 
                focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:outline-none
                shadow-lg shadow-red-500/30 hover:shadow-red-500/40"
            >
              <span className="flex items-center justify-center gap-2">
                {isLoading ? (
                  <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                ) : (
                  <>
                    Sign In
                    <ArrowRight className="w-4 h-4 transition-transform group-hover:translate-x-1" />
                  </>
                )}
              </span>
            </button>

            <div className="relative my-8">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-4 text-gray-500 bg-white">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <button
                type="button"
                className="flex items-center justify-center gap-2 py-3 px-4 rounded-xl border-2 border-yellow-500 
                  hover:bg-yellow-50 transition-colors text-yellow-600 font-medium"
              >
                <Mail className="w-5 h-5" />
                <span>Google</span>
              </button>
              <button
                type="button"
                className="flex items-center justify-center gap-2 py-3 px-4 rounded-xl border-2 border-blue-500
                  hover:bg-blue-50 transition-colors text-blue-600 font-medium"
              >
                <Github className="w-5 h-5" />
                <span>Github</span>
              </button>
            </div>
          </form>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-red-50 via-yellow-50 to-blue-50 flex items-center justify-center p-6">
      <PageSeo
        title={`SiteBuilderz | ${
          view === "signup"
            ? "Sign Up"
            : view === "forgot"
            ? "Reset Password"
            : "Login"
        }`}
      />

      <animated.div
        style={slideUp}
        className="w-full max-w-5xl grid grid-cols-2 bg-white rounded-3xl shadow-2xl overflow-hidden border border-red-100"
      >
        {/* Left Panel - Form */}
        <div className="relative bg-gradient-to-br from-orange-500 to-primary p-6 flex items-center justify-center">
          <div className="absolute top-6 left-6 z-20">
            <DarkSwitch bgSun="bg-red-400" />
          </div>
          <div className="absolute left-0 top-0 w-72 h-72 bg-primary/40 rounded-full -translate-x-32 -translate-y-36 "></div>

          <div className="text-center">
            <img
              src="/bannerIllustration.png"
              alt="Login illustration"
              className="w-full mx-auto mb-8 drop-shadow-2xl"
            />
            <h3 className="text-2xl font-bold text-white mb-4">
              {view === "signup"
                ? "Join Our Community"
                : view === "forgot"
                ? "Password Recovery"
                : "Site Building Automation"}
            </h3>
            <p className="text-red-100 max-w-sm mx-auto">
              Streamline your workflow with our intelligent automation platform
            </p>
          </div>

          <div className="absolute bottom-0 right-0 w-40 h-40 bg-blue-500/10 rounded-full translate-x-1/2 translate-y-1/2"></div>
        </div>

        {/* Right Panel */}
        <div className="p-12 flex flex-col justify-between relative overflow-hidden">
          <div className="relative">
            <img
              src="/logo.png"
              alt="Logo"
              className="h-16 mb-8 hover:scale-105 transition-transform duration-300"
            />

            <div className="mb-12">
              <h1 className="text-4xl font-bold mb-2 !text-black">
                {view === "signup"
                  ? "Create Account"
                  : view === "forgot"
                  ? "Reset Password"
                  : "Welcome Back!"}{" "}
                <span className="text-red-500">👋</span>
              </h1>
              <p className="text-gray-500">
                {view === "signup"
                  ? "Fill in your details to get started"
                  : view === "forgot"
                  ? "Enter your email to reset your password"
                  : "Enter your credentials to access your account"}
              </p>
            </div>

            {renderForm()}

            {view !== "forgot" && (
              <p className="text-center text-gray-500 text-sm mt-8">
                {view === "login" ? (
                  <>
                    Don't have an account?{" "}
                    <button
                      onClick={() => setView("signup")}
                      className="text-primary hover:text-red-600 ml-1 font-medium"
                    >
                      Sign up
                    </button>
                  </>
                ) : (
                  <>
                    Already have an account?{" "}
                    <button
                      onClick={() => setView("login")}
                      className="text-primary hover:text-red-600 ml-1 font-medium"
                    >
                      Sign in
                    </button>
                  </>
                )}
              </p>
            )}
          </div>

          {/* Decorative circles */}

          <div className="absolute top-0 right-0 w-52 h-52 bg-yellow-500/10 rounded-full translate-x-32 -translate-y-32"></div>
          <div className="absolute bottom-0 left-0 w-64 h-64 bg-yellow-500/10 rounded-full -translate-x-32 translate-y-32"></div>
        </div>
      </animated.div>
    </div>
  );
};

export default Login;
