import React from "react";

export default function Step3Sections({
  selectedSections,
  handleSectionToggle,
  availableSections,
  bannerResolutions,
  formData,
  setFormData,
}) {
  return (
    <div className="space-y-6">
      {/* Section Selection */}
      <div>
        <label className="block text-xl font-medium text-gray-700 dark:text-gray-300 mb-4">
          Select Sections for Landing Page
        </label>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {availableSections.map((section) => (
            <div
              key={section.id}
              className={`p-4 rounded-lg border-2 transition-all cursor-pointer
                ${
                  selectedSections[section.id]
                    ? "border-primary bg-primary/5"
                    : "border-gray-200 dark:border-gray-700 hover:border-primary/50"
                }`}
              onClick={() => handleSectionToggle(section.id)}
            >
              <div className="flex items-center justify-between">
                <h4 className="font-medium text-gray-900 dark:text-white">
                  {section.name}
                </h4>
                <input
                  type="checkbox"
                  checked={selectedSections[section.id]}
                  onChange={() => handleSectionToggle(section.id)}
                  className="w-4 h-4 text-primary"
                />
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                {section.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Banner Resolution Selection */}
      {selectedSections.banner && (
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Banner Resolution
          </label>
          <select
            value={formData.bannerResolution}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                bannerResolution: e.target.value,
              }))
            }
            className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                     dark:bg-gray-800 focus:ring-2 focus:ring-primary/20"
          >
            <option value="">Select resolution</option>
            {bannerResolutions.map((resolution, index) => (
              <option
                key={index}
                value={resolution.value}
                disabled={resolution.disabled}
              >
                {resolution.label}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}
