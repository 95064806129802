// ArticlePreview.jsx
import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { useArticleContext } from "../ArticleContext";
import MarkdownIt from "markdown-it";
import { ChevronUp, X } from "lucide-react";

const Modal = ({ isOpen, onClose, title, children }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (!isOpen) return;

    const handleEscape = (e) => {
      if (e.key === "Escape") onClose();
    };

    document.body.style.overflow = "hidden";
    window.addEventListener("keydown", handleEscape);

    return () => {
      document.body.style.overflow = "unset";
      window.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[200] overflow-y-auto" onClick={onClose}>
      <div className="fixed inset-0 bg-gray-800/50 backdrop-blur-sm" />
      <div className="flex min-h-full items-center justify-center p-4">
        <div
          ref={modalRef}
          className="relative w-full max-w-3xl rounded-lg bg-white p-6 shadow-2xl shadow-black/30 dark:bg-gray-800"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="mb-4 flex items-center justify-between">
            <h2 className="text-xl font-semibold dark:text-white">{title}</h2>
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
          <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const SUSPICIOUS_TLDS = [
  ".xyz",
  ".top",
  ".work",
  ".click",
  ".loan",
  ".win",
  ".link",
  ".bid",
  ".download",
  ".stream",
  ".gdn",
  ".racing",
  ".online",
  ".science",
];

const SeoReport = ({
  h1Count,
  totalImagesCount,
  imagesWithoutTitlesCount,
  imagesWithoutAltTextCount,
  onClose,
  article,
  wordCount,
  internalLinksCount,
  readabilityScore,
  dangerousLinksCount,
  setShowDangerousLinks,
}) => {
  const calculateSeoScore = () => {
    let score = 0;
    let totalChecks = 0;

    // Meta information checks
    if (article?.meta_title?.length >= 50 && article?.meta_title?.length <= 60)
      score++;
    if (
      article?.meta_description?.length >= 150 &&
      article?.meta_description?.length <= 160
    )
      score++;
    totalChecks += 2;

    // Content checks
    if (wordCount >= 1500) score++;
    if (h1Count === 1) score++;
    if (readabilityScore >= 60) score++;
    totalChecks += 3;

    // Links checks
    if (internalLinksCount > 0) score++;
    totalChecks += 1;

    // Image checks
    if (imagesWithoutAltTextCount === 0) score++;
    if (imagesWithoutTitlesCount === 0) score++;
    totalChecks += 2;

    // Security checks
    if (dangerousLinksCount === 0) score++;
    totalChecks += 1;

    return Math.round((score / totalChecks) * 100);
  };

  const seoScore = calculateSeoScore();

  // Get score color and emoji based on score
  const getScoreDetails = (score) => {
    if (score >= 80)
      return { color: "bg-green-500", emoji: "🎯", text: "Excellent!" };
    if (score >= 60)
      return { color: "bg-yellow-500", emoji: "👍", text: "Good" };
    return { color: "bg-red-500", emoji: "⚠️", text: "Needs Improvement" };
  };

  const scoreDetails = getScoreDetails(seoScore);

  // Get status icon based on validity
  const getStatusIcon = (isValid) => {
    return isValid ? (
      <span className="text-green-500">✓</span>
    ) : (
      <span className="text-red-500">×</span>
    );
  };

  const sections = [
    {
      title: "Meta Information",
      items: [
        {
          label: "Meta Title",
          value: article?.meta_title,
          status: {
            isValid:
              article?.meta_title?.length >= 50 &&
              article?.meta_title?.length <= 60,
            message: `${
              article?.meta_title?.length || 0
            } Characters (Recommended: 50-60)`,
          },
        },
        {
          label: "Meta Description",
          value: article?.meta_description,
          status: {
            isValid:
              article?.meta_description?.length >= 150 &&
              article?.meta_description?.length <= 160,
            message: `${
              article?.meta_description?.length || 0
            } Characters (Recommended: 150-160)`,
          },
        },
      ],
    },
    {
      title: "Content Analysis",
      items: [
        {
          label: "Word Count",
          value: `${wordCount} words`,
          status: {
            isValid: wordCount >= 1500,
            message:
              wordCount < 1500
                ? "Consider adding more content (1500+ recommended)"
                : "Good length",
          },
        },
        {
          label: "H1 Usage",
          value: h1Count,
          status: {
            isValid: h1Count === 1,
            message: h1Count !== 1 ? "Only one H1 tag recommended" : "Perfect",
          },
        },
        {
          label: "Readability Score",
          value: readabilityScore,
          status: {
            isValid: readabilityScore >= 60,
            message:
              readabilityScore < 60
                ? "Consider simplifying the content"
                : "Good readability",
          },
        },
      ],
    },
    {
      title: "Links Analysis",
      items: [
        {
          label: "Internal Links",
          value: internalLinksCount,
          status: {
            isValid: internalLinksCount > 0,
            message:
              internalLinksCount === 0
                ? "Add internal links for better SEO"
                : "Good",
          },
        },
        {
          label: "Dangerous Links",
          value: (
            <button
              onClick={() => setShowDangerousLinks(true)}
              className="text-blue-600 hover:underline focus:outline-none"
            >
              {dangerousLinksCount} (Click to view)
            </button>
          ),
          status: {
            isValid: dangerousLinksCount === 0,
            message:
              dangerousLinksCount > 0
                ? `Found ${dangerousLinksCount} potentially dangerous links`
                : "No dangerous links detected",
          },
        },
      ],
    },
    {
      title: "Image Optimization",
      items: [
        {
          label: "Total Images",
          value: totalImagesCount,
          status: {
            isValid: true,
            message: "Images help engage readers",
          },
        },
        {
          label: "Missing Alt Text",
          value: imagesWithoutAltTextCount,
          status: {
            isValid: imagesWithoutAltTextCount === 0,
            message:
              imagesWithoutAltTextCount > 0
                ? "Add alt text to all images"
                : "Perfect",
          },
        },
        {
          label: "Missing Titles",
          value: imagesWithoutTitlesCount,
          status: {
            isValid: imagesWithoutTitlesCount === 0,
            message:
              imagesWithoutTitlesCount > 0
                ? "Add titles to all images"
                : "Perfect",
          },
        },
      ],
    },
  ];

  return (
    <div className="w-full max-h-[70vh] overflow-y-auto bg-white shadow-2xl shadow-black/40 rounded-lg relative dark:bg-gray-900">
      {/* Score Header */}
      <div className="sticky top-0 bg-white z-10 border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="py-2 px-6">
          <div className="flex items-center gap-4">
            <span className="text-xl">{scoreDetails.emoji}</span>
            <h2 className="text-2xl font-bold dark:text-white">
              SEO Score: {scoreDetails.text}
            </h2>
          </div>
          <div className="flex items-center gap-3">
            <div className="text-lg font-semibold dark:text-gray-300">
              {seoScore}%
            </div>
            <div className="w-full bg-gray-200 rounded-full flex-1 dark:bg-gray-700">
              <div
                className={`${scoreDetails.color} h-2 rounded-full transition-all duration-500 ease-out`}
                style={{ width: `${seoScore}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-6">
        {sections.map((section, idx) => (
          <div key={idx} className="mb-6 last:mb-0">
            <div className="flex items-center gap-2 mb-3">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                {section.title}
              </h3>
              <div className="flex-1 border-b border-gray-200 dark:border-gray-700"></div>
            </div>

            <div className="space-y-4">
              {section.items.map((item, itemIdx) => (
                <div
                  key={itemIdx}
                  className={`p-4 rounded-lg transition-all duration-200 ${
                    item.status.isValid
                      ? "bg-green-50 dark:bg-green-900"
                      : "bg-red-50 dark:bg-red-900"
                  } hover:shadow-md`}
                >
                  <div className="flex items-start justify-between">
                    <div className="flex items-center gap-2">
                      {getStatusIcon(item.status.isValid)}
                      <span className="font-medium text-gray-700 dark:text-gray-300">
                        {item.label}
                      </span>
                    </div>
                    <span
                      className={`text-sm px-2 py-1 rounded-full ${
                        item.status.isValid
                          ? "bg-green-100 text-green-700 dark:bg-green-800 dark:text-green-300"
                          : "bg-red-100 text-red-700 dark:bg-red-800 dark:text-red-300"
                      }`}
                    >
                      {item.status.message}
                    </span>
                  </div>
                  {item.value && (
                    <p className="text-sm text-gray-600 mt-2 ml-6 dark:text-gray-400">
                      Current value: {item.value}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Update the SEO button and dropdown container
const SeoButton = ({ onClick, isOpen }) => (
  <button
    className={`btnPrimary py-2 px-4 flex items-center gap-2 transition-all duration-200 ${
      isOpen ? "bg-gray-700" : ""
    }`}
    onClick={onClick}
    aria-haspopup="true"
    aria-expanded={isOpen}
  >
    <span>SEO</span>
    <ChevronUp
      className={`w-4 h-4 transition-transform duration-200 ${
        isOpen ? "transform rotate-180" : ""
      }`}
    />
  </button>
);

// Update the useSeoDropdown hook
const useSeoDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    dropdownRef,
    buttonRef,
    toggle,
    close,
  };
};

// Update TabButton for better visual feedback
const TabButton = ({ isActive, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-6 py-3 font-medium transition-all duration-200 border-b-2 ${
      isActive
        ? "border-blue-500 text-blue-600 dark:border-blue-400 dark:text-blue-400"
        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-200 dark:hover:border-gray-600"
    }`}
  >
    {children}
  </button>
);

// Add SearchPreview component
const SearchPreview = ({ article }) => (
  <div className="p-6 space-y-6 max-h-[70vh] overflow-y-auto">
    <h3 className="text-lg font-semibold mb-4 dark:text-white">
      Google Search Preview
    </h3>

    <div className="max-w-[600px] font-arial">
      {/* Desktop Preview */}
      <div className="space-y-6">
        <div>
          <h4 className="text-sm text-gray-600 dark:text-gray-400 mb-2">
            Desktop Preview
          </h4>
          <div className="p-4 bg-white rounded-lg border dark:bg-gray-900 dark:border-gray-700">
            <div className="space-y-1">
              <div className="text-xl text-[#1a0dab] dark:text-[#8ab4f8] hover:underline cursor-pointer overflow-hidden text-ellipsis">
                {article?.meta_title || article?.title || "Untitled Article"}
              </div>
              <div className="text-sm text-[#006621] dark:text-[#0cce6b]">
                {window.location.origin}/articles/
                {article?.slug || "article-slug"}
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400 line-clamp-2">
                {article?.meta_description ||
                  "No meta description provided. Add a meta description to improve your SEO."}
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Preview */}
        <div>
          <h4 className="text-sm text-gray-600 dark:text-gray-400 mb-2">
            Mobile Preview
          </h4>
          <div className="p-4 bg-white rounded-lg border dark:bg-gray-900 dark:border-gray-700 max-w-[400px]">
            <div className="space-y-1">
              <div className="text-sm text-[#006621] dark:text-[#0cce6b]">
                {window.location.origin}/articles/
                {article?.slug || "article-slug"}
              </div>
              <div className="text-base text-[#1a0dab] dark:text-[#8ab4f8] hover:underline cursor-pointer overflow-hidden text-ellipsis">
                {article?.meta_title || article?.title || "Untitled Article"}
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400 line-clamp-2">
                {article?.meta_description ||
                  "No meta description provided. Add a meta description to improve your SEO."}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Character Count Indicators */}
      <div className="mt-8 space-y-4">
        <div>
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
              Title Length
            </span>
            <span
              className={`text-sm ${
                (article?.meta_title?.length || 0) > 60
                  ? "text-red-500"
                  : (article?.meta_title?.length || 0) < 50
                  ? "text-yellow-500"
                  : "text-green-500"
              }`}
            >
              {article?.meta_title?.length || 0}/60
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
            <div
              className={`h-2 rounded-full ${
                (article?.meta_title?.length || 0) > 60
                  ? "bg-red-500"
                  : (article?.meta_title?.length || 0) < 50
                  ? "bg-yellow-500"
                  : "bg-green-500"
              }`}
              style={{
                width: `${Math.min(
                  ((article?.meta_title?.length || 0) / 60) * 100,
                  100
                )}%`,
              }}
            ></div>
          </div>
        </div>

        <div>
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
              Description Length
            </span>
            <span
              className={`text-sm ${
                (article?.meta_description?.length || 0) > 160
                  ? "text-red-500"
                  : (article?.meta_description?.length || 0) < 150
                  ? "text-yellow-500"
                  : "text-green-500"
              }`}
            >
              {article?.meta_description?.length || 0}/160
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
            <div
              className={`h-2 rounded-full ${
                (article?.meta_description?.length || 0) > 160
                  ? "bg-red-500"
                  : (article?.meta_description?.length || 0) < 150
                  ? "bg-yellow-500"
                  : "bg-green-500"
              }`}
              style={{
                width: `${Math.min(
                  ((article?.meta_description?.length || 0) / 160) * 100,
                  100
                )}%`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

// Update SeoDropdownContent to include the new tab
const SeoDropdownContent = ({
  h1Count,
  article,
  totalImagesCount,
  imagesWithoutTitlesCount,
  imagesWithoutAltTextCount,
  wordCount,
  internalLinksCount,
  readabilityScore,
  dangerousLinksCount,
  setShowDangerousLinks,
}) => {
  const [activeTab, setActiveTab] = useState("report");

  return (
    <div className="w-full bg-white rounded-lg shadow-xl overflow-hidden dark:bg-gray-800 border dark:border-gray-700">
      <div className="border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900">
        <div className="flex">
          <TabButton
            isActive={activeTab === "report"}
            onClick={() => setActiveTab("report")}
          >
            SEO Report
          </TabButton>
          <TabButton
            isActive={activeTab === "preview"}
            onClick={() => setActiveTab("preview")}
          >
            Search Preview
          </TabButton>
          <TabButton
            isActive={activeTab === "docs"}
            onClick={() => setActiveTab("docs")}
          >
            Documentation
          </TabButton>
        </div>
      </div>

      <div className="transition-all duration-200">
        {activeTab === "report" ? (
          <SeoReport
            h1Count={h1Count}
            article={article}
            totalImagesCount={totalImagesCount}
            imagesWithoutTitlesCount={imagesWithoutTitlesCount}
            imagesWithoutAltTextCount={imagesWithoutAltTextCount}
            wordCount={wordCount}
            internalLinksCount={internalLinksCount}
            readabilityScore={readabilityScore}
            dangerousLinksCount={dangerousLinksCount}
            setShowDangerousLinks={setShowDangerousLinks}
          />
        ) : activeTab === "preview" ? (
          <SearchPreview article={article} />
        ) : (
          <div className="p-6 space-y-6  max-h-[70vh] overflow-y-auto">
            <div className="prose max-w-none dark:prose-invert">
              <h3 className="text-lg font-semibold mb-4">SEO Best Practices</h3>

              <div className="space-y-6">
                <div className="p-4 bg-blue-50 rounded-lg border border-blue-100 dark:bg-blue-900/30 dark:border-blue-800">
                  <h4 className="font-medium m-0 text-blue-800 dark:text-blue-300 mb-2">
                    Title Optimization
                  </h4>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    Ensure your title is between 50-60 characters and includes
                    your primary keyword near the beginning. Make it compelling
                    and relevant to your content.
                  </p>
                </div>

                <div className="p-4 bg-green-50 rounded-lg border border-green-100 dark:bg-green-900/30 dark:border-green-800">
                  <h4 className="font-medium m-0 text-green-800 dark:text-green-300 mb-2">
                    Meta Description
                  </h4>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    Write a compelling meta description between 150-160
                    characters that accurately summarizes your content and
                    includes your target keywords naturally.
                  </p>
                </div>

                <div className="p-4 bg-purple-50 rounded-lg border border-purple-100 dark:bg-purple-900/30 dark:border-purple-800">
                  <h4 className="font-medium m-0 text-purple-800 dark:text-purple-300 mb-2">
                    Content Structure
                  </h4>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    Use a single H1 tag for your main title. Structure your
                    content with H2-H6 tags logically. Aim for at least 1,500
                    words for comprehensive coverage.
                  </p>
                </div>

                <div className="p-4 bg-amber-50 rounded-lg border border-amber-100 dark:bg-amber-900/30 dark:border-amber-800">
                  <h4 className="font-medium m-0 text-amber-800 dark:text-amber-300 mb-2">
                    Image Optimization
                  </h4>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    Always include descriptive alt text and titles for images.
                    Optimize image file sizes without compromising quality.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default function ArticlePreview() {
  const markdownIt = useMemo(() => new MarkdownIt(), []);
  const { article, fileInfo } = useArticleContext();
  const convertMarkdown = (markdownText) => markdownIt.render(markdownText);

  // State declarations
  const [h1Count, setH1Count] = useState(0);
  const [imagesWithoutTitlesCount, setImagesWithoutTitlesCount] = useState(0);
  const [imagesWithoutAltTextCount, setImagesWithoutAltTextCount] = useState(0);
  const [totalImagesCount, setTotalImagesCount] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const [internalLinksCount, setInternalLinksCount] = useState(0);
  const [readabilityScore, setReadabilityScore] = useState(0);
  const [dangerousLinksCount, setDangerousLinksCount] = useState(0);
  const [isDocsModalOpen, setIsDocsModalOpen] = useState(false);
  const [dangerousLinksDetails, setDangerousLinksDetails] = useState([]);
  const [showDangerousLinks, setShowDangerousLinks] = useState(false);

  // SEO dropdown state management
  const {
    isOpen: isSeoDropdownOpen,
    dropdownRef: seoDropdownRef,
    buttonRef: seoButtonRef,
    toggle: toggleSeoDropdown,
    close: closeSeoDropdown,
  } = useSeoDropdown();

  // Close SEO dropdown when modals open
  useEffect(() => {
    if (showDangerousLinks || isDocsModalOpen) {
      closeSeoDropdown();
    }
  }, [showDangerousLinks, isDocsModalOpen, closeSeoDropdown]);

  const SeoButtonWithRef = (
    <div ref={seoButtonRef}>
      <SeoButton onClick={toggleSeoDropdown} isOpen={isSeoDropdownOpen} />
    </div>
  );

  // Parse and Calculate SEO Metrics
  useEffect(() => {
    const renderedContent = convertMarkdown(article?.articleContent || "");
    const doc = new DOMParser().parseFromString(renderedContent, "text/html");

    // H1 Count
    const h1Tags = doc.getElementsByTagName("h1");
    const totalH1 = h1Tags.length + (article?.title ? 1 : 0);
    setH1Count(totalH1);

    // Dangerous Links Collection
    const links = doc.getElementsByTagName("a");
    let dangerousLinks = 0;
    const dangerousLinksArray = [];
    const siteUrl = "https://api.sitebuilderz.com";

    const isExternalLink = (href) => {
      if (!href) return false;

      // Ignore anchor links and relative paths
      if (href.startsWith("#") || href.startsWith("/")) return false;

      // If it's a relative URL without leading slash, it's internal
      if (!href.includes("://") && !href.startsWith("//")) return false;

      try {
        const url = new URL(href, siteUrl);
        return url.hostname !== "api.sitebuilderz.com";
      } catch (error) {
        return !href.includes("api.sitebuilderz.com");
      }
    };

    for (let link of links) {
      const href = link.getAttribute("href") || "";
      if (!href) continue;

      if (isExternalLink(href)) {
        dangerousLinks++;
        dangerousLinksArray.push({
          url: href,
          text: link.textContent,
          type: "External Link",
        });
      }
    }

    // Images Count and Dangerous Links
    const images = doc.getElementsByTagName("img");
    let withoutTitles = 0;
    let withoutAlt = 0;
    let totalImages = 0;
    for (let img of images) {
      totalImages++;
      if (!img.title) withoutTitles++;
      if (!img.alt) withoutAlt++;
      const src = img.getAttribute("src") || "";
      if (isExternalLink(src)) {
        dangerousLinks++;
        dangerousLinksArray.push({
          url: src,
          text: img.alt || "Image",
          type: "External Image",
        });
      }
    }
    setImagesWithoutTitlesCount(withoutTitles);
    setImagesWithoutAltTextCount(withoutAlt);
    setTotalImagesCount(totalImages);

    setDangerousLinksDetails(dangerousLinksArray);
    setDangerousLinksCount(dangerousLinks);

    // Calculate word count
    const words = doc.body.textContent.trim().split(/\s+/).length;
    setWordCount(words);

    // Calculate internal links
    const isInternalLink = (href) => {
      if (!href) return false;

      try {
        // Handle empty links that just have #
        if (href.startsWith("#")) return true;

        // If it's a relative path starting with /, it's internal
        if (href.startsWith("/")) return true;

        // Parse the URL to check the domain
        const url = new URL(href, siteUrl);
        return url.hostname === "api.sitebuilderz.com";
      } catch (error) {
        // If URL is malformed but starts with the site URL, consider it internal
        return (
          href.startsWith(siteUrl) || href.includes("api.sitebuilderz.com")
        );
      }
    };

    let internal = 0;
    for (let link of links) {
      const href = link.href || "";
      if (isInternalLink(href)) {
        internal++;
      }
    }
    setInternalLinksCount(internal);

    // Simple readability score (example)
    const sentences = doc.body.textContent.split(/[.!?]+/).length;
    const avgWordsPerSentence = words / sentences;
    const readability = 100 - avgWordsPerSentence * 1.5;
    setReadabilityScore(Math.round(readability));
  }, [article?.articleContent, article?.title]);

  // Banner Styles
  const bannerStyle = useMemo(
    () => ({
      backgroundColor:
        `rgba(0, 0, 0, ${article.opacity / 100})` || `rgba(0, 0, 0, 0.09)`,
      color: article.textColor || "white",
    }),
    [article.opacity, article.textColor]
  );

  // Dangerous Links Modal Usage
  const DangerousLinksModal = ({ isOpen, onClose, links }) => (
    <Modal isOpen={isOpen} onClose={onClose} title="Dangerous Links Found">
      <div className="space-y-4">
        {links.length === 0 ? (
          <p className="text-green-600">No dangerous links found!</p>
        ) : (
          <>
            <p className="text-red-600">
              The following links are considered dangerous because they are
              external or invalid:
            </p>
            {links.map((link, index) => (
              <div
                key={index}
                className="rounded-lg border border-red-200 bg-red-50 p-4 dark:border-red-800 dark:bg-red-900/50"
              >
                <div className="flex items-start justify-between gap-4">
                  <div>
                    <p className="font-medium text-gray-700 dark:text-gray-200">
                      {link.text}
                    </p>
                    <p className="break-all text-sm text-gray-500 dark:text-gray-400">
                      {link.url}
                    </p>
                  </div>
                  <span className="rounded bg-red-100 px-2 py-1 text-xs text-red-800 dark:bg-red-800 dark:text-red-100">
                    {link.type}
                  </span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </Modal>
  );

  return (
    <div className="py-4 px-6 h-[calc(100vh-6rem)] overflow-y-scroll dark:bg-gray-900 dark:text-white">
      {/* Header Section */}
      <div className="bg-white shadow sticky top-0 z-50 rounded-md p-2.5 pl-4 dark:bg-gray-800">
        <div className="flex items-center justify-between gap-10">
          <span className="text-xl font-bold whitespace-nowrap dark:text-white">
            Article Preview
          </span>
          <div className="flex items-center justify-end gap-2">
            {SeoButtonWithRef}
          </div>
        </div>
        {isSeoDropdownOpen && (
          <div className="relative w-full">
            <div
              ref={seoDropdownRef}
              className="absolute right-0 transform transition-all w-full duration-200 ease-out max-w-[600px]"
            >
              <SeoDropdownContent
                h1Count={h1Count}
                article={article}
                totalImagesCount={totalImagesCount}
                imagesWithoutTitlesCount={imagesWithoutTitlesCount}
                imagesWithoutAltTextCount={imagesWithoutAltTextCount}
                wordCount={wordCount}
                internalLinksCount={internalLinksCount}
                readabilityScore={readabilityScore}
                dangerousLinksCount={dangerousLinksCount}
                setShowDangerousLinks={setShowDangerousLinks}
              />
            </div>
          </div>
        )}
      </div>

      <div
        style={{ backgroundImage: `url(${fileInfo?.preview})` }}
        className="relative min-h-[450px] mt-4 w-full bg-cover bg-center rounded-md overflow-hidden"
      >
        <div
          style={bannerStyle}
          className="flex flex-col items-center justify-center text-center py-16 px-12 absolute top-0 h-full w-full dark:bg-black/50"
        >
          <h1
            className="font-bold dark:text-white"
            style={{
              fontSize: article.titleFontSize,
              padding: 0,
            }}
          >
            {article?.title || "New Article Title"}
          </h1>
          <p
            className="mt-7 text-2xl font-semibold dark:text-gray-300"
            style={{
              fontSize: article.taglineFontSize,
              padding: 0,
            }}
          >
            {article?.tagline || "New article tagline"}
          </p>
          <div className="flex items-center justify-center gap-10 text-sm mt-10 dark:text-gray-400">
            <p>
              By{" "}
              <span className="underline">
                {article?.author || "author name"}
              </span>
            </p>
            <p>PUBLISHED: {article?.published_at}</p>
          </div>
        </div>
      </div>

      <p className="text-end text-sm py-2 text-gray-500 dark:text-gray-400">
        {article?.imageTitle || "image title"}
      </p>

      <div
        className="prose mt-5 max-w-full dark:text-white/80"
        dangerouslySetInnerHTML={{
          __html: convertMarkdown(
            article?.articleContent || "Article content here."
          ),
        }}
      />

      <DangerousLinksModal
        isOpen={showDangerousLinks}
        onClose={() => setShowDangerousLinks(false)}
        links={dangerousLinksDetails}
      />
    </div>
  );
}
