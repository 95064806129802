const CircularProgressBar = ({ percentage = 0, className, showLabel }) => {
  const getColor = () => {
    if (percentage === 100) return "text-green-500";
    return "text-primary";
  };

  const getLabel = () => {
    if (percentage === 100) return "Perfect";
    return "In Complete";
  };

  const radius = 16;
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = `${
    (percentage / 100) * circumference
  } ${circumference}`;

  const color = getColor();
  const label = getLabel();

  return (
    <div
      className={`bg-white dark:bg-gray-900 p-1 rounded-xl relative flex items-center w-fit gap-2 m-1 pr-2 ${className}`}
    >
      <div className="relative w-11 h-11">
        <svg
          className="rotate-90 w-full h-full"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Background Circle */}
          <circle
            cx="18"
            cy="18"
            r={radius}
            fill="none"
            className="stroke-current text-gray-300 dark:text-gray-700"
            strokeWidth="2"
            strokeDasharray={`${circumference} ${circumference}`}
          ></circle>

          {/* Progress Circle */}
          <circle
            cx="18"
            cy="18"
            r={radius}
            fill="none"
            className={`stroke-current ${color}`}
            strokeWidth="4"
            strokeDasharray={strokeDasharray}
            strokeLinecap="round"
            style={{
              transformOrigin: "center",
              transform: "rotate(-90deg)", // Align starting point at the top
            }}
          ></circle>
        </svg>

        {/* Percentage Display */}
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <span className={`font-bold text-xs ${color}`}>{percentage}%</span>
        </div>
      </div>

      {showLabel && <span className={`font-medium ${color}`}>{label}</span>}
    </div>
  );
};

export default CircularProgressBar;
