import React, { useEffect, useState, useRef, forwardRef } from "react";
import useApi from "../../../../utils/useApi";
import { useProjectContext } from "../ProjectContext";
import toast, { Toaster } from "react-hot-toast";
import "react-markdown-editor-lite/lib/index.css";
import {
  Check,
  Stars,
  Trash,
  X,
  FileText,
  FileX,
  Sparkles,
  Building,
  Image,
  Type,
  Upload,
  Layout,
  Plus,
  ChevronLeft,
  ChevronRight,
  ExternalLink,
} from "lucide-react";
import SwitchToggle from "../../../../components/common/SwitchToggle";
import InputField from "../../../../components/common/InputField";

// Internal Button component
const Button = forwardRef(({ className, children, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={`inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background ${className}`}
      {...props}
    >
      {children}
    </button>
  );
});

// Internal ImageUploader component
const ImageUploader = ({ onChange, accept, className }) => {
  const inputRef = useRef(null);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onChange(file);
    }
  };

  return (
    <div
      className={`flex items-center justify-center w-full ${className}`}
      onClick={() => inputRef.current.click()}
    >
      <input
        type="file"
        accept={accept}
        ref={inputRef}
        onChange={handleChange}
        className="hidden"
      />
      <p className="text-gray-400">Click to upload</p>
    </div>
  );
};

export default function AIContentManager({ sidebar }) {
  const { request } = useApi();
  const { project_id } = useProjectContext();
  const [isGenerating, setIsGenerating] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [formData, setFormData] = useState({
    categories: [],
    logo: {
      type: null,
      value: null,
      settings: {
        fontFamily: "inter",
        color: "#000000",
        maxWidth: "200px",
        altText: "",
        alignment: "left",
      },
    },
    banner: null,
  });
  const [totalArticles, setTotalArticles] = useState({});
  const [publishArticles, setPublishArticles] = useState({});
  const [scheduleArticles, setScheduleArticles] = useState({});
  const [layout, setLayout] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [industryName, setIndustryName] = useState("");
  const [logoType, setLogoType] = useState("image"); // 'image' or 'text'
  const [textLogo, setTextLogo] = useState("");
  const [bannerType, setBannerType] = useState("image"); // 'image' or 'ai'
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [scheduleFrequency, setScheduleFrequency] = useState(1);
  const [scheduleInterval, setScheduleInterval] = useState("week");

  // Define steps
  const steps = [
    { id: 1, title: "Welcome", description: "Let's set up your new website" },
    {
      id: 2,
      title: "Site Configuration",
      description: "Configure your site's basic settings",
    },
    { id: 3, title: "Pages", description: "Choose which pages to include" },
    { id: 4, title: "Content", description: "Configure your blog content" },
    { id: 5, title: "Review", description: "Review and generate content" },
  ];

  const { projectToManage } = useProjectContext();

  useEffect(() => {
    if (project_id) {
      fetchSections();
    }
  }, [project_id]);

  const fetchSections = async () => {
    setLoading(true);
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/layout`,
      });
      setLayout(res.data[0].value);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const handleEnabledPage = async (index) => {
    const updatedSections = layout.map((page, i) =>
      i === index ? { ...page, enable: !page.enable } : page
    );

    try {
      const formData = new FormData();
      formData.append("key", "layout");
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(updatedSections));

      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      setLayout(updatedSections);
      toast.success(
        updatedSections[index].enable ? "Page enabled" : "Page disabled"
      );
    } catch (error) {
      toast.error("Failed to update page status");
      console.error(error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (newCategory.trim()) {
        if (!formData.categories.includes(newCategory.trim())) {
          setFormData((prev) => ({
            ...prev,
            categories: [...prev.categories, newCategory.trim()],
          }));
          setTotalArticles((prev) => ({
            ...prev,
            [newCategory.trim()]: 1, // Set initial total to 1
          }));
          setPublishArticles((prev) => ({
            ...prev,
            [newCategory.trim()]: 0, // Changed: Set initial publish to 0
          }));
          setScheduleArticles((prev) => ({
            ...prev,
            [newCategory.trim()]: 0, // Changed: Set initial schedule to 0
          }));
        }
        setNewCategory("");
      }
    }
  };

  // Update the same in the button click handler
  const handleCategoryAdd = () => {
    if (newCategory.trim()) {
      if (!formData.categories.includes(newCategory.trim())) {
        setFormData((prev) => ({
          ...prev,
          categories: [...prev.categories, newCategory.trim()],
        }));
        setTotalArticles((prev) => ({
          ...prev,
          [newCategory.trim()]: 1, // Set initial total to 1
        }));
        setPublishArticles((prev) => ({
          ...prev,
          [newCategory.trim()]: 0, // Changed: Set initial publish to 0
        }));
        setScheduleArticles((prev) => ({
          ...prev,
          [newCategory.trim()]: 0, // Changed: Set initial schedule to 0
        }));
      }
      setNewCategory("");
    }
  };

  const removeCategory = (categoryToRemove) => {
    setFormData((prev) => ({
      ...prev,
      categories: prev.categories.filter((cat) => cat !== categoryToRemove),
    }));
    setTotalArticles((prev) => {
      const { [categoryToRemove]: _, ...rest } = prev;
      return rest;
    });
    setPublishArticles((prev) => {
      const { [categoryToRemove]: _, ...rest } = prev;
      return rest;
    });
    setScheduleArticles((prev) => {
      const { [categoryToRemove]: _, ...rest } = prev;
      return rest;
    });
  };

  const handleImageUpload = (type, file) => {
    if (!file) {
      setFormData((prev) => ({
        ...prev,
        [type]: null,
      }));
      return;
    }

    // Check file size (100KB = 100 * 1024 bytes)
    if (file.size > 100 * 1024) {
      toast.error("Image size must be less than 100KB");
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [type]: file,
    }));
  };

  const handleArticleCountChange = (category, type, value) => {
    const numValue = parseInt(value) || 0;
    const total = totalArticles[category] || 0;

    switch (type) {
      case "total":
        // When total is changed, ensure publish and schedule don't exceed new total
        const newTotal = Math.max(numValue, 1); // Ensure minimum of 1
        setTotalArticles((prev) => ({
          ...prev,
          [category]: newTotal,
        }));

        // Adjust publish and schedule if they exceed new total
        if ((publishArticles[category] || 0) > newTotal) {
          setPublishArticles((prev) => ({
            ...prev,
            [category]: newTotal,
          }));
        }
        if ((scheduleArticles[category] || 0) > newTotal) {
          setScheduleArticles((prev) => ({
            ...prev,
            [category]: 0,
          }));
        }
        break;

      case "publish":
        const currentScheduled = scheduleArticles[category] || 0;
        // Ensure publish + scheduled doesn't exceed total
        const maxPublish = total - currentScheduled;
        const validPublish = Math.min(Math.max(numValue, 0), maxPublish);

        setPublishArticles((prev) => ({
          ...prev,
          [category]: validPublish,
        }));
        break;
    }
  };

  const handleOpenScheduleModal = (category) => {
    setSelectedCategory(category);
    setIsScheduleModalOpen(true);
  };

  const handleCreateSchedule = () => {
    const scheduledCount = parseInt(scheduleFrequency);
    const currentPublished = publishArticles[selectedCategory] || 0;
    const currentScheduled = scheduleArticles[selectedCategory] || 0;
    const total = totalArticles[selectedCategory] || 0;

    // Calculate maximum allowed new schedules
    const maxNewSchedules = total - currentPublished - currentScheduled;

    if (scheduledCount > maxNewSchedules) {
      toast.error(
        `Can only schedule up to ${maxNewSchedules} more articles for this category`
      );
      return;
    }

    // Update the scheduled articles count
    setScheduleArticles((prev) => ({
      ...prev,
      [selectedCategory]: (prev[selectedCategory] || 0) + scheduledCount,
    }));

    // Close the modal
    setIsScheduleModalOpen(false);
    setSelectedCategory(null);

    // Show success message
    toast.success(
      `Successfully scheduled ${scheduledCount} articles for ${selectedCategory}`
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsGenerating(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("project_id", project_id);
      formDataToSend.append("categories", formData.categories);
      formDataToSend.append(
        "articleCounts",
        JSON.stringify(formData.articleCounts)
      );
      if (formData.logo) formDataToSend.append("logo", formData.logo);
      if (formData.banner) formDataToSend.append("banner", formData.banner);

      const response = await request("/api/generate-content", {
        method: "POST",
        body: formDataToSend,
      });

      if (response.success) {
        toast.success("Content generated successfully!");
      } else {
        toast.error(response.message || "Failed to generate content");
      }
    } catch (error) {
      toast.error("An error occurred while generating content");
      console.error(error);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div
      className={`overflow-y-scroll dark:bg-gray-900 py-4 flex flex-col ${
        sidebar
          ? "h-[calc(100vh-2.5rem)] w-[650px] px-6"
          : "h-[calc(100vh-6rem)] pt-8"
      }`}
    >
      <Toaster />
      <div className="max-w-4xl mx-auto mb-5 w-full">
        {/* Enhanced progress bar */}
        <div className="mb-10 relative">
          <div className="absolute top-4 w-full h-0.5 bg-gray-200 dark:bg-gray-700">
            <div
              className="h-full bg-primary transition-all duration-500 ease-in-out"
              style={{
                width: `${((currentStep - 1) / (steps.length - 1)) * 100}%`,
              }}
            />
          </div>
          <div className="relative flex justify-between items-center gap-2">
            {steps.map((step) => (
              <div key={step.id} className="flex flex-col items-center">
                <div
                  onClick={() => setCurrentStep(step.id)}
                  className={`w-10 h-10 rounded-full cursor-pointer flex items-center justify-center transition-all duration-300 ${
                    currentStep === step.id
                      ? "bg-primary text-white shadow-lg shadow-primary/30 scale-110"
                      : currentStep > step.id
                      ? "bg-green-500 text-white"
                      : "bg-white dark:bg-gray-800 text-gray-500 border-2 border-gray-200 dark:border-gray-700"
                  }`}
                >
                  {currentStep > step.id ? (
                    <Check className="w-6 h-6" />
                  ) : (
                    step.id
                  )}
                </div>
                <p className="text-sm mt-2 font-medium">{step.title}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400 text-center">
                  {step.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Welcome Screen */}
        {currentStep === 1 && (
          <div className="dark:border-gray-700 py-10 space-y-12">
            {/* Hero Section */}
            <div className="text-center space-y-3">
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-r from-primary/20 to-purple-600/20 blur-3xl rounded-full"></div>
                <div className="relative z-10 space-y-4">
                  <h1 className="text-4xl flex items-center gap-2 justify-center font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-purple-600">
                    <Stars className="w-10 h-10 text-primary animate-pulse" />
                    Sitebuilderz AI Content Generator
                  </h1>
                </div>
              </div>
              <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto text-lg leading-relaxed">
                Let's create amazing content for your website! We'll guide you
                through the process of setting up your site structure and
                generating AI-powered content.
              </p>
            </div>

            {/* Project Details Card */}
            <div className="max-w-3xl mx-auto">
              <div className="bg-white dark:bg-gray-800/50 backdrop-blur-xl rounded-2xl border border-gray-200 dark:border-gray-700 overflow-hidden">
                {/* Card Header */}
                <div className="bg-gradient-to-r from-gray-50 to-gray-100 dark:from-gray-700/50 dark:to-gray-800/50 px-6 py-4 border-b border-gray-200 dark:border-gray-700">
                  <h3 className="text-xl font-semibold flex items-center gap-3">
                    <Building className="w-5 h-5 text-primary" />
                    Current Project Details
                  </h3>
                </div>

                {/* Card Content */}
                <div className="p-6">
                  <div className="grid md:grid-cols-2 gap-4">
                    {/* Project Name */}
                    <div className="bg-gray-50 dark:bg-gray-700/30 p-4 rounded-xl border border-gray-200 dark:border-gray-600">
                      <label className="text-sm font-medium text-gray-500 dark:text-gray-400 block mb-1">
                        Project Name
                      </label>
                      <p className="font-semibold text-lg text-gray-900 dark:text-gray-100">
                        {projectToManage?.project_name || "Untitled Project"}
                      </p>
                    </div>

                    {/* Template */}
                    <div className="bg-gray-50 dark:bg-gray-700/30 p-4 rounded-xl border border-gray-200 dark:border-gray-600">
                      <label className="text-sm font-medium text-gray-500 dark:text-gray-400 block mb-1">
                        Template
                      </label>
                      <p className="font-semibold text-lg text-gray-900 dark:text-gray-100">
                        {projectToManage?.industry_template_id?.template_name ||
                          "Custom Template"}
                      </p>
                    </div>

                    {/* Staging URL */}
                    <div className="bg-gray-50 dark:bg-gray-700/30 p-4 rounded-xl border border-gray-200 dark:border-gray-600">
                      <label className="text-sm font-medium text-gray-500 dark:text-gray-400 block mb-1">
                        Staging URL
                      </label>
                      <a
                        href={`https://${projectToManage?.project_name?.replaceAll(
                          " ",
                          "-"
                        )}.sitebuilderz.com`}
                        target="_blank"
                        rel="noreferrer"
                        className="font-medium text-primary hover:text-purple-600 transition-colors hover:underline flex items-center gap-2"
                      >
                        {`${projectToManage?.project_name?.replaceAll(
                          " ",
                          "-"
                        )}.sitebuilderz.com`}
                        <ExternalLink className="w-4 h-4" />
                      </a>
                    </div>

                    {/* Live Domain - Only show if exists */}
                    {projectToManage?.domain_id?.domain && (
                      <div className="bg-gray-50 dark:bg-gray-700/30 p-4 rounded-xl border border-gray-200 dark:border-gray-600">
                        <label className="text-sm font-medium text-gray-500 dark:text-gray-400 block mb-1">
                          Live Domain
                        </label>
                        <a
                          href={`https://${projectToManage?.domain_id?.domain}`}
                          target="_blank"
                          rel="noreferrer"
                          className="font-medium text-primary hover:text-purple-600 transition-colors hover:underline flex items-center gap-2"
                        >
                          {projectToManage?.domain_id?.domain}
                          <ExternalLink className="w-4 h-4" />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Action Button */}
            <div className="text-center">
              <Button
                onClick={() => setCurrentStep(2)}
                className="btnPrimary bg-gradient-to-r from-orange-400 to-primary py-4 px-8 text-lg font-medium rounded-xl hover:shadow-lg hover:shadow-primary/20 transition-all duration-300 transform hover:scale-105"
              >
                Get Started →
              </Button>
            </div>
          </div>
        )}

        {/* Site Configuration Screen */}
        {currentStep === 2 && (
          <div
            className={`space-y-6 ${
              !sidebar &&
              "bg-gradient-to-b from-white to-gray-50 dark:from-gray-800 dark:to-gray-900 rounded-xl p-8 shadow-xl border border-gray-100 dark:border-gray-700"
            }`}
          >
            <div className="pb-3">
              <h2 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-purple-600 mb-2">
                Site Configuration
              </h2>
              <p className="text-gray-500 dark:text-gray-400 text-lg">
                Configure your site's basic settings
              </p>
            </div>

            <div>
              <div className="group bg-gray-50 dark:bg-gray-700/30 p-6 mb-6 rounded-xl border border-gray-200 dark:border-gray-600">
                <div className="flex items-center gap-3 mb-4">
                  <div className="p-3 bg-primary/10 rounded-lg">
                    <Building className="w-6 h-6 text-primary" />
                  </div>
                  <div>
                    <label className="text-lg font-medium text-gray-700 dark:text-gray-300 block">
                      Industry Name
                    </label>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      This helps us generate more relevant content for your
                      website
                    </p>
                  </div>
                </div>
                <InputField
                  value={industryName}
                  onChange={(e) => setIndustryName(e.target.value)}
                  placeholder="Enter your industry (e.g., Real Estate, Restaurant, Healthcare)"
                  className="w-full transition-all duration-300 focus:ring-2 focus:ring-primary/20"
                />
              </div>

              {/* Site Logo */}
              <div className="group bg-gray-50 dark:bg-gray-700/30 p-6 mb-6 rounded-xl border border-gray-200 dark:border-gray-600">
                <div className="flex items-center gap-3 mb-6">
                  <div className="p-3 bg-primary/10 rounded-lg">
                    <Image className="w-6 h-6 text-primary" />
                  </div>
                  <div>
                    <label className="text-lg font-medium text-gray-700 dark:text-gray-300 block">
                      Site Logo
                    </label>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Choose your preferred logo type
                    </p>
                  </div>
                </div>

                <div className="space-y-6">
                  {/* Logo Type Selection */}
                  <div className="grid grid-cols-3 gap-3">
                    {[
                      { type: "image", icon: Image, label: "Upload Image" },
                      { type: "text", icon: Type, label: "Text Logo" },
                      { type: "ai", icon: Sparkles, label: "AI Generated" },
                    ].map(({ type, icon: Icon, label }) => (
                      <button
                        key={type}
                        onClick={() => setLogoType(type)}
                        className={`p-4 rounded-xl flex justify-center items-center gap-2 transition-all duration-300 ${
                          logoType === type
                            ? "bg-primary text-white shadow-lg shadow-primary/30 scale-[1.02]"
                            : "bg-white dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
                        }`}
                      >
                        <Icon
                          className={`w-6 h-6 ${
                            logoType === type ? "text-white" : "text-primary"
                          }`}
                        />
                        <span className="text-sm font-medium">{label}</span>
                      </button>
                    ))}
                  </div>

                  {/* Logo Content Area */}
                  <div className="bg-white dark:bg-gray-700 rounded-xl p-6 border border-gray-200 dark:border-gray-600">
                    {logoType === "image" && (
                      <div className="text-center">
                        {formData.logo && formData.logo instanceof File ? (
                          <div className="relative group">
                            <img
                              src={URL.createObjectURL(formData.logo)}
                              alt="Logo preview"
                              className="max-w-[200px] mx-auto object-contain rounded-lg"
                            />
                            <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center gap-3 rounded-lg">
                              <div className="flex items-center justify-center gap-2">
                                {" "}
                                <button
                                  onClick={() =>
                                    handleImageUpload("logo", null)
                                  }
                                  className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
                                >
                                  <Trash className="w-4 h-4" />
                                </button>
                                <div className="relative">
                                  <label htmlFor="logo-upload-change">
                                    <Upload className="w-8 h-8 bg-red-500 hover:bg-red-600 transition-colors cursor-pointer text-white rounded-full p-2" />
                                  </label>
                                  <input
                                    type="file"
                                    id="logo-upload-change"
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleImageUpload(
                                        "logo",
                                        e.target.files[0]
                                      )
                                    }
                                    className="hidden"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="border-2 border-dashed border-primary/20 rounded-xl p-8 hover:border-primary/40 transition-all duration-300 cursor-pointer group/upload">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleImageUpload("logo", e.target.files[0])
                              }
                              className="hidden"
                              id="logo-upload"
                            />
                            <label
                              htmlFor="logo-upload"
                              className="cursor-pointer flex flex-col items-center gap-4"
                            >
                              <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center group-hover/upload:bg-primary/20 transition-colors">
                                <Upload className="w-8 h-8 text-primary" />
                              </div>
                              <div className="space-y-2">
                                <p className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                  <span className="text-primary">
                                    Click to upload
                                  </span>{" "}
                                  or drag and drop
                                </p>
                                <p className="text-xs text-gray-500">
                                  SVG, PNG, JPG or GIF (max. 800x400px)
                                </p>
                              </div>
                            </label>
                          </div>
                        )}
                      </div>
                    )}

                    {logoType === "text" && (
                      <div className="space-y-4">
                        <InputField
                          value={textLogo}
                          onChange={(e) => setTextLogo(e.target.value)}
                          placeholder="Enter your text logo"
                          className="w-full"
                        />
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <label className="text-sm text-gray-500 mb-2 block">
                              Font Family
                            </label>
                            <select className="w-full rounded-lg border border-gray-200 dark:border-gray-600 p-2">
                              <option>Inter</option>
                              <option>Roboto</option>
                              <option>Poppins</option>
                            </select>
                          </div>
                          <div>
                            <label className="text-sm text-gray-500 mb-2 block">
                              Color
                            </label>
                            <input
                              type="color"
                              className="w-full h-9 rounded-lg"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {logoType === "ai" && (
                      <div className="text-center py-3 flex flex-col items-center">
                        <h4 className="font-medium text-gray-700 dark:text-gray-300 mb-2 flex items-center gap-2">
                          <Sparkles className="w-6 h-6 text-primary" />
                          AI-Powered Logo Generation
                        </h4>
                        <p className="text-sm text-gray-500 dark:text-gray-400 max-w-sm mx-auto">
                          Our AI will create multiple logo options based on your
                          industry and brand identity
                        </p>
                        <button className="btnPrimary mt-4 rounded-full bg-gradient-to-r from-orange-400 to-primary">
                          Generate Logo
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Banner Section */}
              <div className="group bg-gray-50 dark:bg-gray-700/30 p-6 rounded-xl border border-gray-200 dark:border-gray-600">
                <div className="flex items-center gap-3 mb-6">
                  <div className="p-3 bg-primary/10 rounded-lg">
                    <Layout className="w-6 h-6 text-primary" />
                  </div>
                  <div>
                    <label className="text-lg font-medium text-gray-700 dark:text-gray-300 block">
                      Site Banner
                    </label>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Choose your hero section style
                    </p>
                  </div>
                </div>

                <div className="space-y-6">
                  {/* Banner Type Selection */}
                  <div className="grid grid-cols-2 gap-3">
                    {[
                      { type: "image", icon: Image, label: "Upload Image" },
                      { type: "ai", icon: Sparkles, label: "AI Generated" },
                    ].map(({ type, icon: Icon, label }) => (
                      <button
                        key={type}
                        onClick={() => setBannerType(type)}
                        className={`p-4 rounded-xl flex justify-center items-center gap-2 transition-all duration-300 ${
                          bannerType === type
                            ? "bg-primary text-white shadow-lg shadow-primary/30 scale-[1.02]"
                            : "bg-white dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
                        }`}
                      >
                        <Icon
                          className={`w-6 h-6 ${
                            bannerType === type ? "text-white" : "text-primary"
                          }`}
                        />
                        <span className="text-sm font-medium">{label}</span>
                      </button>
                    ))}
                  </div>

                  {/* Banner Content Area */}
                  <div className="bg-white dark:bg-gray-700 rounded-xl border border-gray-200 dark:border-gray-600">
                    {bannerType === "image" && (
                      <div className="text-center">
                        {formData.banner && formData.banner instanceof File ? (
                          <div className="relative group">
                            <img
                              src={URL.createObjectURL(formData.banner)}
                              alt="Banner preview"
                              className="w-full h-48 object-cover rounded-xl"
                            />
                            <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center gap-3 rounded-xl">
                              <div className="flex items-center justify-center gap-2">
                                <button
                                  onClick={() =>
                                    handleImageUpload("banner", null)
                                  }
                                  className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
                                >
                                  <Trash className="w-4 h-4" />
                                </button>
                                <div className="relative">
                                  <label htmlFor="banner-upload-change">
                                    <Upload className="w-8 h-8 bg-red-500 hover:bg-red-600 transition-colors cursor-pointer text-white rounded-full p-2" />
                                  </label>
                                  <input
                                    type="file"
                                    id="banner-upload-change"
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleImageUpload(
                                        "banner",
                                        e.target.files[0]
                                      )
                                    }
                                    className="hidden"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="border-2 border-dashed border-primary/20 rounded-xl p-8 m-6 hover:border-primary/40 transition-all duration-300 cursor-pointer group/upload">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleImageUpload("banner", e.target.files[0])
                              }
                              className="hidden"
                              id="banner-upload"
                            />
                            <label
                              htmlFor="banner-upload"
                              className="cursor-pointer flex flex-col items-center gap-4"
                            >
                              <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center group-hover/upload:bg-primary/20 transition-colors">
                                <Upload className="w-8 h-8 text-primary" />
                              </div>
                              <div className="space-y-2">
                                <p className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                  <span className="text-primary">
                                    Click to upload
                                  </span>{" "}
                                  or drag and drop
                                </p>
                                <p className="text-xs text-gray-500">
                                  Recommended size: 1920x1080px
                                </p>
                              </div>
                            </label>
                          </div>
                        )}
                      </div>
                    )}

                    {bannerType === "ai" && (
                      <div className="text-center py-8 flex flex-col items-center">
                        <h4 className="font-medium text-gray-700 dark:text-gray-300 mb-2 flex items-center gap-2 flex-wrap">
                          <Sparkles className="w-6 h-6 text-primary" />
                          AI-Generated Banner
                        </h4>
                        <p className="text-sm text-gray-500 dark:text-gray-400 max-w-sm mx-auto">
                          Our AI will create a custom banner that matches your
                          brand's style and industry
                        </p>
                        <button className="btnPrimary mt-4 rounded-full bg-gradient-to-r from-orange-400 to-primary">
                          Generate Image
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-end gap-4 mt-4">
                  <InputField placeholder="Enter your banner title" />
                  or
                  <button className="btnPrimary">AI Generate</button>
                </div>
                <div className="flex items-center justify-end gap-4 mt-4">
                  <InputField placeholder="Enter your banner description" />
                  or
                  <button className="btnPrimary">AI Generate</button>
                </div>
              </div>
            </div>

            <div className="flex justify-between pt-6">
              <Button
                onClick={() => setCurrentStep(1)}
                className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-xl px-6 py-2.5 text-black dark:text-white flex items-center gap-2"
              >
                <ChevronLeft className="w-4 h-4" /> Back
              </Button>
              <Button
                onClick={() => setCurrentStep(3)}
                className="btnPrimary bg-gradient-to-r from-orange-400 to-primary py-2.5 px-6 rounded-xl flex items-center gap-2"
              >
                Continue <ChevronRight className="w-4 h-4" />
              </Button>
            </div>
          </div>
        )}

        {/* Pages Selection Screen */}
        {currentStep === 3 && (
          <div
            className={`space-y-8 ${
              !sidebar &&
              "bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700"
            }`}
          >
            <div className="pb-4">
              <h2 className="text-4xl mb-3 font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-purple-600">
                Choose Your Pages
              </h2>
              <p className="text-gray-500 dark:text-gray-400">
                Select which pages to include in your website
              </p>
            </div>

            <div className="grid gap-3">
              {layout.map((page, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between px-3 py-2.5 bg-gray-50 dark:bg-gray-700/50 rounded-lg border-2 border-gray-100 dark:border-gray-700 hover:border-primary/30 transition-all duration-300 hover:scale-[1.01]"
                >
                  <span className="font-medium capitalize flex items-center gap-2">
                    <div
                      className={`w-2 h-5 rounded-full ${
                        page.enable ? "bg-green-500" : "bg-gray-400"
                      }`}
                    ></div>
                    {page.page}
                  </span>
                  <SwitchToggle
                    enabled={page.enable}
                    handleEnabled={() => handleEnabledPage(index)}
                  />
                </div>
              ))}
            </div>

            <div className="flex justify-between pt-6">
              <Button
                onClick={() => setCurrentStep(2)}
                className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 px-6 py-2.5 rounded-xl transition-all duration-300 flex items-center gap-2"
              >
                <ChevronLeft className="w-4 h-4" /> Back
              </Button>
              <Button
                onClick={() => setCurrentStep(4)}
                className="btnPrimary bg-gradient-to-r from-orange-400 to-primary py-2.5 px-6 rounded-xl flex items-center gap-2"
              >
                Continue <ChevronRight className="w-4 h-4" />
              </Button>
            </div>
          </div>
        )}

        {/* Content Configuration Screen */}
        {currentStep === 4 && (
          <div
            className={`space-y-6 ${
              !sidebar &&
              "bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700"
            }`}
          >
            {/* Header */}
            <div className="pb-3">
              <h2 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-purple-600 mb-2">
                Blog Content Configuration
              </h2>
              <p className="text-gray-500 dark:text-gray-400 text-lg">
                Set up your blog categories and configure article distribution
                for each category
              </p>
            </div>

            {/* Enhanced Category Management Section */}
            <div className="space-y-6">
              {/* Add Category Card */}
              <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-xl border border-gray-200 dark:border-gray-700 shadow-sm">
                <div className="flex items-center gap-3 mb-6">
                  <div className="p-2.5 bg-primary/10 rounded-lg">
                    <Plus className="w-5 h-5 text-primary" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">
                      Add Content Category
                    </h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Create categories to organize your blog content
                    </p>
                  </div>
                </div>

                <div className="flex gap-3">
                  <InputField
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter category name (e.g. Business, Technology)"
                    className="flex-1"
                  />
                  <Button
                    onClick={handleCategoryAdd}
                    disabled={!newCategory.trim()}
                    className={`px-6 rounded-lg flex items-center disabled:bg-primary disabled:text-white gap-2 ${
                      newCategory.trim()
                        ? "bg-primary text-white hover:bg-primary/90"
                        : "bg-gray-100 text-gray-400 dark:bg-gray-700 cursor-not-allowed"
                    }`}
                  >
                    <Plus className="w-4 h-4" />
                    Add
                  </Button>
                </div>
              </div>

              {/* Categories List */}
              <div className="bg-gray-50 dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700 shadow-sm overflow-hidden">
                <div className="p-6 border-b border-gray-200 dark:border-gray-700">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <div className="p-2.5 bg-primary/10 rounded-lg">
                        <FileText className="w-5 h-5 text-primary" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold">
                          Content Categories
                        </h3>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Manage your blog categories and article distribution
                        </p>
                      </div>
                    </div>
                    <span className="bg-primary/10 text-primary px-4 py-2 rounded-full text-sm font-medium">
                      {formData.categories.length} categories
                    </span>
                  </div>
                </div>

                <div className="divide-y divide-gray-200 dark:divide-gray-700">
                  {formData.categories.length === 0 ? (
                    <div className="p-12 text-center">
                      <div className="w-20 h-20 rounded-full bg-gray-100 dark:bg-gray-700 flex items-center justify-center mx-auto mb-4">
                        <FileX className="w-10 h-10 text-gray-400 dark:text-gray-500" />
                      </div>
                      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
                        No categories yet
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400 max-w-sm mx-auto">
                        Start by adding your first content category using the
                        form above
                      </p>
                    </div>
                  ) : (
                    formData.categories.map((category) => (
                      <div
                        key={category}
                        className="group p-6 hover:bg-gray-100 dark:hover:bg-gray-700/30 transition-all duration-200"
                      >
                        <div className="flex items-center justify-between mb-4">
                          <div className="flex items-center gap-3">
                            <button
                              onClick={() => removeCategory(category)}
                              className="p-2 rounded-full hover:bg-red-100 dark:hover:bg-red-900/30 group-hover:text-red-500 transition-colors"
                            >
                              <Trash className="w-4 h-4" />
                            </button>
                            <span className="font-medium text-lg capitalize">
                              {category}
                            </span>
                          </div>
                          <Button
                            onClick={() => handleOpenScheduleModal(category)}
                            className="bg-primary/10 hover:bg-primary text-primary hover:text-white px-4 py-2 rounded-lg transition-all duration-300 flex items-center gap-2"
                          >
                            <Stars className="w-4 h-4" />
                            Schedule Posts
                          </Button>
                        </div>

                        <div className="grid grid-cols-4 gap-3 bg-white dark:bg-gray-800 p-4 rounded-xl border border-gray-200 dark:border-gray-700">
                          {[
                            {
                              label: "Total Articles",
                              value: totalArticles[category] || 1,
                              onChange: (e) =>
                                handleArticleCountChange(
                                  category,
                                  "total",
                                  e.target.value
                                ),
                              min: 1,
                              editable: true,
                              icon: FileText,
                            },
                            {
                              label: "Publish Now",
                              value: publishArticles[category] || 0,
                              onChange: (e) =>
                                handleArticleCountChange(
                                  category,
                                  "publish",
                                  e.target.value
                                ),
                              min: 0,
                              max:
                                totalArticles[category] -
                                (scheduleArticles[category] || 0),
                              editable: true,
                              icon: Check,
                            },
                            {
                              label: "Scheduled",
                              value: scheduleArticles[category] || 0,
                              editable: false,
                              icon: Stars,
                            },
                            {
                              label: "Remaining",
                              value: Math.max(
                                (totalArticles[category] || 0) -
                                  (publishArticles[category] || 0) -
                                  (scheduleArticles[category] || 0),
                                0
                              ),
                              editable: false,
                              icon: FileText,
                            },
                          ].map((item, index) => (
                            <div key={index}>
                              <div className="flex items-center gap-1 mb-1">
                                <item.icon className="w-4 h-4 text-gray-400" />
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  {item.label}
                                </span>
                              </div>
                              <InputField
                                type="number"
                                min={item.min}
                                max={item.max}
                                value={item.value}
                                onChange={item.onChange}
                                disabled={!item.editable}
                                className={`w-full ${
                                  !item.editable && "bg-transparent"
                                }`}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>

            {/* Navigation Buttons */}
            <div className="flex justify-between pt-6">
              <Button
                onClick={() => setCurrentStep(3)}
                className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 px-6 py-2.5 rounded-xl transition-all duration-300 flex items-center gap-2"
              >
                <ChevronLeft className="w-4 h-4" /> Back
              </Button>
              <Button
                onClick={() => setCurrentStep(5)}
                className="btnPrimary bg-gradient-to-r from-orange-400 to-primary py-2.5 px-6 rounded-xl flex items-center gap-2"
              >
                Continue <ChevronRight className="w-4 h-4" />
              </Button>
            </div>
          </div>
        )}

        {/* Review Screen */}
        {currentStep === 5 && (
          <div
            className={`space-y-6 ${
              !sidebar &&
              "bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700"
            }`}
          >
            <div className="pb-6">
              <h2 className="text-4xl mb-3 font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-purple-600">
                Review Your Choices
              </h2>
              <p className="text-gray-500 dark:text-gray-400">
                Please review your site's configuration before we generate your
                content
              </p>
            </div>

            <div className="bg-gray-50 dark:bg-gray-700/30 p-6 rounded-xl border border-gray-200 dark:border-gray-600">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 bg-primary/10 rounded-lg">
                  <Stars className="w-5 h-5 text-primary" />
                </div>
                <h3 className="font-semibold text-lg">Site Information</h3>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between p-3 bg-white dark:bg-gray-600/30 rounded-lg">
                  <span className="text-gray-600 dark:text-gray-300">
                    Industry Name
                  </span>
                  <span className="font-medium">
                    {industryName || "Not set"}
                  </span>
                </div>

                <div className="flex items-center justify-between p-3 bg-white dark:bg-gray-600/30 rounded-lg">
                  <span className="text-gray-600 dark:text-gray-300">Logo</span>
                  <span
                    className={`font-medium flex items-center gap-2 ${
                      formData.logo ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {formData.logo ? (
                      <>
                        <Check className="w-4 h-4" />
                        Uploaded
                      </>
                    ) : (
                      <>
                        <X className="w-4 h-4" />
                        Not uploaded
                      </>
                    )}
                  </span>
                </div>

                <div className="flex items-center justify-between p-3 bg-white dark:bg-gray-600/30 rounded-lg">
                  <span className="text-gray-600 dark:text-gray-300">
                    Banner
                  </span>
                  <span
                    className={`font-medium flex items-center gap-2 ${
                      formData.banner ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {formData.banner ? (
                      <>
                        <Check className="w-4 h-4" />
                        Uploaded
                      </>
                    ) : (
                      <>
                        <X className="w-4 h-4" />
                        Not uploaded
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>

            {/* Content Categories Card */}
            <div className="bg-gray-50 dark:bg-gray-700/30 p-6 rounded-xl border border-gray-200 dark:border-gray-600">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-primary/10 rounded-lg">
                    <FileText className="w-5 h-5 text-primary" />
                  </div>
                  <h3 className="font-semibold text-lg">Content Categories</h3>
                </div>
                <span className="text-sm text-primary bg-primary/10 px-3 py-1 rounded-full font-medium">
                  {formData.categories.length} categories
                </span>
              </div>

              {formData.categories.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-[200px] text-gray-500 dark:text-gray-400 bg-gray-50 dark:bg-gray-600/30 rounded-lg">
                  <FileX className="w-12 h-12 mb-2 opacity-50" />
                  <p>No categories selected</p>
                </div>
              ) : (
                <div className="space-y-3">
                  {formData.categories.map((category) => (
                    <div
                      key={category}
                      className="group flex justify-between items-center p-3 bg-white dark:bg-gray-600/30 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600/50 transition-all duration-200"
                    >
                      <div className="flex items-center gap-3">
                        <div className="w-2 h-2 rounded-full bg-primary"></div>
                        <span className="font-medium capitalize">
                          {category}
                        </span>
                      </div>
                      <div className="flex items-center gap-4">
                        <div className="px-3 py-1 rounded-full bg-primary/10 text-primary text-sm">
                          {totalArticles[category] || 0} articles
                        </div>
                        <div className="flex gap-2 text-sm text-gray-500">
                          <span>{publishArticles[category] || 0} publish</span>
                          <span>•</span>
                          <span>
                            {scheduleArticles[category] || 0} schedule
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* Summary */}
                  <div className="mt-4 p-4 bg-white dark:bg-gray-600/30 rounded-lg">
                    <div className="flex justify-between items-center text-sm text-gray-600 dark:text-gray-300">
                      <span>Total Articles:</span>
                      <span className="font-semibold text-primary">
                        {formData.categories.reduce(
                          (sum, category) =>
                            sum + (totalArticles[category] || 0),
                          0
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Action Buttons */}
            <div className="flex justify-between pt-6">
              <Button
                onClick={() => setCurrentStep(4)}
                className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 px-6 py-2.5 rounded-xl transition-all duration-300 flex items-center gap-2"
              >
                <ChevronLeft className="w-4 h-4" /> Back
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={isGenerating}
                className="btnPrimary bg-gradient-to-r from-primary to-purple-600 py-3 px-6 text-white rounded-xl flex items-center gap-2 hover:shadow-lg hover:shadow-primary/20 transition-all duration-300"
              >
                {isGenerating ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent"></div>
                    Generating...
                  </>
                ) : (
                  <>
                    Generate Content
                    <Stars className="w-4 h-4" />
                  </>
                )}
              </Button>
            </div>
          </div>
        )}
      </div>

      {/* Schedule Modal */}
      {isScheduleModalOpen && (
        <div className="fixed inset-0 bg-gray-800/50 backdrop-blur-sm flex items-center justify-center z-50 -top-6 left-0">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-8 w-[500px] shadow-xl">
            {/* Header */}
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-primary/10 rounded-lg">
                  <Stars className="w-6 h-6 text-primary" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold">
                    Create Publishing Schedule
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Schedule content for "{selectedCategory}"
                  </p>
                </div>
              </div>
              <button
                onClick={() => setIsScheduleModalOpen(false)}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            {/* Content */}
            <div className="space-y-6">
              {/* Schedule Configuration */}
              <div className="bg-gray-50 dark:bg-gray-700/30 p-4 rounded-xl border border-gray-200 dark:border-gray-600">
                <div className="flex items-end gap-3">
                  <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Frequency
                    </label>
                    <InputField
                      type="number"
                      min="1"
                      value={scheduleFrequency}
                      onChange={(e) => setScheduleFrequency(e.target.value)}
                      className="w-full"
                    />
                  </div>
                  <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Interval
                    </label>
                    <select
                      value={scheduleInterval}
                      onChange={(e) => setScheduleInterval(e.target.value)}
                      className="w-full rounded-lg border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800 p-2.5 focus:ring-2 focus:ring-primary/20 outline-none"
                    >
                      <option value="day">per Day</option>
                      <option value="week">per Week</option>
                      <option value="month">per Month</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Summary */}
              <div className="bg-primary/5 rounded-xl p-4">
                <div className="flex items-center gap-2 text-primary mb-2">
                  <FileText className="w-4 h-4" />
                  <span className="font-medium">Schedule Summary</span>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  This will schedule{" "}
                  <span className="font-semibold text-primary">
                    {scheduleFrequency}
                  </span>{" "}
                  articles {scheduleInterval}ly for the category{" "}
                  <span className="font-semibold text-primary">
                    "{selectedCategory}"
                  </span>
                </p>
              </div>

              {/* Warning if applicable */}
              {scheduleFrequency >
                (totalArticles[selectedCategory] || 0) -
                  (publishArticles[selectedCategory] || 0) -
                  (scheduleArticles[selectedCategory] || 0) && (
                <div className="bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 p-4 rounded-xl flex items-start gap-2">
                  <div className="p-1 bg-red-100 dark:bg-red-900/30 rounded-full mt-0.5">
                    <X className="w-3 h-3" />
                  </div>
                  <p className="text-sm">
                    Warning: You can only schedule up to{" "}
                    {(totalArticles[selectedCategory] || 0) -
                      (publishArticles[selectedCategory] || 0) -
                      (scheduleArticles[selectedCategory] || 0)}{" "}
                    more articles for this category
                  </p>
                </div>
              )}
            </div>

            {/* Actions */}
            <div className="flex justify-end gap-3 mt-8 pt-6 border-t border-gray-200 dark:border-gray-700">
              <Button
                onClick={() => setIsScheduleModalOpen(false)}
                className="px-4 py-2 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-lg transition-colors"
              >
                Cancel
              </Button>
              <Button
                onClick={handleCreateSchedule}
                className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors flex items-center gap-2"
              >
                <Stars className="w-4 h-4" />
                Create Schedule
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
