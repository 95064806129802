import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { CircleCheck, LoaderCircle, Stars } from "lucide-react";
import { Breadcrumbs, InputField, ListBox } from "../../../components";
import { TypeAnimation } from "react-type-animation";
import { useSpring, animated } from "react-spring";

import useApi from "../../../utils/useApi";
import SelectTemplate from "./SelectTemplate";

/* -------------------------------------------------------------------------- */
/*                                Sub-Components                              */
/* -------------------------------------------------------------------------- */

/**
 * AnimatedField
 * Displays a single field key-value pair with a fade/slide animation.
 */
const AnimatedField = ({ fieldKey, value, delay }) => {
  const fieldAnimation = useSpring({
    from: { opacity: 0, transform: "translateX(-20px)" },
    to: { opacity: 1, transform: "translateX(0)" },
    delay,
    config: { tension: 170, friction: 26 },
  });

  return (
    <animated.div
      style={fieldAnimation}
      className="flex items-center justify-between group"
    >
      <span className="text-sm text-gray-500 dark:text-gray-400">
        {fieldKey.replace(/([A-Z])/g, " $1").trim()}
      </span>
      <span className="font-medium text-gray-800 dark:text-gray-200">
        {value}
      </span>
    </animated.div>
  );
};

/**
 * SitePortfolioCard
 * Renders a card containing multiple AnimatedField components for a given category.
 */
const SitePortfolioCard = ({ category, fields, categoryIndex }) => {
  const categoryAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: categoryIndex * 200,
    config: { tension: 170, friction: 26 },
  });

  return (
    <animated.div
      style={categoryAnimation}
      className="col-span-1 bg-white dark:bg-gray-800 p-8 rounded-2xl shadow-lg 
                 hover:shadow-xl transition-all duration-300"
    >
      <h4 className="text-xl font-semibold mb-6 text-primary flex items-center gap-3">
        <div className="w-2 h-2 rounded-full bg-primary" />
        {category}
      </h4>
      <div className="space-y-5">
        {fields.map(([key, value], index) => (
          <AnimatedField
            key={key}
            fieldKey={key}
            value={value}
            delay={categoryIndex * 200 + index * 100}
          />
        ))}
      </div>
    </animated.div>
  );
};

/**
 * SitePortfolio
 * Shows a summary of the form data and a button to finalize execution.
 */
const SitePortfolio = ({ formData, onExecute }) => {
  // Button animation
  const buttonAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: 800,
    config: { tension: 170, friction: 26 },
  });

  // Check if all required fields are filled
  const isFormValid = () => {
    const requiredFields = [
      "projectName",
      "industryName",
      "category",
      "numArticles",
      "numAuthors",
      "numTags",
      "wordCount",
      "createdBy",
      "siteDomain",
      "numSections",
      "bannerResolution",
      "companyName",
    ];

    return requiredFields.every(
      (field) => formData[field] && formData[field].toString().trim() !== ""
    );
  };

  // Derive any necessary data from formData
  const projectName = formData.projectName;
  const projectDescription = formData.industryName
    ? `Revving up your ${formData.industryName.toLowerCase()} knowledge.`
    : "Creating your perfect website.";

  // Essential fields to display
  const essentialFields = {
    Industry: formData.industryName,
    Category: formData.category,
    Domain: formData.siteDomain,
    Company: formData.companyName,
    Location: formData.companyLocation,
    Contact: formData.phoneNumber,
  };

  return (
    <div className="animate-fadeIn bg-white dark:bg-gray-900 min-h-[calc(100vh-3.5rem)] shadow-l shadow-black/50">
      <div className="max-w-4xl mx-auto p-8">
        {/* Header Section */}
        <div className="space-y-6 mb-12">
          <p className="text-sm font-medium tracking-widest text-gray-500 dark:text-gray-400 uppercase">
            SITE PROFILE
          </p>
          <h1 className="text-7xl font-light tracking-tight text-gray-900 dark:text-white">
            {projectName}
          </h1>
          <p className="text-xl text-gray-500 dark:text-gray-400">
            {projectDescription}
          </p>
        </div>

        {/* Content Grid */}
        <div className="grid grid-cols-2 gap-x-24 gap-y-12">
          {Object.entries(essentialFields).map(([key, value], index) => {
            if (!value) return null;
            return (
              <div key={key} className="space-y-2">
                <p className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  {key}
                </p>
                <p className="text-lg text-gray-900 dark:text-white">{value}</p>
              </div>
            );
          })}
        </div>

        {/* Action Button */}
        <animated.button
          style={buttonAnimation}
          onClick={onExecute}
          disabled={!isFormValid()}
          className={`mt-32 px-12 py-4 rounded-lg text-lg font-medium transition-all duration-300
            ${
              isFormValid()
                ? "bg-gray-900 dark:bg-white text-white dark:text-gray-900 hover:bg-gray-800 dark:hover:bg-gray-100"
                : "bg-gray-300 dark:bg-gray-700 text-gray-500 dark:text-gray-400 cursor-not-allowed"
            }`}
        >
          Create Site
        </animated.button>
      </div>
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                                Main Component                              */
/* -------------------------------------------------------------------------- */

/**
 * NewProject
 * Combines manual project creation and AI-driven site creation wizard.
 */
export default function NewProject() {
  /* ------------------------------ API & State ----------------------------- */
  const { request, isLoading } = useApi();
  const navigate = useNavigate();

  // Basic states
  const [siteCreated, setCreated] = useState(false);
  const [industry, setIndustry] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [industries, setIndustries] = useState([]);

  // Website data
  const [selectedThemeId, setSelectedThemeId] = useState(null);
  const [project_name, setProjectName] = useState("");

  // Merchant data from localStorage
  const merchant = JSON.parse(localStorage.getItem("merchant")) || {};

  // Chat or manual toggle
  const [chat, setChat] = useState(true);

  // Step-based form data
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    projectName: "",
    industryName: "",
    category: "",
    numArticles: "",
    numAuthors: "",
    numTags: "",
    wordCount: "",
    createdBy: "admin",
    siteDomain: "",
    numSections: "",
    bannerResolution: "",
    companyName: "",
    companyWebsite: "",
    companyLocation: "",
    phoneNumber: "",
  });

  // Questions for AI wizard
  const questions = [
    { key: "projectName", label: "What would you like to name your project?" },
    { key: "industryName", label: "What industry is your site in?" },
    { key: "category", label: "What category best describes your site?" },
    {
      key: "numArticles",
      label: "How many articles would you like to generate?",
    },
    { key: "numAuthors", label: "How many authors should we create?" },
    { key: "numTags", label: "How many tags should we generate?" },
    { key: "wordCount", label: "What's the average word count per article?" },
    { key: "createdBy", label: "Who is creating this site?" },
    { key: "siteDomain", label: "What domain will you use?" },
    { key: "numSections", label: "How many sections should your site have?" },
    {
      key: "bannerResolution",
      label: "What resolution do you want for banner images?",
      type: "select",
      options: [
        { label: "Please select a resolution", value: "" },
        { label: "Wide Resolutions (Landscape)", disabled: true },
        { label: "256x512 (Wide - Small)", value: "256x512" },
        { label: "512x1024 (Wide - Medium)", value: "512x1024" },
        { label: "720x1280 (Wide - Large)", value: "720x1280" },
        { label: "Square Resolutions", disabled: true },
        { label: "128x128 (Square - Small)", value: "128x128" },
        { label: "256x256 (Square - Medium)", value: "256x256" },
        { label: "512x512 (Square - Large)", value: "512x512" },
        { label: "Tall Resolution", disabled: true },
        { label: "512x256 (Tall - Small)", value: "512x256" },
        { label: "1024x512 (Tall - Medium)", value: "1024x512" },
        { label: "1280x720 (Tall - Large)", value: "1280x720" },
      ],
    },
    { key: "companyName", label: "What's your company name?" },
    { key: "companyWebsite", label: "What's your company website?" },
    { key: "companyLocation", label: "Where is your company located?" },
    { key: "phoneNumber", label: "What's your company phone number?" },
  ];

  /* ---------------------------- Lifecycle Hooks --------------------------- */
  // 1. Fetch list of industries on mount
  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const data = await request({ method: "get", url: "industries" });
        setIndustries(data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchIndustries();
  }, []);

  // 2. Fetch templates when an industry is selected
  useEffect(() => {
    const fetchTemplates = async () => {
      if (!industry) return;
      try {
        const data = await request({
          method: "get",
          url: `industries/${industry._id}/templates?active=1`,
        });
        setTemplates(data.data);
      } catch (err) {
        console.error(err);
      }
    };
    if (industry?._id) fetchTemplates();
  }, [industry]);

  // 3. AI chat paragraph reveal
  const [showParagraph, setShowParagraph] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowParagraph(true);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  /* ----------------------------- Animations ------------------------------- */
  const [headingAnimation] = useSpring(() => ({
    from: { opacity: 0, transform: "translateY(-20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { tension: 200, friction: 20 },
  }));

  const cardAnimation = useSpring({
    from: { transform: "translateY(20px)", opacity: 0 },
    to: { transform: "translateY(0)", opacity: 1 },
    config: { tension: 170, friction: 26 },
  });

  /* ----------------------------- Handlers -------------------------------- */
  // Handle input change in AI wizard
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (questions[step].key === "industryName") {
      // For industry selection, match the industry object if possible
      const selectedIndustry = industries.find(
        (ind) => ind.industry_name === value
      );
      setFormData((prev) => ({
        ...prev,
        [questions[step].key]: selectedIndustry
          ? selectedIndustry.industry_name
          : value,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [questions[step].key]: value,
      }));
    }
  };

  // Move to next question in AI wizard
  const handleNext = () => {
    const currentValue = formData[questions[step].key];

    // Check if the current field is empty or only whitespace
    if (!currentValue || currentValue.trim() === "") {
      toast.error("This field is required");
      return;
    }

    // For numeric fields, ensure the value is a positive number
    const numericFields = [
      "numArticles",
      "numAuthors",
      "numTags",
      "wordCount",
      "numSections",
    ];
    if (numericFields.includes(questions[step].key)) {
      const numValue = parseInt(currentValue);
      if (isNaN(numValue) || numValue <= 0) {
        toast.error("Please enter a valid positive number");
        return;
      }
    }

    if (step < questions.length - 1) {
      setStep((prev) => prev + 1);
      // Reset TypeAnimation (simple approach)
      const typeAnimationElement = document.querySelector(".type-animation");
      if (typeAnimationElement) {
        typeAnimationElement.style.opacity = 0;
        setTimeout(() => {
          typeAnimationElement.style.opacity = 1;
        }, 50);
      }
    }
  };

  // Execute final step from AI wizard
  const handleExecute = async () => {
    try {
      // Extract image dimensions from bannerResolution
      const [height, width] = formData.bannerResolution.split("x").map(Number);

      // Get auth token from localStorage
      const token = localStorage.getItem("token");

      // Prepare request payload
      const payload = {
        projectName: formData.projectName,
        industry: formData.industryName,
        category: formData.category,
        titleCount: parseInt(formData.numArticles),
        authorCount: parseInt(formData.numAuthors),
        tagCount: parseInt(formData.numTags),
        wordCount: parseInt(formData.wordCount),
        createdBy: formData.createdBy,
        domain: formData.siteDomain,
        sectionCount: parseInt(formData.numSections),
        executeNow: 1,
        imgHeight: height,
        imgWidth: width,
        additionalData: {
          companyDetails: {
            companyName: formData.companyName,
            website: formData.companyWebsite,
            location: formData.companyLocation,
            phoneNumber: formData.phoneNumber,
          },
        },
      };

      // Make API request with headers
      const response = await request({
        method: "post",
        url: "https://apipm.ecommcube.com/create_project",
        data: payload,
        headers: {
          Authorization: `Bearer f61c1e040031ca554f74b1d58acff7b8`,
          "Content-Type": "application/json",
        },
      });

      toast.success("Project created successfully!");

      navigate(
        `/projects/${formData.projectName.toLowerCase().replaceAll(" ", "-")}`
      );
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to create project");
      console.error("Project creation error:", error);
    }
  };

  // Manage domain and project navigation
  const manageProject = () => {
    navigate(`/projects/${project_name.toLowerCase().replaceAll(" ", "-")}`);
    setProjectName("");
    setSelectedThemeId(null);
  };
  const manageDomains = () => {
    navigate(
      `/projects/${project_name.toLowerCase().replaceAll(" ", "-")}/domains`
    );
    setProjectName("");
    setSelectedThemeId(null);
  };

  // Create a new project in manual setup
  const handleCreateProject = async (e, template_id) => {
    e.preventDefault();
    try {
      await request({
        method: "post",
        url: "projects",
        data: {
          project_name,
          industry_id: industry?._id,
          industry_template_id: template_id,
          merchant_id: merchant?._id,
        },
      });
      toast.success("New Project Created");
      setCreated(true);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  /* ----------------------------- Render ---------------------------------- */
  // If AI chat is disabled, show manual project creation
  if (!chat) {
    return (
      <div className="flex flex-col items-center w-full">
        <Toaster />
        {siteCreated && (
          <div
            className="h-full w-full fixed top-0 left-0 bg-gray-400/50 dark:bg-gray-950/50 
                          flex items-center justify-center z-50"
          >
            <div
              className="bg-white dark:bg-gray-800 p-10 shadow-xl rounded-xl text-center w-5/12 
                            flex flex-col items-center gap-5"
            >
              <CircleCheck className="w-16 text-green-500" />
              <h1 className="font-semibold">Congratulations</h1>
              <p className="text-lg">Your Project is ready.</p>
              <div className="flex items-center justify-center gap-2">
                <button onClick={manageProject} className="btnPrimary">
                  Manage Project
                </button>
                <button onClick={manageDomains} className="btnWhite">
                  Connect Domain
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Main Layout for manual setup */}
        <div className="grid grid-cols-1 md:grid-cols-newSiteManual w-full">
          {/* Left Side - Project Form */}
          <div className="border-r dark:border-white/20 py-8 px-10 bg-white dark:bg-gray-900">
            <Breadcrumbs />
            <button
              onClick={() => setChat(true)}
              className="flex items-center justify-center mt-12 gap-2 py-2.5 px-4 rounded-full 
                         bg-gradient-to-r from-orange-500 to-primary text-white w-full shadow-lg 
                         hover:shadow-xl transition-all duration-300 relative overflow-hidden group"
            >
              <Stars className="h-4 w-4 group-hover:rotate-180 transition-transform duration-500" />
              <span className="relative z-10">Create With AI</span>
              <div className="absolute inset-0 bg-white/10 group-hover:bg-white/20 transition-colors duration-300" />
            </button>

            <div className="flex items-center gap-4 my-10">
              <div className="h-px bg-gray-200 dark:bg-white/20 flex-1"></div>
              <p className="text-gray-500 dark:text-white/70 text-sm font-medium">
                or
              </p>
              <div className="h-px bg-gray-200 dark:bg-white/20 flex-1"></div>
            </div>

            <h2 className="text-3xl font-bold capitalize mb-1">
              Create New Project
            </h2>
            <p className="text-gray-500">
              To deploy a new Project, import an existing Git Repository or get
              started with one of our Templates.
            </p>

            <div className="flex flex-col max-w-screen-sm mt-8 gap-5">
              <InputField
                label="What do you want to call this project?"
                placeholder="Enter project name"
                value={project_name}
                onChange={(e) => setProjectName(e.target.value)}
              />
              <ListBox
                label="Select Project Type"
                name="industry"
                options={industries?.map((ind) => ({
                  _id: ind._id,
                  name: ind.industry_name,
                }))}
                selectedOption={industry}
                setSelectedOption={setIndustry}
                placeholder="Select"
              />
            </div>
          </div>

          {/* Right Side - Template Selection */}
          <div className="bg-gray-50 dark:bg-gray-800 h-[calc(100vh-3rem)] overflow-y-scroll">
            {industry ? (
              <div className="px-10">
                <h3 className="font-semibold capitalize mt-7 text-lg md:text-2xl">
                  Select a {industry?.name} template for your project
                </h3>
                {isLoading ? (
                  <div className="flex flex-col items-center w-full p-52 gap-5">
                    <LoaderCircle className="h-12 w-12 animate-spin text-primary" />
                    <p>Loading Templates...</p>
                  </div>
                ) : (
                  <div className="mt-5 w-full">
                    {templates?.length > 0 ? (
                      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 mt-5">
                        {templates.map((template, index) => (
                          <SelectTemplate
                            key={index}
                            themeId={template._id}
                            image={`${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template?._id}/thumb/${template.thumbnail}`}
                            title={template.template_name}
                            demoURL={template?.url}
                            selectedValue={selectedThemeId}
                            setSelectedValue={setSelectedThemeId}
                            createProject={handleCreateProject}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="text-center text-gray-500 py-44">
                        No templates found
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <img
                src="/onboarding.webp"
                className="h-[calc(100vh-5rem)] ml-auto mr-auto"
                alt="Onboarding"
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  // AI Chat Setup
  return (
    <div
      className={`${
        step > questions.length - 13
          ? "grid grid-cols-1 md:grid-cols-newSite w-full"
          : "w-full"
      }`}
    >
      {/* AI Chat Container */}
      <div
        className="relative flex flex-col items-center justify-between p-10 w-full min-h-[calc(100vh-3.5rem)] bg-cover bg-center"
        style={{ backgroundImage: "url('/newProjectBg.jpg')" }}
      >
        <div className="absolute inset-0 bg-white/75 dark:bg-gray-900/50" />

        <div className="w-full max-w-screen-md px-4 relative z-10">
          <animated.div style={headingAnimation} className="text-center mb-8">
            <h1 className="text-4xl font-bold mb-3 text-gray-800 dark:text-white">
              AI-Powered Site Creator
            </h1>
            <p className="text-gray-600 dark:text-gray-300">
              Let's build your perfect website together, one step at a time
            </p>
          </animated.div>

          <animated.div style={cardAnimation}>
            <div
              className="bg-white dark:bg-gray-800 rounded-2xl shadow-2xl p-8 
                            backdrop-blur-sm bg-opacity-80"
            >
              <div className="flex items-center gap-3 mb-8 pb-4 border-b dark:border-gray-700">
                <Stars className="w-8 h-8 text-primary animate-pulse" />
                <div>
                  <h2 className="text-2xl font-bold">Site Configuration</h2>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Step {step + 1} of {questions.length}
                  </p>
                </div>
              </div>

              <div className="space-y-6">
                {step < questions.length && (
                  <div className="animate-fadeIn">
                    <div key={step} className="type-animation mb-6">
                      <TypeAnimation
                        sequence={[questions[step].label]}
                        wrapper="h3"
                        speed={50}
                        className="text-xl font-medium text-gray-700 dark:text-gray-200"
                      />
                    </div>

                    <div className="flex gap-3">
                      {questions[step].type === "select" ? (
                        <select
                          className="flex-1 px-6 py-3 rounded-xl border border-gray-200 dark:border-gray-700 
                                     dark:bg-gray-900 focus:ring-2 focus:ring-primary/20 
                                     focus:border-primary transition-all duration-200 text-lg"
                          value={formData[questions[step].key]}
                          onChange={handleInputChange}
                        >
                          {questions[step].options.map((option, index) => (
                            <option
                              key={index}
                              value={option.value}
                              disabled={option.disabled}
                            >
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : questions[step].key === "industryName" ? (
                        <select
                          className="flex-1 px-6 py-3 rounded-xl border border-gray-200 dark:border-gray-700 
                                     dark:bg-gray-900 focus:ring-2 focus:ring-primary/20 
                                     focus:border-primary transition-all duration-200 text-lg"
                          value={formData[questions[step].key]}
                          onChange={handleInputChange}
                        >
                          <option value="">Select an industry</option>
                          {industries.map((ind) => (
                            <option key={ind._id} value={ind.industry_name}>
                              {ind.industry_name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="flex-1 px-6 py-3 rounded-xl border border-gray-200 dark:border-gray-700 
                                     dark:bg-gray-900 focus:ring-2 focus:ring-primary/20 
                                     focus:border-primary transition-all duration-200 text-lg
                                     required:border-red-500"
                          placeholder="Type your answer... (required)"
                          value={formData[questions[step].key]}
                          onChange={handleInputChange}
                          onKeyPress={(e) => e.key === "Enter" && handleNext()}
                          required
                        />
                      )}

                      <button
                        className="px-8 py-3 bg-gradient-to-r from-primary to-orange-500 text-white 
                                   rounded-xl hover:shadow-lg hover:shadow-primary/20 transition-all 
                                   duration-200 flex items-center gap-2 font-medium"
                        onClick={handleNext}
                      >
                        Next
                        <svg
                          className="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex justify-between items-center mt-8 pt-4 border-t dark:border-gray-700">
                <button
                  className="text-primary hover:text-primary/80 transition-colors duration-200 
                             flex items-center gap-2 text-sm font-medium"
                  onClick={() => setChat(false)}
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                  Switch to Manual Setup
                </button>

                {/* Step Indicators */}
                <div className="flex items-center gap-2">
                  {Array.from({ length: questions.length }).map((_, index) => (
                    <div
                      key={index}
                      onClick={() => setStep(index)}
                      className={`h-1.5 rounded-full transition-all duration-200 cursor-pointer ${
                        index <= step
                          ? "w-8 bg-primary"
                          : "w-2 bg-gray-200 dark:bg-gray-700"
                      }`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </animated.div>
        </div>

        <div className="text-center text-gray-500 dark:text-gray-400 mt-6 max-w-screen-sm z-10 px-4">
          <p className="text-sm">
            AI can make mistakes. Please review all generated content before
            publishing.
          </p>
        </div>
      </div>

      {/* Display SitePortfolio once enough data is collected in AI wizard */}
      {step > questions.length - 13 && (
        <SitePortfolio formData={formData} onExecute={handleExecute} />
      )}
    </div>
  );
}
