import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SectionHead from "./SectionHead";
import useApi from "../../../../utils/useApi";
import { InputField } from "../../../../components";
import { useProjectContext } from "../ProjectContext";
import { CameraIcon, LoaderCircle, Save } from "lucide-react";

// Reusable component for file upload button
const FileUploadButton = ({ id, onChange, label }) => (
  <>
    <input
      type="file"
      id={id}
      onChange={onChange}
      className="hidden"
      accept="image/*"
    />
    <label
      htmlFor={id}
      className="bg-white text-primary shadow-md cursor-pointer rounded-lg overflow-hidden flex px-2 py-1 items-center absolute right-1 bottom-1 group"
    >
      <span className="text-xs max-w-0 font-medium group-hover:max-w-[100px] group-hover:mr-2 transition-all duration-300 ease-in-out whitespace-nowrap overflow-hidden">
        {label}
      </span>
      <CameraIcon className="w-5 shrink-0" />
    </label>
  </>
);

// Reusable component for image preview with loader
const ImagePreview = ({
  isUpdating,
  previewSrc,
  height,
  width,
  altText,
  children,
}) => (
  <div className="relative p-6 rounded-xl bg-gray-50 dark:bg-gray-800/50 border border-dashed border-gray-300 dark:border-gray-700 hover:border-primary/50 transition-colors">
    <div className="flex flex-col items-center justify-center overflow-hidden gap-4">
      {isUpdating ? (
        <LoaderCircle className="w-8 h-8 animate-spin text-primary" />
      ) : (
        <>
          <img
            src={previewSrc}
            loading="lazy"
            alt={altText}
            style={{ height: `${height}px`, width: `${width}px` }}
            className="object-contain"
          />

          {children}
        </>
      )}
    </div>
  </div>
);

export default function ProjectIdentity({
  setIsCustomizing,
  open,
  isCustomizing,
}) {
  const { request } = useApi();
  const { project_id, refreshIframe } = useProjectContext();

  const [logoInfo, setLogoInfo] = useState(null);
  const [faviconInfo, setFaviconInfo] = useState(null);
  const [isUpdating, setIsUpdating] = useState({ logo: false, favicon: false });

  // Group logo settings into one state object
  const [logoSettings, setLogoSettings] = useState({
    logoType: "",
    logoText: "",
    logoHeight: 50,
    logoWidth: 180,
    fontSize: 24,
    isBold: false,
    isItalic: false,
  });

  // Calculate aspect ratio for proportional scaling
  const aspectRatio = logoSettings.logoWidth / logoSettings.logoHeight;

  const fetchData = async (type, setFileInfo) => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/${type}`,
      });
      const file = res.data[0].file_name;
      if (type === "logo") {
        setLogoSettings({
          logoType: res.data[0].value?.logoType,
          logoText: res.data[0].value?.logoText,
          logoHeight: res.data[0].value?.logoHeight,
          logoWidth: res.data[0].value?.logoWidth,
          fontSize: res.data[0].value?.fontSize,
          isBold: res.data[0].value?.isBold,
          isItalic: res.data[0].value?.isItalic,
        });
      }
      setFileInfo({
        file,
        preview: `${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${file}`,
      });
    } catch (err) {
      console.error(err);
      toast.error("Error fetching data.");
    }
  };

  useEffect(() => {
    if (isCustomizing === "project identity") {
      const fetchInitialData = async () => {
        await Promise.all([
          fetchData("favicon", setFaviconInfo),
          fetchData("logo", setLogoInfo),
        ]);
      };
      fetchInitialData();
    }
  }, [project_id, isCustomizing]);

  const handleFileChange = (e, setFileInfo) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        // Get the actual dimensions of the image
        const width = img.width;
        const height = img.height;

        setLogoSettings((prev) => ({
          ...prev,
          logoHeight: height,
          logoWidth: width,
        }));

        // Set the preview and file info
        setFileInfo({
          file,
          preview: URL.createObjectURL(file),
        });
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const updateFile = async (e, fileInfo, type, successMessage) => {
    e.preventDefault();
    setIsUpdating((prev) => ({ ...prev, [type]: true }));

    try {
      const formData = new FormData();
      formData.append("key", type);
      if (type === "logo" && logoSettings.logoType === "image") {
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(logoSettings));
      } else {
        formData.append("value", type);
      }
      if (fileInfo?.file) {
        formData.append("file", fileInfo?.file);
      }
      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success(successMessage);
      fetchData(type, type === "logo" ? setLogoInfo : setFaviconInfo);
      refreshIframe();
    } catch (err) {
      toast.error(err.response?.data?.message || "Error updating file.");
    }
    setIsUpdating((prev) => ({ ...prev, [type]: false }));
  };

  const updateTextLogo = async (e) => {
    e.preventDefault();
    setIsUpdating((prev) => ({ ...prev, logo: true }));

    try {
      const formData = new FormData();
      formData.append("key", "logo");
      formData.append("value", JSON.stringify(logoSettings));
      formData.append("value_type", "JSON");
      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Logo Updated");
      refreshIframe();
    } catch (err) {
      toast.error(err.response?.data?.message || "Error updating logo.");
    }
    setIsUpdating((prev) => ({ ...prev, logo: false }));
  };

  return (
    <div
      className={`z-50 shadow-r shadow-black/15 bg-white dark:bg-gray-900 absolute transparent-scrollbar
        transition-all duration-500 ease-in-out
        ${open ? "left-64" : "left-0"}
        ${
          isCustomizing === "project identity"
            ? "w-96 opacity-100 h-[calc(100vh-6rem)] top-0 overflow-y-scroll"
            : "w-0 opacity-0 h-0 bottom-0 overflow-hidden"
        }
        border-r border-gray-200 dark:border-gray-800`}
    >
      <SectionHead
        title="Project Identity"
        setIsCustomizing={setIsCustomizing}
      />
      <div className="p-6 space-y-8">
        {/* Logo Type Selection */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Select Logo Type
          </label>
          <select
            onChange={(e) =>
              setLogoSettings((prev) => ({ ...prev, logoType: e.target.value }))
            }
            value={logoSettings.logoType}
            className="w-full rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-primary/50 transition-shadow"
          >
            <option value="image">Image Logo</option>
            <option value="text">Text Logo</option>
          </select>
        </div>

        {/* Image Logo Section */}
        {logoSettings.logoType === "image" && (
          <div>
            <div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                Logo
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400 mb-3">
                Recommended size: 50x180 pixels
              </p>
            </div>

            <ImagePreview
              isUpdating={isUpdating.logo}
              previewSrc={logoInfo?.preview}
              height={logoSettings.logoHeight}
              width={logoSettings.logoWidth}
              altText="No Logo"
            >
              <FileUploadButton
                id="logoUpload"
                onChange={(e) => handleFileChange(e, setLogoInfo)}
                label="Select Logo"
              />
            </ImagePreview>

            {/* Logo Size Controls */}
            <div className="space-y-3 my-4 py-2 px-4 shadow rounded-lg bg-white dark:bg-gray-800">
              <div className="flex items-center justify-between">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-200">
                  Adjust Logo Size
                </label>
                <span className="text-sm text-gray-500 dark:text-gray-400 font-mono">
                  {Math.max(10, Math.round(logoSettings.logoHeight))}px ×{" "}
                  {Math.max(10, Math.round(logoSettings.logoWidth))}px
                </span>
              </div>
              <input
                type="range"
                min="10"
                max="100"
                step="3"
                value={logoSettings.logoHeight}
                onChange={(e) => {
                  const newHeight = Math.max(10, Number(e.target.value));
                  const newWidth = Math.max(
                    10,
                    Math.round(newHeight * aspectRatio)
                  );
                  setLogoSettings((prev) => ({
                    ...prev,
                    logoHeight: newHeight,
                    logoWidth: newWidth,
                  }));
                }}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
              />
            </div>

            <button
              onClick={(e) => updateFile(e, logoInfo, "logo", "Logo updated.")}
              className="ml-auto flex items-center justify-center gap-2 px-4 py-2.5 w-fit text-sm font-medium text-white bg-green-500 hover:bg-green-600 rounded-lg transition-colors"
            >
              {isUpdating.logo ? (
                <LoaderCircle className="w-4 h-4 animate-spin" />
              ) : (
                <Save className="w-4 h-4" />
              )}
              {isUpdating.logo ? "Updating..." : "Save Changes"}
            </button>
          </div>
        )}

        {logoSettings.logoType === "text" && (
          <div className="space-y-6">
            {/* Text Logo Preview */}
            <div className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800/50 border border-dashed border-gray-300 dark:border-gray-700">
              <div className="text-center">
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-3">
                  Logo Preview
                </p>
                <div
                  className="min-h-[50px] flex items-center justify-center"
                  style={{
                    fontSize: `${logoSettings.fontSize}px`,
                    fontWeight: logoSettings.isBold ? "bold" : "normal",
                    fontStyle: logoSettings.isItalic ? "italic" : "normal",
                  }}
                >
                  {logoSettings.logoText || "Your Logo Text"}
                </div>
              </div>
            </div>

            {/* Text Input */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                Logo Text
              </label>
              <input
                type="text"
                value={logoSettings.logoText}
                onChange={(e) =>
                  setLogoSettings((prev) => ({
                    ...prev,
                    logoText: e.target.value,
                  }))
                }
                placeholder="Enter your logo text"
                className="w-full rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-primary/50 transition-shadow"
              />
            </div>

            {/* Font Controls */}
            <div className="grid grid-cols-2 gap-4">
              {/* Font Size */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Font Size
                </label>
                <select
                  value={logoSettings.fontSize}
                  onChange={(e) =>
                    setLogoSettings((prev) => ({
                      ...prev,
                      fontSize: Number(e.target.value),
                    }))
                  }
                  className="w-full rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-primary/50 transition-shadow"
                >
                  {[12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 56, 64, 72].map(
                    (size) => (
                      <option key={size} value={size}>
                        {size}px
                      </option>
                    )
                  )}
                </select>
              </div>

              {/* Font Style */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Font Style
                </label>
                <div className="flex items-center gap-4 h-[38px]">
                  <button
                    onClick={() =>
                      setLogoSettings((prev) => ({
                        ...prev,
                        isBold: !prev.isBold,
                      }))
                    }
                    className={`px-3 py-1 rounded-md text-sm ${
                      logoSettings.isBold
                        ? "bg-primary text-white"
                        : "bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300"
                    }`}
                  >
                    B
                  </button>
                  <button
                    onClick={() =>
                      setLogoSettings((prev) => ({
                        ...prev,
                        isItalic: !prev.isItalic,
                      }))
                    }
                    className={`px-3 py-1 rounded-md text-sm italic ${
                      logoSettings.isItalic
                        ? "bg-primary text-white"
                        : "bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300"
                    }`}
                  >
                    I
                  </button>
                </div>
              </div>
            </div>

            {/* Save Button */}
            <button
              onClick={updateTextLogo}
              className="ml-auto flex items-center justify-center gap-2 px-4 py-2.5 w-fit text-sm font-medium text-white bg-green-500 hover:bg-green-600 rounded-lg transition-colors"
            >
              {isUpdating.logo ? (
                <LoaderCircle className="w-4 h-4 animate-spin" />
              ) : (
                <Save className="w-4 h-4" />
              )}
              {isUpdating.logo ? "Updating..." : "Save Changes"}
            </button>
          </div>
        )}

        {/* Favicon Section */}
        <div>
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">
              Favicon
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-3">
              Recommended size: 32x32 pixels
            </p>
          </div>

          <ImagePreview
            isUpdating={isUpdating.favicon}
            previewSrc={faviconInfo?.preview}
            height={32}
            width={32}
            altText="No Favicon"
          >
            <FileUploadButton
              id="faviconUpload"
              onChange={(e) => handleFileChange(e, setFaviconInfo)}
              label="Select Favicon"
            />
          </ImagePreview>

          <button
            onClick={(e) =>
              updateFile(e, faviconInfo, "favicon", "Favicon updated.")
            }
            className="ml-auto flex items-center justify-center gap-2 mt-3 px-4 py-2.5 w-fit text-sm font-medium text-white bg-green-500 hover:bg-green-600 rounded-lg transition-colors"
          >
            {isUpdating.favicon ? (
              <LoaderCircle className="w-4 h-4 animate-spin" />
            ) : (
              <Save className="w-4 h-4" />
            )}
            {isUpdating.favicon ? "Updating..." : "Save Changes"}
          </button>
        </div>
      </div>
    </div>
  );
}
