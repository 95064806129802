import { X } from "lucide-react";
import React from "react";

export default function SectionHead({ setIsCustomizing, title }) {
  return (
    <div className="flex w-full border-b dark:border-white/20 sticky top-0 bg-white dark:bg-gray-900 z-10">
      <p className="text-lg font-semibold text-gray-900 dark:text-white py-2.5 pl-6 flex-1">
        {title}
      </p>
      <button
        onClick={() => setIsCustomizing(false)}
        className="border-l dark:border-white/20 flex items-center justify-center p-3 hover:cursor-pointer hover:bg-gray-100 dark:hover:bg-white/10 hover:text-primary transition-all"
      >
        <X className="w-5 h-5" />
      </button>
    </div>
  );
}
