import React, { useState, useEffect, useRef } from "react";
import { useArticleContext } from "./ArticleContext";
import {
  ChevronLeft,
  ChevronRight,
  FileWarning,
  Grid2X2,
  List,
  LoaderCircle,
  RefreshCw,
  Trash,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../../../utils/userPermission";
import Checkbox from "../../../../components/common/CheckBox";
import toast, { Toaster } from "react-hot-toast";
import useApi from "../../../../utils/useApi";

const GridBox = ({ grid, setGrid }) => (
  <div className="bg-white/90 dark:bg-gray-800/50 backdrop-blur-sm p-1.5 rounded-lg border border-gray-200/50 dark:border-white/10 flex items-center gap-1 shadow-sm">
    <button
      onClick={() => setGrid(true)}
      className={`cursor-pointer rounded hover:bg-gray-100 dark:hover:bg-gray-700 p-1 px-2 transition-all ${
        grid ? "bg-white dark:bg-gray-700 shadow-sm" : ""
      }`}
    >
      <Grid2X2 className="h-4 w-4" />
    </button>
    <button
      onClick={() => setGrid(false)}
      className={`cursor-pointer rounded hover:bg-gray-100 dark:hover:bg-gray-700 p-1 px-2 transition-all ${
        !grid ? "bg-white dark:bg-gray-700 shadow shadow-black/10" : ""
      }`}
    >
      <List className="h-4 w-4" />
    </button>
  </div>
);

const Pagination = ({ currentPage, totalPages, paginate }) => (
  <div className="flex justify-center items-center">
    <button
      disabled={currentPage === 1}
      className="h-8 w-8 rounded-full hover:bg-white hover:text-black transition-all flex items-center justify-center"
      onClick={() => paginate(currentPage - 1)}
    >
      <ChevronLeft className="w-5 h-5" />
    </button>
    {[...Array(totalPages).keys()].map((number) => (
      <button
        key={number}
        onClick={() => paginate(number + 1)}
        className={`h-8 w-8 rounded-full ${
          currentPage === number + 1
            ? "bg-primary text-white"
            : "hover:bg-white hover:text-black"
        }`}
      >
        {number + 1}
      </button>
    ))}
    <button
      disabled={currentPage === totalPages}
      className="h-8 w-8 rounded-full hover:bg-white hover:text-black transition-all flex items-center justify-center"
      onClick={() => paginate(currentPage + 1)}
    >
      <ChevronRight className="w-5 h-5" />
    </button>
  </div>
);

const SelectButtons = ({
  select,
  setSelect,
  onSelectAll,
  filteredArticles,
  selectedData,
  action,
  handleDelete,
}) => (
  <div className="flex items-center justify-center gap-2">
    {select ? (
      <>
        <button
          className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-red-500 hover:bg-red-600 active:bg-red-700 rounded-xl shadow-sm transition-all duration-200"
          onClick={handleDelete}
        >
          <Trash className="w-4 h-4" /> Delete
        </button>
        <button
          className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-red-600 bg-red-50 dark:bg-red-500/10 hover:bg-red-100 dark:hover:bg-red-500/20 active:bg-red-200 rounded-xl shadow-sm transition-all duration-200"
          onClick={() => setSelect(false)}
        >
          Cancel
        </button>
        <Checkbox
          className="ml-2"
          label={<span className="text-sm font-medium">Select All</span>}
          onChange={(e) => onSelectAll(e.target.checked)}
          checked={
            filteredArticles?.length &&
            filteredArticles?.length === selectedData?.length
          }
        />
      </>
    ) : (
      <button
        className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-white/90 dark:bg-gray-800/50 backdrop-blur-sm border border-gray-200/50 dark:border-white/10 hover:bg-white dark:hover:bg-gray-700 active:bg-gray-50 rounded-lg shadow-sm transition-all duration-200"
        onClick={() => setSelect(true)}
      >
        Select Articles
      </button>
    )}
  </div>
);

const Header = ({
  searchTerm,
  setSearchTerm,
  filterCategory,
  setFilterCategory,
  currentPage,
  paginate,
  totalPages,
  select,
  setSelect,
  onSelectAll,
  selectedData,
  filteredArticles,
}) => {
  const navigate = useNavigate();
  const {
    getBlogsList,
    isLoading,
    grid,
    setGrid,
    setArticle,
    setFileInfo,
    projectToManage,
    categories,
    project_id,
    blogsList,
  } = useArticleContext();
  const { hasPermission } = usePermissions();
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const writeArticle = () => {
    navigate(
      `/projects/${projectToManage?.project_name
        ?.toLowerCase()
        ?.replaceAll(" ", "-")}/articles/write`
    );
  };

  const handleAddArticle = () => {
    setArticle({
      title: "",
      tagline: "",
      author: "",
      published_at: "",
      imageTitle: "",
      image: "",
      articleContent: "",
    });
    setFileInfo(null);
    writeArticle();
  };

  const { request } = useApi();
  const [action, setAction] = useState(false);

  const confirmRemoveArticles = async (event) => {
    event.preventDefault();
    setAction(true);
    console.log("Previous Blogs", blogsList);

    const idsToRemove = new Set(selectedData.map((item) => item._id));
    const updatedBlogsList = blogsList.filter(
      (item) => !idsToRemove.has(item._id)
    );

    console.log("Updated Blogs List", updatedBlogsList);

    try {
      const formData = new FormData();
      formData.append("key", "blog_list");
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(updatedBlogsList));

      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setShowPopup(false);
      getBlogsList();
      toast.success("Selected blogs removed.");
    } catch (err) {
      toast.error("Error deleting articles.");
    }

    setAction(false);
  };

  const handleDelete = () => {
    setShowPopup(true);
  };

  const handleOutsideClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showPopup]);

  return (
    <>
      <div className="flex items-center justify-between flex-wrap gap-4">
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-bold bg-gradient-to-r from-gray-900 to-gray-600 dark:from-white dark:to-gray-300 bg-clip-text text-transparent">
            Articles
          </h1>
          <button
            onClick={getBlogsList}
            className="p-2 rounded-xl hover:bg-white dark:hover:bg-gray-800 hover:shadow-sm active:scale-95 transition-all duration-200"
          >
            <RefreshCw
              className={`w-5 h-5 text-gray-500 dark:text-gray-400 ${
                isLoading && "animate-spin"
              }`}
            />
          </button>
        </div>

        <div className="flex items-center justify-end gap-2 flex-wrap">
          {!select && (
            <div className="flex items-center gap-4">
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                Page {currentPage} of {totalPages}
              </p>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
              />
            </div>
          )}
          {!select && <GridBox grid={grid} setGrid={setGrid} />}
          <SelectButtons
            select={select}
            setSelect={setSelect}
            onSelectAll={onSelectAll}
            filteredArticles={filteredArticles}
            selectedData={selectedData}
            handleDelete={handleDelete}
            action={action}
          />
          {!select && hasPermission("Add Project Article") && (
            <button
              onClick={handleAddArticle}
              className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary/90 active:bg-primary/80 rounded-lg shadow-sm transition-all duration-200"
            >
              Add Article
            </button>
          )}
        </div>
      </div>

      {!select && (
        <div className="flex-1 flex items-center bg-white/90 dark:bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-200/50 dark:border-white/10 overflow-hidden shadow-sm mt-2">
          <select
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
            className="bg-transparent pl-3 py-2.5 text-sm font-medium focus:outline-none cursor-pointer"
          >
            <option value="">All Categories</option>
            {categories?.map((item, index) => (
              <option key={index} value={item.title}>
                {item.title}
              </option>
            ))}
            <option value="Must Read">Must Read</option>
            <option value="Popular">Popular</option>
            <option value="Featured">Featured</option>
          </select>
          <input
            type="text"
            className="flex-1 bg-transparent px-4 py-2.5 ml-3 border-l border-gray-200/50 dark:border-white/10 text-sm placeholder:text-gray-500 focus:outline-none"
            placeholder="Search articles..."
            value={searchTerm}
            onChange={setSearchTerm}
          />
        </div>
      )}

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div
            ref={popupRef}
            className="bg-white dark:bg-gray-800 p-6 rounded shadow-lg"
          >
            <div className="flex items-center text-primary gap-2 mb-2">
              <FileWarning className="w-5 h-5" />
              <h2 className="text-lg font-bold">Confirmation</h2>
            </div>
            <p className="mb-6">
              Are you sure you want to delete these articles?
            </p>
            <div className="flex justify-end space-x-4">
              <button onClick={() => setShowPopup(false)} className="btnWhite">
                Cancel
              </button>
              <button
                onClick={confirmRemoveArticles}
                className="btnPrimary bg-red-500"
              >
                {action ? (
                  <LoaderCircle className="w-4 h-4 animate-spin" />
                ) : (
                  <Trash className="w-4 h-4" />
                )}
                {action ? <p>Deleting</p> : <p>Confirm Delete</p>}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
